import React, { createContext, ReactNode, useContext, useState } from 'react';

import { Room } from '@app/types/Hotel/room';
import { ZoneEvent } from '@app/types/Planning/zoneEvent';
import { Availability, GridDetailledData, PlanningLine, Rate, Rates } from '@app/types/types';
import { getLogger } from 'loglevel';
import moment from 'moment-timezone';

import axios from '@services/axios/Axios';

// Define the type for your params

// Define the interface for ParamsContextState
export interface PlanningContextState {
  Planning: PlanningLine[] | null;
  FreeAvailabilities: Availability[] | null;
  Rates: Rates[] | null;
  ZoneEvents: ZoneEvent[] | null;
  PlanningPoint: Room[] | null;
  PlanningDetail: GridDetailledData | null;
  setPlanning: (Planning: PlanningLine[] | null) => void;
  getPlanning: (params: PlanningFilters) => Promise<void | undefined>;
  setPlanningPoint: (PlanningPoint: Room[] | null) => void;
  getPlanningPoint: (params: PlanningFilters) => Promise<void | undefined>;
  setZoneEvents: (ZoneEvents: ZoneEvent[] | null) => void;
  getZoneEvents: (params: PlanningFilters) => Promise<void | undefined>;
  setPlanningDetail: (PlanningDetail: GridDetailledData | null) => void;
  getPlanningDetail: (params: PlanningFilters) => Promise<void | undefined>;
  resource: any;
  setResource: (resource: any) => void;
  getFreeAvailabilities: (DateStart: Date, DateEnd: Date) => Promise<Availability[] | undefined>;
  getRates: (DateStart: Date, DateEnd: Date, IdRoomType: string) => Promise<Rates[] | undefined>;
  setFreeAvailabilities: (Availabilities: Availability[] | null) => void;
  setRates: (Rates: Rates[] | null) => void;
}

// Create a context to hold your params with default values
export const PlanningContext = createContext<PlanningContextState | undefined>(undefined);

// Define the props type for the ParamsProvider component
type PlanningProviderProps = {
  children: ReactNode;
};

// Create a provider component
export const PlanningProvider: React.FC<PlanningProviderProps> = (props) => {
  const [Planning, setPlanning] = useState<PlanningLine[] | null>(null);
  const [FreeAvailabilities, setFreeAvailabilities] = useState<Availability[] | null>(null);
  const [Rates, setRates] = useState<Rates[] | null>(null);

  const [PlanningPoint, setPlanningPoint] = useState<Room[] | null>(null);
  const [ZoneEvents, setZoneEvents] = useState<ZoneEvent[] | null>(null);
  const [PlanningDetail, setPlanningDetail] = useState<GridDetailledData | null>(null);
  const [resource, setResource] = useState<any>({});
  const getPlanning = async (params: PlanningFilters) => {
    try {
      const {
        SelectedHotels,
        TypeToDisplay,
        DatePointStart,
        DatePointEnd,
        CurrentSort,
        ForceOneDay,
        IsCollapse,
        ...filtersWithoutDateStart
      } = params;
      const filters = { ...filtersWithoutDateStart };
      filters.DateEnd = moment(filters?.DateEnd).format('YYYY-MM-DD') as string;
      filters.DateStart = moment(filters.DateStart).format('YYYY-MM-DD') as string;

      const urlToPass = 'Rooms';
      const response = await axios.post('/Planning/' + urlToPass, filters, {});

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setPlanning(data.Data as PlanningLine[]);
    } catch (error) {
      getLogger('web').error(error);
    }
  };
  const getPlanningPoint = async (params: PlanningFilters) => {
    try {
      const urlToPass = 'Point';
      const response = await axios.post(
        '/Planning/' + urlToPass,
        {
          DateEnd: moment(params.DatePointEnd).format('YYYY-MM-DD'),
          DateStart: moment(params.DatePointStart).format('YYYY-MM-DD'),
          IdHotel: params.IdHotel,
        },
        {},
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setPlanningPoint(data.Data as Room[]);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getFreeAvailabilities = async (DateStart: Date, DateEnd: Date) => {
    try {
      const params = {
        DateStart: moment(DateStart).format('YYYY-MM-DD'),
        DateEnd: moment(DateEnd).format('YYYY-MM-DD'),
      };
      const urlToPass = 'Libre';
      const response = await axios.post('/Availabilities/' + urlToPass, params, {});
      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setFreeAvailabilities(data.Data as Availability[]);
      return data.Data as Availability[];
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getRates = async (DateStart: Date, DateEnd: Date, IdRoomType: string) => {
    try {
      const params = {
        DateStart: moment(DateStart).format('YYYY-MM-DD'),
        DateEnd: moment(DateEnd).format('YYYY-MM-DD'),
        IdRoomType: IdRoomType,
      };
      const urlToPass = 'Libre';
      const response = await axios.post('/Rates/' + urlToPass, params, {});
      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setRates(data.Data as Rates[]);
      return data.Data as Rates[];
    } catch (error) {
      getLogger('web').error(error);
    }
  };
  const getZoneEvents = async (params: PlanningFilters) => {
    try {
      const filters = { ...params };

      filters.DateEnd = moment(filters.DateEnd).format('YYYY-MM-DD') as string;
      filters.DateStart = moment(filters.DateStart).format('YYYY-MM-DD') as string;

      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :

      const urlToPass = 'EventsAndCondition/';
      const response = await axios.get(
        'PmsData/Localized/' +
          urlToPass +
          filters.SelectedHotels?.IdHotel +
          '/' +
          filters.DateStart +
          '/' +
          filters.DateEnd,
        {},
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setZoneEvents(data.Data as ZoneEvent[]);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getPlanningDetail = async (params: PlanningFilters) => {
    try {
      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :

      const gridParams = {
        IdHotel: params.IdHotel,
        DateStart: moment(params.DateStart).format('YYYY-MM-DD') as string,
        DateEnd: moment(params.DateEnd).format('YYYY-MM-DD') as string,
        PickupDate: params.SelectedHotels?.CurrentPlanningDate,
        DisplayMode: 1,
      };

      const urlToPass = 'HotelDailyData';
      const response = await axios.post('/Dashboard/' + urlToPass, gridParams, {});

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setPlanningDetail(data.Data as GridDetailledData);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  return (
    <PlanningContext.Provider
      value={{
        Planning,
        PlanningDetail,
        PlanningPoint,
        ZoneEvents,
        FreeAvailabilities,
        setPlanning,
        getPlanning,
        getPlanningPoint,
        setPlanningPoint,
        getZoneEvents,
        setZoneEvents,
        getPlanningDetail,
        setPlanningDetail,
        setResource,
        resource,
        getFreeAvailabilities,
        getRates,
        Rates,
        setFreeAvailabilities,
        setRates,
      }}
    >
      {props.children}
    </PlanningContext.Provider>
  );
};

// Create a custom hook to access the params
export function usePlanning(): PlanningContextState {
  const planningContext = useContext(PlanningContext);
  if (planningContext === undefined) {
    throw new Error('usePlanning must be used within a PlanningProvider');
  }
  return planningContext;
}
