import { useWs, WSEventEmitter } from '@app/modules/ws/wsContext';
import { createContext, ReactNode, useContext, useEffect } from 'react';
import { actionHandlers, handleDefault } from '../services/ReceivedEvent/actionHandlers';
import { useMail } from '@app/modules/Mail/MailContext';
import { useUser } from '@app/modules/user/UserContext';
import { useLocation } from 'react-router-dom';
import { useFilters } from '@app/modules/filter/filterContext';
import { usePlanning } from '@app/modules/planning/PlanningContext';
import { useDataFactory } from '@app/modules/DataFactory/DataFactoryContext';
import { useAnalytics } from '@app/modules/Analytics/AnalyticsContext';
import { useBookings } from '@app/modules/Booking/BookingsContext';
import { useDashboard } from '@app/modules/dashboard/DashboardContext';
import { useHotel } from '@app/modules/hotel/HotelContext';
import { useMainCourante } from '@app/modules/MainCourante/MainCouranteContext';
import { usePrevision } from '@app/modules/prevision/PrevisionContext';
import { useTabView } from '@app/modules/Modal/TabViewContext';
import { useSubForm } from '@app/modules/Modal/SubFormContext';
import { useWindow } from '@app/modules/Modal/WindowContext';

// Définir l'interface pour l'état du contexte
interface ReceivedEventContextState {
  triggerAction: (action: string) => void;
}

// Créer le contexte
export const ReceivedEventContext = createContext<ReceivedEventContextState | undefined>(undefined);

// Définir les propriétés pour le provider
type ReceivedEventProviderProps = {
  children: ReactNode;
};

// Créer le provider

export const ReceivedEventProvider: React.FC<ReceivedEventProviderProps> = (props) => {
  const mailCtx = useMail();
  const wsCtx = useWs();
  const userCtx = useUser();
  const location = useLocation();
  const filtersCTX = useFilters();
  const planningCTX = usePlanning();
  const dataFactoryCTX = useDataFactory();
  const analyticsCTX = useAnalytics();
  const bookingCTX = useBookings();
  const dashboardCTX = useDashboard();
  const hotelCTX = useHotel();
  const maincouranteCTX = useMainCourante();
  const previsionCTX = usePrevision();
  const tabViewCTX = useTabView();
  const subFormCTX = useSubForm();
  const windowCTX = useWindow();
  const contextDependencies = {
    mailCtx,
    wsCtx,
    userCtx,
    location,
    filtersCTX,
    planningCTX,
    dataFactoryCTX,
    analyticsCTX,
    bookingCTX,
    dashboardCTX,
    hotelCTX,
    maincouranteCTX,
    previsionCTX,
    tabViewCTX,
    subFormCTX,
    windowCTX,
  };

  const triggerAction = (action: string) => {
    const handler = actionHandlers[action] || (() => handleDefault(action));
    handler(contextDependencies);
  };

  useEffect(() => {
    const handleReceivedEventServiceMessage = (actionToDo: string[]) => {
      actionToDo.forEach((action) => {
        triggerAction(action);
      });
    };

    WSEventEmitter.on('ReceivedPMSMessage', handleReceivedEventServiceMessage);

    return () => {
      WSEventEmitter.off('ReceivedPMSMessage', handleReceivedEventServiceMessage);
    };
  }, []);

  return <ReceivedEventContext.Provider value={{ triggerAction }}>{props.children}</ReceivedEventContext.Provider>;
};

// Hook pour utiliser le contexte
export const useReceivedEvent = (): ReceivedEventContextState => {
  const context = useContext(ReceivedEventContext);
  if (!context) {
    throw new Error('useReceivedEvent must be used within a ReceivedEventProvider');
  }
  return context;
};
