import React, { useEffect } from 'react';

import ContentHeader from '@app/components/Common/content-header/ContentHeader';
import { getOpositeColor, hexToRgb, stringToColour } from '@app/utils/themes';
import { isArray } from 'lodash';
import { getInitials, stripHtml } from '@app/utils/helpers';
import colors from '@app/styles/colors';
import { DailyMessage, Email } from '@app/types/types';
import { useMail } from '@app/modules/Mail/MailContext';
import moment from 'moment-timezone';
import { Tooltip } from '@mui/material';

interface props {
  mail: Email | DailyMessage;
}
const MailItem: React.FC<props> = (props) => {
  const mailCTX = useMail();
  useEffect(() => {
    if (
      mailCTX.SelectedMail &&
      mailCTX.SelectedMail.IsRead === false &&
      mailCTX.SelectedMail.UniqueId === props.mail.UniqueId
    ) {
      console.log('should mark as read ', props.mail);
      mailCTX.markAsRead(props.mail.UniqueId ?? '', props.mail.Id as string, props.mail.IdHotelTo as number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mail.UniqueId, mailCTX.SelectedMail]);

  useEffect(() => {
    if (mailCTX.SelectedMail) {
      mailCTX.setCreationMode(false);
      mailCTX.setCreationDailyMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailCTX.SelectedMail]);
  return (
    <div style={{ margin: 0, padding: 0, cursor: 'pointer' }} onClick={() => mailCTX.setSelectedMail(props.mail)}>
      <hr style={{ margin: 0, padding: 0 }} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor:
            mailCTX?.SelectedMail?.UniqueId && mailCTX?.SelectedMail?.UniqueId === props.mail.UniqueId
              ? '#D0E4F9'
              : 'transparent',
        }}
      >
        <div
          style={{
            backgroundColor:
              mailCTX.currentBox === '1'
                ? props.mail.IsRead
                  ? 'transparent'
                  : colors.colorPrimaryDark
                : mailCTX.currentBox === '2'
                  ? props.mail.Recipients && props.mail.Recipients?.filter((r) => r.IsRead === false).length > 0
                    ? props.mail.Recipients &&
                      props.mail.Recipients?.filter((r) => r.IsRead === false).length === props.mail.Recipients?.length
                      ? 'red'
                      : 'orange'
                    : 'transparent'
                  : 'transparent',

            width: '5px',
            height: '80px',
          }}
          id="mailStatus"
        ></div>

        <div
          style={{
            height: '80px',
            width: 'calc(100% - 5px)',
            padding: '5px',
          }}
        >
          {props.mail.Sender && (
            <div
              className="user-info_avatar custom_avatar_position header-userIcon"
              style={{
                width: '36px',
                height: '36px',
                fontWeight: '600',
                fontSize: '12px',
                lineHeight: '36px',
                marginRight: '12px',
                marginBottom: '8px',
                marginTop: '5px',
                backgroundColor: stringToColour(props.mail.Sender),

                color: getOpositeColor(hexToRgb(stringToColour(props.mail.Sender))),

                float: 'left',
                position: 'relative',
                right: 'auto',
              }}
            >
              {getInitials(props.mail.Sender)}
            </div>
          )}
          <div
            style={{
              fontWeight: props.mail.IsRead ? 'normal' : 'bold',
              fontSize: '0.8rem',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div style={{ width: '65%', height: mailCTX.currentBox === '2' ? '35px' : '', overflow: 'auto' }}>
              {props.mail.Sender
                ? props.mail.Sender
                : props.mail.Recipients
                  ? props.mail.Recipients?.map((r) => (
                      <Tooltip title={<>{r.DisplayName}</>}>
                        <div
                          className="user-info_avatar custom_avatar_position header-userIcon"
                          style={{
                            width: '20px',
                            height: '20px',
                            fontWeight: '600',
                            fontSize: '9px',
                            lineHeight: '20px',
                            marginRight: '8px',
                            marginBottom: '8px',
                            marginTop: '5px',
                            backgroundColor: stringToColour(r.DisplayName),

                            color: getOpositeColor(hexToRgb(stringToColour(r.DisplayName))),

                            float: 'left',
                            position: 'relative',
                            right: 'auto',
                          }}
                        >
                          {r.DisplayName && getInitials(r.DisplayName)}
                        </div>
                      </Tooltip>
                    ))
                  : ''}
            </div>{' '}
            <div style={{ width: '35%', alignItems: 'end', display: 'flex', justifyContent: 'right' }}>
              {'ValidityFrom' in props.mail && 'ValidityTo' in props.mail
                ? moment(props.mail.ValidityFrom).format('DD/MM/YY') +
                  ' - ' +
                  moment(props.mail?.ValidityTo).format('DD/MM/YY')
                : moment(props.mail.DateCreation).format('DD/MM/YY HH:mm')}
            </div>{' '}
          </div>
          <div id="mailSubject" style={{ fontWeight: 'normal', fontSize: '0.8rem' }}>
            {props.mail.Subject}
          </div>
          <div
            style={{
              fontWeight: 'lighter',
              fontSize: '0.8rem',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              width: '80%',
              marginBottom: '10px',
              paddingBottom: '10px',
            }}
          >
            {stripHtml(props.mail.Body.replaceAll('-', ' '))}
          </div>
        </div>
      </div>
      <hr style={{ margin: 0, padding: 0 }} />
    </div>
  );
};

export default MailItem;
