import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { getLogger } from 'loglevel';

import normalAxios from '@services/axios/Axios';
import silentAxios from '@services/axios/SilentAxios';
import {
  DailyMessage,
  Email,
  EmailToSend,
  HotelMails,
  HotelUsersContact,
  MailFilters,
  simpleRecipient,
} from '@app/types/types';
import { useUser } from '../user/UserContext';
import axios from '@services/axios/Axios';
// Define the type for your params

// Define the interface for ParamsContextState
export interface MailContextState {
  SelectedMail: Email | DailyMessage | null;
  mailFilters: MailFilters;
  isCreationMode: boolean;
  setCreationMode: (isCreation: boolean) => void;
  isCreationDailyMode: boolean;
  setCreationDailyMode: (isCreation: boolean) => void;
  setMailFilters: (mailFilters: MailFilters) => void;
  setSelectedMail: (Mail: Email | null) => void;
  currentBox: string;
  setCurrentBox: (Box: string) => void;

  inboxMessages: Email[] | null;
  trashInboxMessages: Email[] | null;
  dailyMessages: DailyMessage[] | null;

  setInboxMessages: (Mails: Email[] | null) => void;
  setTrashInboxMessages: (Mails: Email[] | null) => void;
  countTrashInboxMessages: () => void;

  getInboxMessage: (mailFilters: MailFilters) => void;
  getDailyMessage: (mailFilters: MailFilters) => void;

  getTrashInboxMessage: (mailFilters: MailFilters) => void;
  getTrashOutboxMessage: (mailFilters: MailFilters) => void;

  outboxMessages: Email[] | null;
  setOutboxMessages: (Mails: Email[] | null) => void;
  getOutboxMessage: (mailFilters: MailFilters) => void;
  trashOutboxMessages: Email[] | null;

  setTrashOutboxMessages: (Mails: Email[] | null) => void;
  countTrashOutboxMessages: () => void;

  outboxMessagesCount: number | null;
  setOutboxMessagesCount: (count: number | null) => void;
  countOutboxMessages: () => void;

  inboxMessagesCount: number | null;
  trashInboxMessagesCount: number | null;

  setInboxMessagesCount: (count: number | null) => void;
  countInboxMessages: () => void;
  setDailyMessagesCount: (count: number | null) => void;
  countDailyMessages: () => void;

  inboxMessagesUnread: Email[] | null;
  setInboxMessagesUnread: (Mails: Email[] | null) => void;

  inboxMessagesUnreadCount: number | null;
  setInboxMessagesUnreadCount: (count: number | null) => void;
  countInboxMessagesUnread: () => void;
  trashOutboxMessagesCount: number | null;
  dailyMessagesCount: number | null;

  markAsRead: (UniqueId: string, IdMessage: string, IdHotelFrom: number) => void;

  deleteMessageInbox: (IdHotelTo: number, IdMessage: string) => void;
  deleteMessageDaily: (IdMessage: string) => void;

  deleteMessageOutbox: (UniqueId: string, IdMessage: string) => void;

  activeDate: Date;
  setActiveDate: (date: Date) => void;

  untilDate: Date;
  setUntilDate: (date: Date) => void;
  recipients: HotelMails[] | null;
  setRecipients: (hotelMails: HotelMails[] | null) => void;
  getRecipients: () => void;

  userToSend: simpleRecipient[] | null;
  setUserToSend: (users: simpleRecipient[] | null) => void;

  send: (Mail: EmailToSend) => void;
  addDailyMessage: (dailyMessage: DailyMessage) => void;
  getTodayMessage: () => void;
  todayMessage: DailyMessage[] | null;
  setTodayMessage: (dailyMessage: DailyMessage[] | null) => void;
}

// Create a context to hold your params with default values
export const MailContext = createContext<MailContextState | undefined>(undefined);

// Define the props type for the ParamsProvider component
type MailProviderProps = {
  children: ReactNode;
};

// Create a provider component
export const MailProvider: React.FC<MailProviderProps> = (props) => {
  const userCTX = useUser();
  const [SelectedMail, setSelectedMail] = useState<Email | null>(null);
  const [currentBox, setCurrentBox] = useState<string>('1');
  const [inboxMessages, setInboxMessages] = useState<Email[] | null>(null);
  const [trashInboxMessages, setTrashInboxMessages] = useState<Email[] | null>(null);
  const [trashOutboxMessages, setTrashOutboxMessages] = useState<Email[] | null>(null);
  const [trashInboxMessagesCount, setTrashInboxMessagesCount] = useState<number | null>(null);
  const [trashOutboxMessagesCount, setTrashOutboxMessagesCount] = useState<number | null>(null);

  const [inboxMessagesUnreadCount, setInboxMessagesUnreadCount] = useState<number | null>(null);
  const [inboxMessagesCount, setInboxMessagesCount] = useState<number | null>(null);
  const [dailyMessagesCount, setDailyMessagesCount] = useState<number | null>(null);
  const [dailyMessages, setDailyMessages] = useState<DailyMessage[] | null>(null);
  const [todayMessage, setTodayMessage] = useState<DailyMessage[] | null>(null);

  const [inboxMessagesUnread, setInboxMessagesUnread] = useState<Email[] | null>(null);
  const [isCreationMode, setIsCreationMode] = useState(false);
  const [isCreationDailyMode, setIsCreationDailyMode] = useState(false);

  const [outboxMessages, setOutboxMessages] = useState<Email[] | null>(null);
  const [outboxMessagesCount, setOutboxMessagesCount] = useState<number | null>(null);
  const [activeDate, setActiveDate] = useState<Date>(new Date());
  const [untilDate, setUntilDate] = useState<Date>(new Date());

  const [mailFilters, setMailFilters] = useState<MailFilters>({
    IdHotel: userCTX.authenticationInfos.selectedHotel?.IdHotel as number,
    Page: 1,
    Pagesize: 8,
  });
  const [userToSend, setUserToSend] = useState<simpleRecipient[] | null>(null);
  const [recipients, setRecipients] = useState<HotelMails[] | null>(null);
  const getInboxMessage = async (mailFilters: MailFilters) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const newParams = { ...mailFilters };
      if (newParams.Page !== 1) {
        newParams.Page = 1 + mailFilters.Page / mailFilters.Pagesize;
      }
      const response = await axios.post(`Messaging/Inbox`, { ...newParams });

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setInboxMessages(data.Data as Email[]);
    } catch (error) {
      getLogger('web').error(error);
    }
  };
  const getOutboxMessage = async (mailFilters: MailFilters) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const newParams = { ...mailFilters };
      if (newParams.Page !== 1) {
        newParams.Page = 1 + mailFilters.Page / mailFilters.Pagesize;
      }
      const response = await axios.post(`Messaging/Outbox`, { ...newParams });

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setOutboxMessages(data.Data as Email[]);
    } catch (error) {
      getLogger('web').error(error);
    }
  };
  const getTrashInboxMessage = async (mailFilters: MailFilters) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const newParams = { ...mailFilters };
      if (newParams.Page !== 1) {
        newParams.Page = 1 + mailFilters.Page / mailFilters.Pagesize;
      }
      const response = await axios.post(`Messaging/Trash/Inbox`, { ...newParams });

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setTrashInboxMessages(data.Data as Email[]);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getDailyMessage = async (mailFilters: MailFilters) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const newParams = { ...mailFilters };
      if (newParams.Page !== 1) {
        newParams.Page = 1 + mailFilters.Page / mailFilters.Pagesize;
      }
      const response = await axios.post(`Messaging/Daily/List`, { ...newParams });

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setDailyMessages(data.Data as DailyMessage[]);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getTodayMessage = async () => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :

      const response = await axios.get(`Messaging/Daily/Today`);

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setTodayMessage(data.Data as DailyMessage[]);
    } catch (error) {
      getLogger('web').error(error);
    }
  };
  const getTrashOutboxMessage = async (mailFilters: MailFilters) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const newParams = { ...mailFilters };
      if (newParams.Page !== 1) {
        newParams.Page = 1 + mailFilters.Page / mailFilters.Pagesize;
      }
      const response = await axios.post(`Messaging/Trash/Outbox`, { ...newParams });

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setTrashOutboxMessages(data.Data as Email[]);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const markAsRead = async (UniqueId: string, IdMessage: string, IdHotelFrom: number) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :

      const response = await axios.post(`Messaging/Read`, {
        IdHotel: userCTX.authenticationInfos.selectedHotel?.IdHotel ?? IdHotelFrom,
        IdMessage: IdMessage,
      });

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setInboxMessages((prevMessages) =>
        prevMessages ? prevMessages.map((mail) => (mail.UniqueId === UniqueId ? { ...mail, IsRead: true } : mail)) : [],
      );
      countInboxMessagesUnread();
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const send = async (Mail: EmailToSend) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :

      const response = await axios.post(`Messaging/Send`, Mail);

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }
    } catch (error) {
      getLogger('web').error(error);
    }
  };
  const addDailyMessage = async (Mail: DailyMessage) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :

      const response = await axios.post(`Messaging/Daily`, Mail);

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const countInboxMessages = async () => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.get(
        `Messaging/Inbox/Count/` +
          (userCTX?.authenticationInfos?.selectedHotel !== null &&
          userCTX?.authenticationInfos?.selectedHotel !== undefined
            ? (userCTX?.authenticationInfos?.selectedHotel?.IdHotel.toString() as string)
            : ''),
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setInboxMessagesCount(data.Data as unknown as number);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const countDailyMessages = async () => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.get(`Messaging/Daily/Count/`);

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setDailyMessagesCount(data.Data as unknown as number);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const countTrashInboxMessages = async () => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.get(
        `Messaging/Trash/Inbox/Count/` +
          (userCTX?.authenticationInfos?.selectedHotel !== null &&
          userCTX?.authenticationInfos?.selectedHotel !== undefined
            ? (userCTX?.authenticationInfos?.selectedHotel?.IdHotel.toString() as string)
            : ''),
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setTrashInboxMessagesCount(data.Data as unknown as number);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const countTrashOutboxMessages = async () => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.get(
        `Messaging/Trash/Outbox/Count/` +
          (userCTX?.authenticationInfos?.selectedHotel !== null &&
          userCTX?.authenticationInfos?.selectedHotel !== undefined
            ? (userCTX?.authenticationInfos?.selectedHotel?.IdHotel.toString() as string)
            : ''),
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setTrashOutboxMessagesCount(data.Data as unknown as number);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const countOutboxMessages = async () => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.get(
        `Messaging/Outbox/Count/` +
          (userCTX?.authenticationInfos?.selectedHotel !== null &&
          userCTX?.authenticationInfos?.selectedHotel !== undefined
            ? (userCTX?.authenticationInfos?.selectedHotel?.IdHotel.toString() as string)
            : ''),
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setOutboxMessagesCount(data.Data as unknown as number);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getRecipients = async () => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.get(
        `Messaging/Recipients/` +
          (userCTX?.authenticationInfos?.selectedHotel !== null &&
          userCTX?.authenticationInfos?.selectedHotel !== undefined
            ? (userCTX?.authenticationInfos?.selectedHotel?.IdHotel.toString() as string)
            : userCTX.authenticationInfos.user?.OwningHotel),
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setRecipients(data.Data as HotelMails[]);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const countInboxMessagesUnread = async () => {
    try {
      console.log(silentAxios);
      console.log(axios);

      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.get(
        `Messaging/Inbox/Unread/` +
          (userCTX?.authenticationInfos?.selectedHotel !== null &&
          userCTX?.authenticationInfos?.selectedHotel !== undefined
            ? (userCTX?.authenticationInfos?.selectedHotel?.IdHotel.toString() as string)
            : ''),
      );

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setInboxMessagesUnread(data.Data as Email[]);
      setInboxMessagesUnreadCount((data.Data as Email[]).length);
    } catch (error) {
      getLogger('web').error(error);
    }
  };
  const setCreationMode = (mode: boolean) => {
    setIsCreationMode(mode);
  };

  const setCreationDailyMode = (mode: boolean) => {
    setIsCreationDailyMode(mode);
  };
  const deleteMessageInbox = async (IdHotelTo: number, IdMessage: string) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.delete(`Messaging/Inbox/` + IdMessage + '/' + IdHotelTo);

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }
      switch (currentBox) {
        case '1':
          getInboxMessage(mailFilters);
          countInboxMessages();
          countInboxMessagesUnread();
          break;
        case '2':
          getOutboxMessage(mailFilters);
          countOutboxMessages();
          break;
      }
    } catch (error) {
      getLogger('web').error(error);
    }
  };
  const deleteMessageOutbox = async (IdMessage: string) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.delete(`Messaging/Outbox/` + IdMessage);

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }
      switch (currentBox) {
        case '1':
          getInboxMessage(mailFilters);
          countInboxMessages();
          countInboxMessagesUnread();
          break;
        case '2':
          getOutboxMessage(mailFilters);
          countOutboxMessages();
          break;
      }
    } catch (error) {
      getLogger('web').error(error);
    }
  };
  const deleteMessageDaily = async (IdMessage: string) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.delete(`Messaging/Daily/` + IdMessage);

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }
      getDailyMessage(mailFilters);
      countDailyMessages();
    } catch (error) {
      getLogger('web').error(error);
    }
  };
  return (
    <MailContext.Provider
      value={{
        SelectedMail,
        setSelectedMail,
        currentBox,
        setCurrentBox,
        inboxMessages,
        setInboxMessages,
        getInboxMessage,
        addDailyMessage,
        countDailyMessages,
        dailyMessages,
        deleteMessageDaily,
        getDailyMessage,
        dailyMessagesCount,
        setDailyMessagesCount,
        mailFilters,
        setMailFilters,
        countInboxMessages,
        countInboxMessagesUnread,
        inboxMessagesCount,
        inboxMessagesUnreadCount,
        setInboxMessagesCount,
        setInboxMessagesUnreadCount,
        markAsRead,
        countOutboxMessages,
        getOutboxMessage,
        outboxMessages,
        outboxMessagesCount,
        setOutboxMessages,
        setOutboxMessagesCount,
        deleteMessageInbox,
        inboxMessagesUnread,
        setInboxMessagesUnread,
        isCreationMode,
        setCreationMode,
        activeDate,
        setActiveDate,
        setRecipients,
        getRecipients,
        recipients,
        userToSend,
        setUserToSend,
        send,
        deleteMessageOutbox,
        countTrashInboxMessages,
        countTrashOutboxMessages,
        setTrashInboxMessages,
        setTrashOutboxMessages,
        trashInboxMessages,
        trashOutboxMessages,
        trashInboxMessagesCount,
        trashOutboxMessagesCount,
        getTrashInboxMessage,
        getTrashOutboxMessage,
        getTodayMessage,
        setTodayMessage,
        todayMessage,
        isCreationDailyMode,
        setCreationDailyMode,
        untilDate,
        setUntilDate,
      }}
    >
      {props.children}
    </MailContext.Provider>
  );
};

// Create a custom hook to access the params
export function useMail(): MailContextState {
  const mailContext = useContext(MailContext);
  if (mailContext === undefined) {
    throw new Error('useMail must be used within a MailProvider');
  }
  return mailContext;
}
