import { ReactNode, useEffect, useState } from 'react';

import { useLogger } from '@app/modules/logger/LoggerContext';
import { MbscCalendarEvent } from '@mobiscroll/react';
import { Button } from 'primereact/button';
import { dateTo_dd_MM_yyyy, getDeltaInDays, midDateToLocaleString, shortDateToLocaleString } from '@app/utils/dates';
import { useUser } from '@app/modules/user/UserContext';
import moment from 'moment-timezone';
import { Room } from '@app/types/Hotel/room';
import { PlanningLine } from '@app/types/types';
import colors from '@app/styles/colors';
import { useBookings } from '@app/modules/Booking/BookingsContext';
import { MultiSelect } from 'primereact/multiselect';
import { useTranslation } from 'react-i18next';
import { useWindow } from '@app/modules/Modal/WindowContext';
import ChooseNewRoom from './ChooseNewRoom';
import { usePlanning } from '@app/modules/planning/PlanningContext';
import { useFilters } from '@app/modules/filter/filterContext';
import { useDataFactory } from '@app/modules/DataFactory/DataFactoryContext';

interface props {
  data: any;
  title: ReactNode;
  showTitle: boolean;
  actions: any;
}
const ConfirmationModale: React.FC<props> = ({ data, title, showTitle, actions }) => {
  const logger = useLogger();
  const userCTX = useUser();
  const bookingCTX = useBookings();
  const planningCTX = usePlanning();
  const windowCTX = useWindow();
  const filtersCTX = useFilters();
  const [t] = useTranslation();
  const dataFactoryCTX = useDataFactory();
  const [hasSufficientPermission, setHasSufficnetPermission] = useState(false);

  useEffect(() => {
    if (
      bookingCTX &&
      windowCTX.window.listOfWindowsDisplayed.find((w) => w.id === 20000)?.translatedTitle !== 'Délogement'
    ) {
      let hasVirtualProposal: boolean | null = null;
      bookingCTX.bookingsProposal?.Items.map((b) =>
        dataFactoryCTX.DataDictionnary.Rooms?.find((r) => r.Id === b.IdRoom)?.IsVirtual
          ? (hasVirtualProposal = true)
          : null,
      );
      if (
        userCTX.authenticationInfos.permissions?.find((p) => p.IdFunctionList === 30)?.CanAccess === false &&
        hasVirtualProposal === true
      ) {
        setHasSufficnetPermission(false);
      } else {
        setHasSufficnetPermission(true);
      }
    }
  }, []);

  const chooseNewRoom = (IdBooking: string) => {
    const form = windowCTX.window.listOfWindowsDisplayed.find((e) => e.id === 30000);
    if (!form) {
      const id = 30000;
      const newWindow = {
        id: id, // ou utilisez un ID unique
        component: ChooseNewRoom, // Remplacez par le composant que vous souhaitez afficher
        data: { IdBooking: IdBooking }, // Les données que vous souhaitez transmettre au composant
        translatedTitle: <>Type de chambre</>,
        actions: {
          hideSubForm: () => windowCTX.hideWindow(id),
          onConfirm: () => {
            windowCTX.hideWindow(id); // Fermer la modale
          },
          onCancel: () => {
            windowCTX.hideWindow(id); // Fermer la modale
          },
        },
        draggable: false, // Définissez-le en fonction de vos besoins
        border: '0px solid #666',
        fullWidth: true, // Définissez-le en fonction de vos besoins
        fullHeight: false, // Définissez-le en fonction de vos besoins
        tabMode: true,
        x: 'calc(50vw - 375px)',
        y: '15vh',
        width: '800px',
        zIndex: 10000,
        maxHeight: '600px',
      };

      // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
      windowCTX.setWindow((prevWindow) => ({
        ...prevWindow,
        listOfWindowsDisplayed: [...prevWindow.listOfWindowsDisplayed, newWindow],
      }));
    }
  };
  return (
    <div className="row" style={{ width: '100%' }}>
      {bookingCTX.bookingsProposal && (
        <div style={{ width: '100%', padding: '5px', backgroundColor: 'white' }}>
          {data.args.event.Bloque && (
            <h5 style={{ color: 'red', width: '100%', textAlign: 'center', padding: '10px' }}>
              {' '}
              <i
                style={{
                  color: 'darkorange',
                  padding: '10px',
                  zIndex: 10,
                }}
                className="fas fa-lock"
              ></i>
              Réservation bloquée{' '}
              <i
                style={{
                  color: 'darkorange',
                  padding: '10px',
                  zIndex: 10,
                }}
                className="fas fa-lock"
              ></i>
            </h5>
          )}
          <table style={{ width: '100%', border: '1px solid silver' }}>
            <tr style={{ backgroundColor: colors.colorPrimaryMain, color: 'white' }}>
              <td></td>
              <td>Arrivée</td>
              <td>Nuits</td>
              <td>Départs</td>
              <td>Chambres</td>
            </tr>
            <tr style={{ border: '1px solid silver' }}>
              <td>
                <b>Ancien</b>
              </td>
              <td>
                {midDateToLocaleString(
                  new Date(bookingCTX.bookingsProposal.ConcernedItem.OldValue.DateStart),
                  userCTX.authenticationInfos.user?.Culture as string,
                )}
              </td>
              <td style={{ textAlign: 'right' }}>
                {getDeltaInDays(
                  new Date(bookingCTX.bookingsProposal.ConcernedItem.OldValue.DateStart),
                  new Date(bookingCTX.bookingsProposal.ConcernedItem.OldValue.DateEnd),
                )}
              </td>
              <td>
                {midDateToLocaleString(
                  new Date(bookingCTX.bookingsProposal.ConcernedItem.OldValue.DateEnd),
                  userCTX.authenticationInfos.user?.Culture as string,
                )}
              </td>
              <td>
                {data?.rooms
                  ?.find((r: Room) => r.Id === bookingCTX.bookingsProposal?.ConcernedItem.OldValue.IdRoom)
                  ?.RoomNumber.replace('[DEG]', '°') +
                  '  (' +
                  data?.rooms
                    ?.find((r: Room) => r.Id === bookingCTX.bookingsProposal?.ConcernedItem.OldValue.IdRoom)
                    ?.RoomType.replace('[VIRTUAL]', t('labels.sr')) +
                  ')'}
              </td>
            </tr>
            <tr>
              <td>
                <b>Nouveau</b>
              </td>
              <td
                style={{
                  color:
                    midDateToLocaleString(
                      new Date(bookingCTX.bookingsProposal.ConcernedItem.OldValue.DateStart),
                      userCTX.authenticationInfos.user?.Culture as string,
                    ) !==
                    midDateToLocaleString(
                      new Date(bookingCTX.bookingsProposal.ConcernedItem.NewValue.DateStart),
                      userCTX.authenticationInfos.user?.Culture as string,
                    )
                      ? 'red'
                      : '',
                }}
              >
                {midDateToLocaleString(
                  new Date(bookingCTX.bookingsProposal.ConcernedItem.NewValue.DateStart),
                  userCTX.authenticationInfos.user?.Culture as string,
                )}
              </td>
              <td
                style={{
                  textAlign: 'right',
                  color:
                    getDeltaInDays(
                      new Date(bookingCTX.bookingsProposal.ConcernedItem.OldValue.DateStart),
                      new Date(bookingCTX.bookingsProposal.ConcernedItem.OldValue.DateEnd),
                    ) !==
                    getDeltaInDays(
                      new Date(bookingCTX.bookingsProposal.ConcernedItem.NewValue.DateStart),
                      new Date(bookingCTX.bookingsProposal.ConcernedItem.NewValue.DateEnd),
                    )
                      ? 'red'
                      : '',
                }}
              >
                {getDeltaInDays(
                  new Date(bookingCTX.bookingsProposal.ConcernedItem.NewValue.DateStart),
                  new Date(bookingCTX.bookingsProposal.ConcernedItem.NewValue.DateEnd),
                )}
              </td>
              <td
                style={{
                  color:
                    midDateToLocaleString(
                      new Date(bookingCTX.bookingsProposal.ConcernedItem.OldValue.DateEnd),
                      userCTX.authenticationInfos.user?.Culture as string,
                    ) !==
                    midDateToLocaleString(
                      new Date(bookingCTX.bookingsProposal.ConcernedItem.NewValue.DateEnd),
                      userCTX.authenticationInfos.user?.Culture as string,
                    )
                      ? 'red'
                      : '',
                }}
              >
                {midDateToLocaleString(
                  new Date(bookingCTX.bookingsProposal.ConcernedItem.NewValue.DateEnd),
                  userCTX.authenticationInfos.user?.Culture as string,
                )}
              </td>
              <td
                style={{
                  color:
                    bookingCTX.bookingsProposal?.ConcernedItem.NewValue.IdRoom !==
                    data?.rooms
                      ?.find((r: Room) => r.Id === bookingCTX.bookingsProposal?.ConcernedItem.NewValue.IdRoom)
                      ?.RoomNumber.replace('[DEG]', '°')
                      ? 'red'
                      : '',
                }}
              >
                {data?.rooms
                  ?.find((r: Room) => r.Id === bookingCTX.bookingsProposal?.ConcernedItem.NewValue.IdRoom)
                  ?.RoomNumber.replace('[DEG]', '°') +
                  '  (' +
                  data.args.resourceObj.RoomType.replace('[VIRTUAL]', t('labels.sr')) +
                  ')'}
              </td>
            </tr>
          </table>
        </div>
      )}
      {bookingCTX.bookingsProposal && bookingCTX.bookingsProposal?.Status !== 0 && (
        <div style={{ width: '100%', padding: '5px', backgroundColor: 'white' }}>
          {data.args.event.Bloque && (
            <h5 style={{ color: 'red', width: '100%', textAlign: 'center', padding: '10px' }}>
              {' '}
              <i
                style={{
                  color: 'darkorange',
                  padding: '10px',
                  zIndex: 10,
                }}
                className="fas fa-lock"
              ></i>
              Réservation bloquée{' '}
              <i
                style={{
                  color: 'darkorange',
                  padding: '10px',
                  zIndex: 10,
                }}
                className="fas fa-lock"
              ></i>
            </h5>
          )}

          {bookingCTX?.bookingsProposal?.Items && bookingCTX.bookingsProposal?.Status === 1 && (
            <>
              {' '}
              <h5 style={{ color: 'red', width: '100%', textAlign: 'center', padding: '5px' }}>
                {' '}
                <i
                  style={{
                    color: 'darkorange',
                    padding: '10px',
                    zIndex: 10,
                  }}
                  className="fas fa-lock"
                ></i>
                {hasSufficientPermission
                  ? 'Attention !!! Ces réservations vont être déplacées :'
                  : 'Vous ne disposez pas des privilèges requis'}
                <i
                  style={{
                    color: 'darkorange',
                    padding: '10px',
                    zIndex: 10,
                  }}
                  className="fas fa-lock"
                ></i>
              </h5>
              <table style={{ width: '100%', border: '1px solid silver' }}>
                <tr style={{ backgroundColor: colors.colorPrimaryMain, color: 'white' }}>
                  <td></td>
                  <td>Arrivée</td>
                  <td>Nuits</td>
                  <td>Départs</td>
                  <td>Chambres</td>
                </tr>
                {bookingCTX?.bookingsProposal?.Items.map((b) => {
                  return (
                    <tr style={{ border: '1px solid silver' }}>
                      <td>
                        <b>{b.Name}</b>
                      </td>
                      <td>
                        {midDateToLocaleString(
                          new Date(b.DateStart),
                          userCTX.authenticationInfos.user?.Culture as string,
                        )}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {getDeltaInDays(new Date(b.DateStart), new Date(b.DateEnd))}
                      </td>
                      <td>
                        {midDateToLocaleString(
                          new Date(b.DateEnd),
                          userCTX.authenticationInfos.user?.Culture as string,
                        )}
                      </td>
                      <td>
                        <Button
                          disabled={bookingCTX.bookingsProposal?.Status === 2 || hasSufficientPermission == false}
                          onClick={() => chooseNewRoom(b.IdBooking)}
                        >
                          {b.IdRoom
                            ? data?.rooms?.find((r: Room) => r.Id === b.IdRoom)?.RoomNumber.replace('[DEG]', '°') +
                              '  (' +
                              data?.rooms
                                ?.find((r: Room) => r.Id === b.IdRoom)
                                ?.RoomType.replace('[VIRTUAL]', t('labels.sr')) +
                              ')'
                            : b.RoomType}
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </>
          )}
          {bookingCTX?.bookingsProposal?.Items && bookingCTX.bookingsProposal?.Status === 2 && (
            <>
              {' '}
              <h5 style={{ color: 'red', width: '100%', textAlign: 'center', padding: '5px' }}>
                {' '}
                <i
                  style={{
                    color: 'darkorange',
                    padding: '10px',
                    zIndex: 10,
                  }}
                  className="fas fa-lock"
                ></i>
                Chambre bloquée : Accès refusé
                <i
                  style={{
                    color: 'darkorange',
                    padding: '10px',
                    zIndex: 10,
                  }}
                  className="fas fa-lock"
                ></i>
              </h5>
              <table style={{ width: '100%', border: '1px solid silver' }}>
                <tr style={{ backgroundColor: colors.colorPrimaryMain, color: 'white' }}>
                  <td></td>
                  <td>Arrivée</td>
                  <td>Nuits</td>
                  <td>Départs</td>
                  <td>Chambres</td>
                </tr>
                {bookingCTX?.bookingsProposal?.Items.map((b) => {
                  return (
                    <tr style={{ border: '1px solid silver' }}>
                      <td>
                        <b>{b.Name}</b>
                      </td>
                      <td>
                        {midDateToLocaleString(
                          new Date(b.DateStart),
                          userCTX.authenticationInfos.user?.Culture as string,
                        )}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {getDeltaInDays(new Date(b.DateStart), new Date(b.DateEnd))}
                      </td>
                      <td>
                        {midDateToLocaleString(
                          new Date(b.DateEnd),
                          userCTX.authenticationInfos.user?.Culture as string,
                        )}
                      </td>
                      <td>
                        {b.IdRoom
                          ? data?.rooms?.find((r: Room) => r.Id === b.IdRoom)?.RoomNumber.replace('[DEG]', '°') +
                            '  (' +
                            data?.rooms
                              ?.find((r: Room) => r.Id === b.IdRoom)
                              ?.RoomType.replace('[VIRTUAL]', t('labels.sr')) +
                            ')'
                          : b.RoomType}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </>
          )}
        </div>
      )}
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'space-around',
          display: 'flex',
          padding: '10px',
          width: '100%',
        }}
      >
        {bookingCTX.bookingsProposal && bookingCTX.bookingsProposal?.Status !== 2 && hasSufficientPermission && (
          <Button
            style={{ width: '35%', backgroundColor: colors.colorSuccessMain, border: colors.colorSuccessDark }}
            icon="fa-solid fa-check"
            onClick={() => {
              const confirmBooking: ConfirmMove = bookingCTX.bookingsProposal?.ConcernedItem.NewValue as BookingItem;
              if (bookingCTX.bookingsProposal?.Items && bookingCTX.bookingsProposal?.Items?.length > 0) {
                confirmBooking.Others = bookingCTX.bookingsProposal?.Items;
              }
              bookingCTX.confirmMove(confirmBooking).then((result) => {
                if (result) {
                  actions.onConfirm(result);
                  bookingCTX.setBookingsProposal(null);
                  bookingCTX.setConfirmedBookings(null);
                  if (filtersCTX.filters.planningFilters.CurrentSort === 1) {
                    planningCTX.getPlanningDetail(filtersCTX.filters.planningFilters);
                  }
                } else {
                  //on recharge le planning
                  planningCTX.getPlanning(filtersCTX.filters.planningFilters);
                }
              });
            }}
          ></Button>
        )}
        <Button
          style={{ width: '35%', backgroundColor: colors.colorErrorMain, border: colors.colorErrorDark }}
          icon="fa-solid fa-reply-all"
          onClick={() => {
            actions.onCancel();
            bookingCTX.setBookingsProposal(null);
          }}
        ></Button>
      </div>
    </div>
  );
};

export default ConfirmationModale;
