import { ReactElement, useState } from 'react';

import { useSubForm } from '@app/modules/Modal/SubFormContext';
import { SegmentsEntity } from '@app/types/dataFactory';
import { InputText } from 'primereact/inputtext';
import { ListBox } from 'primereact/listbox';

interface props {
  data: any;

  title: ReactElement;
  showTitle: boolean;
  selectedId: string;
}
type TitleProps = {
  children?: React.ReactNode; // Définissez ici les propriétés attendues
};
const PickupSelector: React.FC<props> = ({ data, title }) => {
  const subFormCTX = useSubForm();
  const [currentSearch, setCurrentSearch] = useState('');
  const onButtonPickupSelectorClick = (event: SegmentsEntity) => {
    if (data.resources?.find((e: any) => e.Level === (data.level ?? 0) + 1)) {
      const id = subFormCTX.subForm.listOfSubformsDisplayed.length;
      const newSubForm = {
        id: id, // ou utilisez un ID unique
        component: PickupSelector, // Remplacez par le composant que vous souhaitez afficher
        data: {
          level: (data.level ?? 0) + 1,
          children: event.Children,
          resources: data.resources,
        },
        actions: { hideSubForm: () => subFormCTX.hideSubForm(id) },
        draggable: true, // Définissez-le en fonction de vos besoins

        fullWidth: false, // Définissez-le en fonction de vos besoins
        fullHeight: false, // Définissez-le en fonction de vos besoins
        width: '300px',
        height: '500px',
        x: 'calc((100vw - 300px)/2)',
        y: '100px',
        translatedTitle:
          ((title as ReactElement<TitleProps>).props.children
            ? (title as ReactElement<TitleProps>).props.children
            : title) +
          ' ' +
          parseInt((data.level ?? 0) + 1).toString(),
      };

      // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
      subFormCTX.setSubForm((prevSubForm) => ({
        ...prevSubForm,
        listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
      }));
    }
  };

  return (
    <div>
      <div className="p-input-icon-left" style={{ width: '100%', padding: '5px' }}>
        <i className="pi pi-search" style={{ marginLeft: '5px' }} />
        <InputText
          placeholder="Rechercher"
          style={{ width: '100%' }}
          value={currentSearch}
          onChange={(e) => setCurrentSearch(e.target.value)}
        />
      </div>
      <div style={{ padding: '5px' }}>
        <ListBox
          dataKey="id"
          options={
            data.resources
              ?.filter((x: any) => x.Libelle.toLocaleLowerCase().includes(currentSearch.toLocaleLowerCase()))
              .filter((e: any) => {
                if (data.level === undefined && e.Level === 0) {
                  return e;
                } else if (data.level === e.Level) {
                  if (data?.children?.includes(e.Id)) {
                    return e;
                  } else if (data.children.length === 0) {
                    return e;
                  }
                }
              }) ?? []
          }
          onChange={(e) => onButtonPickupSelectorClick(e.value)}
          optionLabel="Libelle"
        ></ListBox>
      </div>
    </div>
  );
};

export default PickupSelector;
