import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import PlanningFilters from '@app/components/Common/filter/PlanningFilters';
import LightBookingSummary from '@app/components/Common/planning/LightBookingSummary';
import { useBookings } from '@app/modules/Booking/BookingsContext';
import { useDataFactory } from '@app/modules/DataFactory/DataFactoryContext';
import { useFilters } from '@app/modules/filter/filterContext';
import { usePlanning } from '@app/modules/planning/PlanningContext';
import { useUser } from '@app/modules/user/UserContext';
import { useWindow } from '@app/modules/Modal/WindowContext';
import { RootState } from '@app/store/store';
import { Room } from '@app/types/Hotel/room';
import { Coord, PlanningColor, PlanningLine } from '@app/types/types';
import { getDeltaInDays, midDateToLocaleString, shortDateToLocaleString } from '@app/utils/dates';
import { useMediaQuery } from '@app/utils/useMediaQuery';
import {
  Eventcalendar,
  localeAr,
  localeDe,
  localeEn,
  localeEs,
  localeFr,
  localeZh,
  MbscCalendarEventData,
  MbscEventClickEvent,
  MbscEventDragEvent,
} from '@mobiscroll/react';
import {
  MbscCalendarDayData,
  MbscCalendarEvent,
} from '@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-view.types.public';
import { debounce } from 'lodash';
import moment from 'moment';
import { Calendar, CalendarDateTemplateEvent } from 'primereact/calendar';
import { isMobile, isMobileOnly, isTablet, useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import CustomDay from '../../components/Common/planning/CustomDay';
import CustomResource from '../../components/Common/planning/CustomResource';
import CustomResourceBreakingType from '../../components/Common/planning/CustomResourceBreakingType';
import Point from '../../components/Common/planning/point';
import Reservation from '../../components/Common/planning/Reservation';
import ResourceFooter from '../../components/Common/planning/ResourceFooter';
import styled from 'styled-components';

import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { useSubForm } from '@app/modules/Modal/SubFormContext';
import { storage } from '@app/store/localstorage/localStorage';
import { hexToRgba, hexToRgbWithOpacity } from '@app/utils/themes';
import PlanningFooter from '@app/components/Common/planning/planningFooter';
import ConfirmationModale from '@app/components/Common/planning/ConfirmationModale';
import zIndex from '@mui/material/styles/zIndex';
import { config } from '@app/utils/config';
import GroupModale from '@app/components/Common/planning/GroupModale';

type RoomAccumulator = { [key: string]: Room[] };
const CustomTimelineRow = styled.div`
  .mbsc-timeline-row {
    height: 28px;
  }
`;
interface CustomCalendarEventData extends MbscCalendarEventData {
  RefGroup?: string | null; // Identifiant du groupe
  BackgroundEvent?: string; // Couleur de fond
  ForegroundEvent?: string; // Couleur de texte
  startDate: Date; // Autres propriétés nécessaires
  endDate: Date;
}
const Planning: React.FC = () => {
  const { orientation } = useMobileOrientation();
  const planningCTX = usePlanning();
  const dataFactoryCTX = useDataFactory();
  const planning = planningCTX.Planning;
  const subFormCTX = useSubForm();

  const planningDetail = planningCTX.PlanningDetail;
  const userCTX = useUser();
  const selectedHotel = userCTX.authenticationInfos.selectedHotel;
  const zoneEvents = planningCTX.ZoneEvents;
  const filtersCTX = useFilters();
  const rooms = dataFactoryCTX.DataDictionnary.Rooms;
  const PlanningDate = userCTX.authenticationInfos.selectedHotel?.CurrentPlanningDate;
  const [myPlanningColor, setMyPlanningColor] = useState<PlanningColor[]>([]);
  const bookingCTX = useBookings();
  const [myResources, setMyResources] = useState<Room[]>([]);
  const [event, setEvent] = useState<PlanningLine[]>([]);
  const [firstCallDone, setFirstCallDone] = useState(false);
  const windowCTX = useWindow();
  const [showCalendar, setShowCalendar] = useState(false);
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);
  const [t] = useTranslation();

  const isPortrait = useMediaQuery('(orientation: portrait)');
  const isLandscape = useMediaQuery('(orientation: landscape)');
  const isNarrowScreen = useMediaQuery('(max-width: 900px)');

  const [isConditionMet, setIsConditionMet] = useState(false);
  const [tooltip, setTooltip] = useState({ visible: false, x: 0, y: 0, text: '' }); // Tooltip state
  const eventBeingModifiedRef = useRef<MbscCalendarEvent | null>(null); // L'événement qui est redimensionné ou déplacé
  const hoveredResourceRef = useRef(null); // Ressource actuellement survolée (pour le déplacement)
  const isDraggingRef = useRef(false); // Indicateur pour le déplacement après redimensionnement
  const initialEventState = useRef<{ start: Date; end: Date; resource: string | number | undefined } | null>(null);

  useEffect(() => {
    setIsConditionMet(isPortrait && isNarrowScreen);
  }, [isPortrait, isNarrowScreen, isLandscape]);
  // Fonction pour ouvrir le calendrier

  useEffect(() => {
    return () => {
      if (windowCTX) {
        windowCTX.hideWindow(10000);
      }
      if (subFormCTX) {
        subFormCTX.hideSubForm(666);
        filtersCTX.setFilters((prevState) => ({
          ...prevState,
          availabiltyFilters: {
            DateStart: null,
            DateEnd: null,
            hasBeenModified: false,
            needReload: false,
            ColoredDates: [],
          },
        }));
        planningCTX.setFreeAvailabilities(null);
        planningCTX.setRates(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const res = myResources.map((e) => {
      return { ...e, collapsed: !filtersCTX.filters.planningFilters.IsCollapse };
    });
    setMyResources(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersCTX]);

  useEffect(() => {
    if (userCTX.authenticationInfos.wsToTreat?.Type === 'UpdateEvent' && planning && planningCTX) {
      const message: any = userCTX.authenticationInfos.wsToTreat;
      planningCTX.setPlanning(
        planning.map((e) => {
          if (e.Id === message.Id) {
            return {
              ...e,
              ...message,
              RoomNumber: message.resource,
              Start: moment(message.start).format('YYYYMMDD'),
              End: moment(message.end).format('YYYYMMDD'),
              IdRoom: message.resource,
            };
          }
          return e;
        }),
      );
      userCTX.setUser((prevState) => ({
        ...prevState,
        wsToTreat: null,
      }));
    }
  }, [planning, planningCTX, userCTX, userCTX.authenticationInfos.wsToTreat]);

  useEffect(() => {
    if (filtersCTX.filters.planningFilters.needReload === true) {
      planningCTX.getPlanning(filtersCTX.filters.planningFilters);

      planningCTX.getPlanningDetail(filtersCTX.filters.planningFilters);
      planningCTX.getZoneEvents(filtersCTX.filters.planningFilters);

      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        planningFilters: {
          ...prevState.planningFilters,
          needReload: false,
        },
      }));
    }
  }, [filtersCTX, filtersCTX.filters.planningFilters.needReload, planningCTX]);
  useEffect(() => {
    if (
      PlanningDate &&
      firstCallDone === false &&
      rooms &&
      selectedHotel &&
      filtersCTX.filters.planningFilters.IdHotel !== 0
    ) {
      planningCTX.getPlanning(filtersCTX.filters.planningFilters);
      planningCTX.getZoneEvents(filtersCTX.filters.planningFilters);
      planningCTX.getPlanningDetail(filtersCTX.filters.planningFilters);

      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        planningFilters: {
          ...prevState.planningFilters,
          TypeToDisplay: rooms
            .map((e) => e.RoomType ?? t('labels.sr'))
            .filter((value, index, self) => self.indexOf(value) === index),
          CurrentSort:
            storage.getParam('CurrentSort') !== null
              ? parseInt(storage.getParam('CurrentSort') as string)
              : dataFactoryCTX.PMSSettings?.PlanningDefautTrieParType === true
                ? 1
                : 0,
          ForceOneDay:
            storage.getParam('CurrentSort') !== null
              ? parseInt(storage.getParam('CurrentSort') as string) === 3
                ? true
                : false
              : false,
        },
      }));

      setFirstCallDone(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PlanningDate, firstCallDone, rooms, selectedHotel, filtersCTX]);

  const countNumberOfEventForResource = useCallback(
    (res: string, index: number) => {
      const currentRes = myResources.find((e) => e.Id === res);
      if (planningDetail) {
        return planningDetail.Data.find((e) => e.Name === currentRes?.RoomNumber)?.Data.find(
          (t) => t.KeyIterator === index,
        )?.Value;
      }
    },
    [myResources, planningDetail],
  );

  // Creation des ressources (chambres et types)
  useEffect(() => {
    if (rooms && PlanningDate && filtersCTX.filters.planningFilters) {
      switch (filtersCTX.filters.planningFilters?.CurrentSort) {
        case 0:
          setMyResources(
            rooms

              .map((e) => {
                return { ...e, key: e.Id, id: e.Id };
              })
              .sort((a, b) => a.PositionRoom - b.PositionRoom),
          );
          planningCTX.setResource(
            rooms

              .map((e) => {
                return { ...e, key: e.Id, id: e.Id };
              })
              .sort((a, b) => a.PositionRoom - b.PositionRoom),
          );

          break;
        case 1:
        case 3:
          const arrayOfRooms = rooms.map((e) => {
            return { ...e, key: e.Id, id: e.Id, Id: e.Id };
          });
          const groupedRooms = arrayOfRooms.reduce((acc: RoomAccumulator, room) => {
            let type = room.RoomType;
            if (room.IdLinkedRoom !== undefined) {
              type = 'COMMUNICANTE';
            }
            if (!acc[type]) {
              acc[type] = [];
            }
            acc[type].push(room);
            return acc;
          }, {});
          const resources = Object.keys(groupedRooms)
            .map((type, index) => {
              const children = groupedRooms[type]

                .map((room: Room) => ({
                  ...room,
                }))
                .sort((a, b) => a.PositionRoom - b.PositionRoom); // Tri des enfants par RoomPosition

              return {
                id: 'p' + (index + 1).toString(),
                Id: (index + 1).toString(),
                key: (index + 1).toString(),
                RoomNumber: type,
                PositionRoomType: type === 'VIRTUAL' ? 2000 : groupedRooms[type][0].RoomTypeOrder,
                PositionRoom: 0,
                RoomType: 'parent',
                collapsed:
                  filtersCTX.filters.planningFilters.CurrentSort === 3
                    ? false
                    : filtersCTX.filters.planningFilters.IsCollapse,
                icon: '\u2615\uFE0F',
                eventCreation: false,
                children: children,
              };
            })
            .sort((a, b) => {
              return a.PositionRoomType - b.PositionRoomType;
            });
          setMyResources(resources);

          planningCTX.setResource(resources);
          break;
        case 2:
          const arrayOfRooms2 = rooms.map((e) => {
            return { ...e, key: e.Id, id: e.Id };
          });
          const roomMap = new Map<string, Room>();
          arrayOfRooms2.forEach((room) => {
            roomMap.set(room.Id, room);
          });
          const sortedRooms = arrayOfRooms2.sort((a, b) => {
            if (a.PositionRoomType !== b.PositionRoomType) {
              return a.PositionRoomType - b.PositionRoomType;
            }
            return a.PositionRoom - b.PositionRoom;
          });

          // Create a final sorted array considering IdCommunicatingRoom
          const finalSortedRooms: Room[] = [];
          const visited = new Set<string>();

          sortedRooms.forEach((room) => {
            if (!visited.has(room.Id)) {
              finalSortedRooms.push(room);
              visited.add(room.Id);
              if (room.IdCommunicatingRoomType) {
                const communicatingRoom = roomMap.get(room.IdLinkedRoom ?? '');
                if (communicatingRoom && !visited.has(communicatingRoom.Id)) {
                  finalSortedRooms.push(communicatingRoom);
                  visited.add(communicatingRoom.Id);
                }
              }
            }
          });
          setMyResources(finalSortedRooms);
          planningCTX.setResource(finalSortedRooms);

          break;
        case 4:
          setMyResources(
            rooms
              .filter(
                (e) =>
                  filtersCTX.filters.planningFilters.TypeToDisplay &&
                  filtersCTX.filters.planningFilters.TypeToDisplay.includes(e.RoomType),
              )
              .map((e) => {
                return { ...e, key: e.Id, id: e.Id };
              }),
          );
          planningCTX.setResource(
            rooms
              .filter(
                (e) =>
                  filtersCTX.filters.planningFilters.TypeToDisplay &&
                  filtersCTX.filters.planningFilters.TypeToDisplay.includes(e.RoomType),
              )
              .map((e) => {
                return { ...e, key: e.Id, id: e.Id };
              }),
          );

          break;
        default:
          setMyResources(
            rooms.map((e) => {
              return { ...e, key: e.Id, id: e.Id };
            }),
          );
          planningCTX.setResource(
            rooms.map((e) => {
              return { ...e, key: e.Id, id: e.Id };
            }),
          );

          break;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersCTX, filtersCTX.filters.planningFilters, rooms, PlanningDate]);

  //création des reservations
  useEffect(() => {
    if (planning) {
      setEvent([
        ...planning
          .filter((t) => t.IdRoom)
          .filter((x) => {
            if (filtersCTX.filters.planningFilters.showDeparture) {
              return x;
            }
            if (
              new Date(moment(filtersCTX.filters.planningFilters.DateStart).format('YYYY-MM-DD')) >=
              new Date(moment(selectedHotel?.CurrentPlanningDate).format('YYYY-MM-DD'))
            ) {
              if (x.Status !== 'D') {
                return x;
              }
            } else {
              return x;
            }
          })
          //  .filter((t) => t.Status !== 'D')
          .map((e) => {
            return {
              ...e,
              id: e.Id,
              memo: e.Memo,
              start: moment(e.Start).format('YYYYMMDD'),
              end: moment(e.End).format('YYYYMMDD'),
              originalBackground: e.BackgroundEvent ?? '',
              originalForeground: e.ForegroundEvent ?? '',
              RefGroup: e.RefGroup,
              BackgroundEvent: e.BackgroundEvent ?? '',
              ForegroundEvent: e.ForegroundEvent ?? '',
              resource: e.IdRoom,
              dragInTime: e.Status === 'P' ? false : true,

              editable: (() => {
                const isEditable =
                  e.Status !== 'D' && moment(e.End).toDate() >= moment(selectedHotel?.CurrentPlanningDate).toDate();

                return isEditable;
              })(),
            };
          }),
      ]);
    }
  }, [
    planning,
    filtersCTX.filters.planningFilters.showDeparture,
    filtersCTX.filters.planningFilters.DateStart,
    selectedHotel?.CurrentPlanningDate,
  ]);

  useEffect(() => {
    const tempColor: PlanningColor[] = [];
    if (myResources && filtersCTX.filters.planningFilters) {
      if (filtersCTX.filters.planningFilters.CurrentSort === 1) {
        myResources.map((e) => {
          if (e.children) {
            tempColor.push({
              background: hexToRgbWithOpacity(e.children[0].TypeColor ?? '', '0.15'),
              recurring: {
                repeat: 'weekly',
                weekDays: 'MO,TU,WE,TH,FR',
                from: moment(filtersCTX.filters.planningFilters.DateStart).format('YYYY-MM-DD'),
                until: moment(filtersCTX.filters.planningFilters.DateEnd).format('YYYY-MM-DD'),
              },
              resource: [...e.children.map((t) => t.id), e.id],
            });
          }
          tempColor.push({
            background: '#333',
            recurring: {
              repeat: 'weekly',
              weekDays: 'MO,TU,WE,TH,FR',
              from: moment(filtersCTX.filters.planningFilters.DateStart).format('YYYY-MM-DD'),
              until: moment(filtersCTX.filters.planningFilters.DateEnd).format('YYYY-MM-DD'),
            },
            resource: e.Id,
          });
        });
      } else {
        myResources.map((e) => {
          tempColor.push({
            background: hexToRgbWithOpacity(e.TypeColor ?? '', '0.15'),
            recurring: {
              repeat: 'weekly',
              weekDays: 'MO,TU,WE,TH,FR',
              from: moment(filtersCTX.filters.planningFilters.DateStart).format('YYYY-MM-DD'),
              until: moment(filtersCTX.filters.planningFilters.DateEnd).format('YYYY-MM-DD'),
            },
            resource: e.id,
          });
        });
      }

      tempColor.push({
        background: '#dfdfdf',
        recurring: {
          repeat: 'weekly',
          weekDays: 'SU,SA',
          from: moment(filtersCTX.filters.planningFilters.DateStart).format('YYYY-MM-DD'),
          until: moment(filtersCTX.filters.planningFilters.DateEnd).format('YYYY-MM-DD'),
        },
      });
      setMyPlanningColor(tempColor);
    }
  }, [filtersCTX.filters.planningFilters, myResources]);

  //Ajout des lignes de totaux en vue type
  useEffect(() => {
    if (filtersCTX.filters.planningFilters) {
      if (
        event.length > 0 &&
        myResources.length > 0 &&
        filtersCTX.filters.planningFilters.CurrentSort === 1 &&
        planningDetail &&
        filtersCTX.filters.planningFilters &&
        myResources.filter((m) => m.RoomType === 'parent').length > 0
      ) {
        const arrayOfTotal: PlanningLine[] = [];
        for (
          let i = 0;
          i <=
          getDeltaInDays(
            filtersCTX.filters.planningFilters.DateStart as number,
            filtersCTX.filters.planningFilters.DateEnd as number,
          );
          i++
        ) {
          myResources.forEach((item) => {
            const dayTotal = {
              start: moment(new Date(filtersCTX.filters.planningFilters.DateStart)).add(i, 'day').format('YYYYMMDD'),
              end: moment(new Date(filtersCTX.filters.planningFilters.DateStart)).add(i, 'day').format('YYYYMMDD'),
              resource: item.id,
              totalType: true,
              editable: false,
              id:
                item.id +
                moment(new Date(filtersCTX.filters.planningFilters.DateStart)).add(i, 'day').format('YYYYMMDD'),
              Id:
                item.id +
                moment(new Date(filtersCTX.filters.planningFilters.DateStart)).add(i, 'day').format('YYYYMMDD'),
              IdRoom: item.id.toString(),
              DisplayName: countNumberOfEventForResource(item.Id, i)?.toString() ?? '',
              foreground: item?.children?.find((e) => e.TypeColor)?.TypeColor ?? 'red',
            };
            arrayOfTotal.push(dayTotal);
          });
        }
        const updatedEvents = event.filter((e) => !e.totalType); // Exclure les anciens `totalType`
        const mergedEvents = updatedEvents.concat(arrayOfTotal);
        setEvent(mergedEvents);
      }
    }
  }, [
    myResources,
    filtersCTX.filters.planningFilters,
    filtersCTX.filters.planningFilters.CurrentSort,
    planningDetail,
    event,
    countNumberOfEventForResource,
  ]);

  const showTooltip = (x: number, y: number, text: string) => {
    setTooltip({
      visible: true,
      x,
      y,
      text,
    });
  };

  const hideTooltip = () => {
    setTooltip((prevTooltip) => ({ ...prevTooltip, visible: false }));
  };

  const renderCustomResource = useCallback((resource: Room) => {
    return <CustomResource resource={resource}></CustomResource>;
  }, []);

  const renderCustomResourceBreakingType = useCallback((resource: Room) => {
    if (resource) {
      return <CustomResourceBreakingType resource={resource}></CustomResourceBreakingType>;
    }
  }, []);

  const hideNA = useMemo(() => planningDetail?.Data.filter((e) => e.DataType === 16).length === 0, [planningDetail]);
  const hideSR = useMemo(() => planningDetail?.Data.filter((e) => e.DataType === 17).length === 0, [planningDetail]);

  const MemoizedResourceFooter = useCallback(() => {
    return <ResourceFooter hideSR={hideSR} hideNA={hideNA} />;
  }, [hideSR, hideNA]);
  const renderCustomDayFooter = useCallback(
    (args: MbscCalendarDayData) => {
      const hideNA = planningDetail?.Data.filter((e) => e.DataType === 16).length === 0 ? true : false;
      const hideSR = planningDetail?.Data.filter((e) => e.DataType === 17).length === 0 ? true : false;

      return (
        <PlanningFooter
          planningDetail={planningDetail}
          hideSR={hideSR}
          hideNA={hideNA}
          date={args.date}
        ></PlanningFooter>
      );
    },
    [planningDetail],
  );

  const dateTemplate = useCallback(
    (date: CalendarDateTemplateEvent) => {
      const dateMoment = moment(date);

      if (dateMoment.isSame(moment(selectedHotel?.CurrentPlanningDate), 'day')) {
        // Applique un style personnalisé si la date correspond à la date de facturation
        return <span style={{ backgroundColor: '#ffeb3b', borderRadius: '50%' }}>{moment(date).date()}</span>;
      }

      // Rendu par défaut pour les autres dates
      return moment(date).date();
    },
    [selectedHotel?.CurrentPlanningDate],
  );

  const renderDay = useCallback(
    (args: MbscCalendarDayData) => {
      return <CustomDay caller="Planning" args={args} date={args.date} zoneEvents={zoneEvents}></CustomDay>;
    },
    [zoneEvents],
  );
  const renderResourceHeader = useCallback(() => {
    return (
      <div
        style={{
          height: '37px',
          alignItems: 'center',
          display: 'flex',
          backgroundColor: '#e6effc',
        }}
      >
        <div>
          <Calendar
            touchUI={isMobile}
            todayButtonClassName="toto"
            inputStyle={{
              backgroundColor: 'transparent',
              width: '150px',
              display: 'flex',
              border: 'none',
              marginTop: '-20px',
              color: 'transparent',
              cursor: 'pointer',
            }}
            style={{
              position: 'fixed',
              width: '160px',
              zIndex: '999',
              backgroundColor: 'transparent',
              cursor: 'pointer',
            }}
            viewDate={new Date(selectedHotel?.CurrentPlanningDate as string)}
            locale={userCTX.authenticationInfos?.language || 'fr'}
            value={filtersCTX.filters.planningFilters.DateStart as Date}
            dateTemplate={dateTemplate}
            onChange={(e) => {
              filtersCTX.setFilters((prevState) => ({
                ...prevState,
                planningFilters: {
                  ...prevState.planningFilters,
                  DateStart: moment(e.value?.getTime()).toDate(),
                  DateEnd: moment(e.value).add(prevState.planningFilters.dayToDisplay, 'days').toDate(),
                  needReload: true,
                },
              }));
            }}
          />
        </div>

        <label
          htmlFor="selectDate"
          onClick={() => setShowCalendar(!showCalendar)} // Gestionnaire de clic pour afficher/masquer le calendrier
          style={{
            justifyContent: 'center',
            display: 'flex',
            width: '100%',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            alignItems: 'center',
            margin: 0,
            fontSize: '12pt',
            padding: 0,
            backgroundColor: '#e6effc',
          }}
        >
          {new Date(filtersCTX.filters.planningFilters.DateStart).toLocaleDateString(
            userCTX.authenticationInfos.language,
            {
              month: 'long',
              year: 'numeric',
            },
          )}
        </label>
      </div>
    );
  }, [
    dateTemplate,
    filtersCTX,
    selectedHotel?.CurrentPlanningDate,
    showCalendar,
    userCTX.authenticationInfos.language,
  ]);
  const myScheduleEvent = useCallback(
    (data: CustomCalendarEventData) => {
      return (
        <div className="custom-event" data-group-id={data?.original?.RefGroup || null}>
          <Reservation
            backgroundColor={data?.original?.BackgroundEvent}
            foregroundColor={data?.original?.ForegroundEvent}
            data={data}
            PlanningDate={PlanningDate}
            groupId={data?.original?.RefGroup} // Identifiant du groupe
          />
        </div>
      );
    },
    [PlanningDate],
  );

  /*




  const onEventDragEnd = useCallback(
    (args: MbscEventDragEvent) => {
      userCTX.SendToGroup(JSON.stringify(args.event), userCTX.authenticationInfos);
      // setLine({ ...line, visible: false });
    },
    [userCTX],
  );

 
*/

  const initialPositionRef = useRef<{ x: number; y: number } | null>(null);
  const lineLeftRef = useRef<HTMLDivElement | null>(null); // Pour la ligne gauche
  const lineRightRef = useRef<HTMLDivElement | null>(null); // Pour la ligne droite
  const eventDraggedId = useRef<string | number | undefined | null>(null);

  const onEventClick = useCallback(
    (args: MbscEventClickEvent) => {
      if (!args.event.totalType) {
        bookingCTX.getBookingSummary({ params: { selectedHotel }, idBooking: args.event.id ?? '' }).then((data) => {
          const form = windowCTX.window.listOfWindowsDisplayed.find((e) => e.id === 10000);
          if (!form) {
            const id = 10000;
            const newWindow = {
              id: id, // ou utilisez un ID unique
              component: LightBookingSummary, // Remplacez par le composant que vous souhaitez afficher
              data: data, // Les données que vous souhaitez transmettre au composant
              translatedTitle: (
                <>
                  <i style={{ color: 'darkorange', marginRight: '5px' }} className="fa-solid fa-hashtag"></i>
                  {data?.ConfirmationNumber}
                  {data?.RefGroup && (
                    <>
                      <i style={{ marginLeft: '10px', marginRight: '5px' }} className="fa-solid fa-users"></i>
                      {data?.RefGroup + ` (${data.GroupRoomsQty})`}
                    </>
                  )}
                  {data?.HasRoomAccessCode && <i className="fa-solid fa-key"></i>}
                  {data?.HasWakeUp && <i className="fa-regular fa-clock"></i>}
                  {data?.MediaScanId && <i className="fa-solid fa-id-card"></i>}
                  {data?.CommunicatingRoomNumber !== undefined && (
                    <>
                      <i className="fa-solid fa-link"></i>
                      {data?.CommunicatingRoomNumber}
                    </>
                  )}
                </>
              ),
              actions: { hideSubForm: () => windowCTX.hideWindow(id) },
              draggable: true, // Définissez-le en fonction de vos besoins
              border: '2px solid #666',
              fullWidth: true, // Définissez-le en fonction de vos besoins
              fullHeight: true, // Définissez-le en fonction de vos besoins
              tabMode: true,
              x: 'calc(100vw - 475px)',
              y: 0,
              width: '400px',
            };

            // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
            windowCTX.setWindow((prevWindow) => ({
              ...prevWindow,
              listOfWindowsDisplayed: [...prevWindow.listOfWindowsDisplayed, newWindow],
            }));
          } else {
            windowCTX.setWindow((prevWindow) => {
              const updatedForms = prevWindow.listOfWindowsDisplayed.map((window) =>
                window.id === 10000
                  ? {
                      ...window,
                      classToAdd: 'showWindow',
                      data: data, // Les données que vous souhaitez transmettre au composant
                      translatedTitle: (
                        <>
                          <i style={{ color: 'darkorange', marginRight: '5px' }} className="fa-solid fa-hashtag"></i>
                          {data?.ConfirmationNumber}
                          {data?.RefGroup && <i className="fa-solid fa-users"></i>}
                          {data?.HasRoomAccessCode && <i className="fa-solid fa-key"></i>}
                          {data?.HasWakeUp && <i className="fa-regular fa-clock"></i>}
                          {data?.MediaScanId && <i className="fa-solid fa-id-card"></i>}
                          {data?.CommunicatingRoomNumber !== undefined && (
                            <>
                              <i className="fa-solid fa-link"></i>
                              {data?.CommunicatingRoomNumber}
                            </>
                          )}
                        </>
                      ),
                    }
                  : form,
              );
              return { ...prevWindow, listOfWindowsDisplayed: updatedForms };
            });
          }
        });
      }
    },
    [bookingCTX, selectedHotel, windowCTX],
  );
  const handleUpdate = useCallback(
    (event: number) => {
      switch (event) {
        case 0:
          filtersCTX.setFilters((prevState) => ({
            ...prevState,
            planningFilters: {
              ...prevState.planningFilters,
              CurrentSort: 0,
              ForceOneDay: false,
            },
          }));
          break;
        case 1:
          filtersCTX.setFilters((prevState) => ({
            ...prevState,
            planningFilters: {
              ...prevState.planningFilters,
              CurrentSort: 1,
              ForceOneDay: false,
            },
          }));
          break;
        case 2:
          filtersCTX.setFilters((prevState) => ({
            ...prevState,
            planningFilters: {
              ...prevState.planningFilters,
              IsCollapse: !prevState.planningFilters.IsCollapse,
            },
          }));
          break;
        case 3:
          filtersCTX.setFilters((prevState) => ({
            ...prevState,
            planningFilters: {
              ...prevState.planningFilters,
              IsCollapse: false,
              ForceOneDay: true,
              CurrentSort: 3,
            },
          }));
          break;
        case 4:
          filtersCTX.setFilters((prevState) => ({
            ...prevState,
            planningFilters: {
              ...prevState.planningFilters,
              ForceOneDay: true,
              IsCollapse: false,
              CurrentSort: 4,
            },
          }));
          break;
      }
    },
    [filtersCTX],
  );

  const handleScroll = () => {
    const originalEventElement = document.querySelector(
      `[data-id="${eventDraggedId.current}"]:not([class*="dragging"])`,
    );
    if (originalEventElement) {
      const rect = originalEventElement.getBoundingClientRect();

      // Recalcule la position initiale en fonction du défilement
      const updatedStartPos = {
        x: rect.left + window.scrollX,
        y: rect.top + window.scrollY,
      };
      const updatedStartRightPos = {
        x: rect.right + window.scrollX,
        y: rect.top + window.scrollY,
      };

      initialPositionRef.current = updatedStartPos; // Mets à jour la position initiale
    }
  };

  const onEventDragStart = (args: MbscEventDragEvent) => {
    initialEventState.current = {
      start: args.event.start as Date,
      end: args.event.end as Date,
      resource: args.event.resource as string,
    };
    const { event } = args;
    const eventId = event.id; // ID de l'événement récupéré depuis les args

    // Utilisation de querySelector pour obtenir l'élément DOM de l'événement
    const eventElement = document.querySelector(`[data-id="${eventId}"]`);

    if (eventElement && eventDraggedId !== undefined && eventDraggedId !== null) {
      eventDraggedId.current = eventId;
      const rect = eventElement.getBoundingClientRect(); // Récupère les coordonnées de l'élément

      // Position initiale de l'élément
      const startPos = {
        x: rect.left,
        y: rect.top + rect.height / 2,
      };
      const startRightPos = {
        x: rect.right, // Partir du bord droit
        y: rect.top + rect.height / 2,
      };

      initialPositionRef.current = startPos; // Enregistre la position initiale

      // Crée et affiche les deux lignes (gauche et droite) si elles n'existent pas déjà
      if (!lineLeftRef.current) {
        const line = document.createElement('div');
        line.style.position = 'absolute';
        line.style.height = '2px';
        line.style.backgroundColor = 'red';
        line.style.zIndex = '1'; // Assure que la ligne est au-dessus du contenu
        lineLeftRef.current = line;
        document.body.appendChild(line);
      }

      if (!lineRightRef.current) {
        const line = document.createElement('div');
        line.style.position = 'absolute';
        line.style.height = '2px';
        line.style.backgroundColor = 'blue'; // Pour la ligne droite, utilisez une autre couleur si nécessaire
        line.style.zIndex = '1';
        lineRightRef.current = line;
        document.body.appendChild(line);
      }

      eventBeingModifiedRef.current = args.event;
      isDraggingRef.current = true;
      document.addEventListener('scroll', handleScroll);
      // Attache un écouteur pour suivre le mouvement de la souris
      document.addEventListener('mousemove', handleMouseMove);
    }
  };

  const handleMouseMove = (e: MouseEvent) => {
    const eventElement = document.querySelector(`[data-id="${eventDraggedId.current}"][class*="dragging"]`);
    if (eventElement) {
      const rect = eventElement.getBoundingClientRect(); // Récupère les coordonnées de l'élément

      if (initialPositionRef.current && lineLeftRef.current && lineRightRef.current) {
        const newPositionLeft = {
          x: rect.left, // Position actuelle à gauche
          y: rect.y + rect.height / 2,
        };

        const newPositionRight = {
          x: rect.right, // Position actuelle à droite
          y: rect.y + rect.height / 2,
        };

        const originalEventElement = document.querySelector(
          `[data-id="${eventDraggedId.current}"]:not([class*="dragging"])`,
        );

        if (originalEventElement) {
          const originalRect = originalEventElement.getBoundingClientRect(); // Récupère les coordonnées de l'élément
          const newOriginalPosition = {
            x: originalRect.x, // Position de départ pour la ligne gauche
            y: originalRect.y + originalRect.height / 2,
          };
          const newOriginalRightPosition = {
            x: originalRect.right, // Position de départ pour la ligne droite
            y: originalRect.y + originalRect.height / 2,
          };

          // Met à jour les deux lignes (gauche et droite)
          drawLine(lineLeftRef.current, newOriginalPosition, newPositionLeft); // Ligne gauche
          drawLine(lineRightRef.current, newOriginalRightPosition, newPositionRight); // Ligne droite

          const timelineElement = document.querySelector('.mbsc-timeline-time-indicator-cont'); // Élément du calendrier Mobiscroll
          if (timelineElement) {
            const rect = timelineElement.getBoundingClientRect(); // Limites du calendrier
            const totalDaysDisplayed = (filtersCTX.filters.planningFilters.dayToDisplay as number) + 1;
            const resourceElement = eventElement.closest('.mbsc-timeline-row'); // Trouver la ressource

            const resourceId = resourceElement?.getAttribute('data-id'); // ID de la ressource survolée

            const calendarWidth = rect.width; // Largeur totale moins la largeur des ressources
            const dayWidth = calendarWidth / totalDaysDisplayed; // Largeur d'un jour

            // Vérifier si la souris est sur un événement
            const hoveredDayIndex = Math.floor((e.clientX - rect.left) / dayWidth);
            const hoveredDate = moment(filtersCTX.filters.planningFilters.DateStart)
              .add(hoveredDayIndex, 'days')
              .format('YYYY-MM-DD');
            const resourceElements = document.querySelectorAll('.mbsc-timeline-resource');
            const eventLengt = getDeltaInDays(
              moment(event.find((e) => e.id === eventDraggedId.current)?.Start).toDate(),
              moment(event.find((e) => e.id === eventDraggedId.current)?.End).toDate(),
            );
            let hoveredResourceId = null;
            // Boucler sur toutes les ressources et comparer leur position avec la souris
            resourceElements.forEach((resourceElement) => {
              const resourceRect = resourceElement.getBoundingClientRect();
              // Si la souris est à l'intérieur des limites de la ressource actuelle
              if (e.clientY >= resourceRect.top && e.clientY <= resourceRect.bottom) {
                hoveredResourceId = (resourceElement as HTMLElement).innerText; // Obtenir l'ID de la ressource
              }
            });
            // Afficher la date et la ressource dans le tooltip
            showTooltip(
              e.clientX - 30,
              e.clientY - 30,
              // eslint-disable-next-line max-len
              `${hoveredResourceId} - Du : ${midDateToLocaleString(moment(event.find((e) => e.id === eventDraggedId.current)?.Start).toDate(), userCTX.authenticationInfos.user?.Culture as string)} au  ${midDateToLocaleString(moment(event.find((e) => e.id === eventDraggedId.current)?.End).toDate(), userCTX.authenticationInfos.user?.Culture as string)}`,
            );

            // Calculer sur quel jour on est en fonction de la position de la souris
          }
        }
      }
    }
  };

  const onEventDragEnd = async (args: MbscCalendarEvent | PlanningLine) => {
    if (args.event.Status === 'P' && rooms?.find((r) => r.Id === args.event.resource)?.RoomType === '[VIRTUAL]') {
      if (lineLeftRef.current) {
        document.body.removeChild(lineLeftRef.current);
        lineLeftRef.current = null;
      }
      if (lineRightRef.current) {
        document.body.removeChild(lineRightRef.current);
        lineRightRef.current = null;
      }
      if (initialEventState.current) {
        if (lineLeftRef.current) {
          document.body.removeChild(lineLeftRef.current);
          lineLeftRef.current = null;
        }
        if (lineRightRef.current) {
          document.body.removeChild(lineRightRef.current);
          lineRightRef.current = null;
        }

        document.removeEventListener('mousemove', handleMouseMove);
        initialPositionRef.current = null;
        hideTooltip();
        isDraggingRef.current = false;
        eventBeingModifiedRef.current = null;
        hoveredResourceRef.current = null;
        //remettre les données initiales
        const updatedEvents = event.map((e) => {
          if (e.id === args.event.id) {
            return {
              ...e,
              start: initialEventState.current?.start,
              Start: moment(initialEventState.current?.start).format('YYYY-MM-DD'),
              end: initialEventState.current?.end,
              End: moment(initialEventState.current?.end).format('YYYY-MM-DD'),

              resource: initialEventState.current?.resource, // Restaurer la ressource
            };
          }
          return e;
        });

        setEvent(updatedEvents.filter((e) => e.Id !== 'temp'));

        toast.error('Le client est déja présent');
        throw 'Le client est déja présent';
      }
    }
    if (moment(args.event.start).toDate() < moment(selectedHotel?.CurrentPlanningDate).toDate()) {
      if (lineLeftRef.current) {
        document.body.removeChild(lineLeftRef.current);
        lineLeftRef.current = null;
      }
      if (lineRightRef.current) {
        document.body.removeChild(lineRightRef.current);
        lineRightRef.current = null;
      }
      if (initialEventState.current) {
        if (lineLeftRef.current) {
          document.body.removeChild(lineLeftRef.current);
          lineLeftRef.current = null;
        }
        if (lineRightRef.current) {
          document.body.removeChild(lineRightRef.current);
          lineRightRef.current = null;
        }

        document.removeEventListener('mousemove', handleMouseMove);
        initialPositionRef.current = null;
        hideTooltip();
        isDraggingRef.current = false;
        eventBeingModifiedRef.current = null;
        hoveredResourceRef.current = null;
        //remettre les données initiales
        const updatedEvents = event.map((e) => {
          if (e.id === args.event.id) {
            return {
              ...e,
              start: initialEventState.current?.start,
              Start: moment(initialEventState.current?.start).format('YYYY-MM-DD'),
              end: initialEventState.current?.end,
              End: moment(initialEventState.current?.end).format('YYYY-MM-DD'),

              resource: initialEventState.current?.resource, // Restaurer la ressource
            };
          }
          return e;
        });

        setEvent(updatedEvents.filter((e) => e.Id !== 'temp'));

        toast.error('La date du planning est supérieure à la date de la réservation');
        throw 'La date du planning est supérieure à la date de la réservation';
      }
    }
    if (
      moment(args.event.Start).format('YYYYMMDD') === moment(args.event.start).format('YYYYMMDD') &&
      moment(args.event.End).format('YYYYMMDD') === moment(args.event.end).format('YYYYMMDD') &&
      initialEventState.current?.resource === args.event.resource
    ) {
      if (lineLeftRef.current) {
        document.body.removeChild(lineLeftRef.current);
        lineLeftRef.current = null;
      }
      if (lineRightRef.current) {
        document.body.removeChild(lineRightRef.current);
        lineRightRef.current = null;
      }

      document.removeEventListener('mousemove', handleMouseMove);
      initialPositionRef.current = null;
      hideTooltip();
      isDraggingRef.current = false;
      eventBeingModifiedRef.current = null;
      hoveredResourceRef.current = null;
      return;
    }

    if (
      args.event.Status === 'P' &&
      midDateToLocaleString(args.event.Start, userCTX.authenticationInfos.user?.Culture as string) !==
        midDateToLocaleString(moment(args.event.start).toDate(), userCTX.authenticationInfos.user?.Culture as string)
    ) {
      //modification de date d'arivée dune resa présente

      if (lineLeftRef.current) {
        document.body.removeChild(lineLeftRef.current);
        lineLeftRef.current = null;
      }
      if (lineRightRef.current) {
        document.body.removeChild(lineRightRef.current);
        lineRightRef.current = null;
      }
      if (initialEventState.current) {
        if (lineLeftRef.current) {
          document.body.removeChild(lineLeftRef.current);
          lineLeftRef.current = null;
        }
        if (lineRightRef.current) {
          document.body.removeChild(lineRightRef.current);
          lineRightRef.current = null;
        }

        document.removeEventListener('mousemove', handleMouseMove);
        initialPositionRef.current = null;
        hideTooltip();
        isDraggingRef.current = false;
        eventBeingModifiedRef.current = null;
        hoveredResourceRef.current = null;
        //remettre les données initiales
        const updatedEvents = event.map((e) => {
          if (e.id === args.event.id) {
            return {
              ...e,
              start: initialEventState.current?.start,
              Start: moment(initialEventState.current?.start).format('YYYY-MM-DD'),
              end: initialEventState.current?.end,
              End: moment(initialEventState.current?.end).format('YYYY-MM-DD'),

              resource: initialEventState.current?.resource, // Restaurer la ressource
            };
          }
          return e;
        });

        setEvent(updatedEvents.filter((e) => e.Id !== 'temp'));
      }
      toast.error('Le client est deja présent');
      throw 'Le client est deja présent';
    }
    setEvent([
      ...event,
      {
        ...args.event,
        Id: 'temp',
        id: 'temp',
        start: args.event.Start,
        end: args.event.End,
        resource: initialEventState.current?.resource,
      },
    ]);

    bookingCTX
      .checkMove({
        DateStart: moment(args.event.start).format('YYYY-MM-DD'),
        DateEnd: moment(args.event.end).format('YYYY-MM-DD'),
        IdBooking: args.event.id,
        IdRoom: args.resourceObj.Id,
      })
      .then((result) => {
        if (result?.Items && result?.Items.length > 0) {
          windowCTX.setWindow((prevWindow) => ({
            ...prevWindow,
            listOfWindowsDisplayed: prevWindow.listOfWindowsDisplayed.map(
              (window) =>
                window.id === 20000
                  ? { ...window, translatedTitle: 'Délogement' } // Met à jour l'élément ciblé
                  : window, // Garde les autres éléments inchangés
            ),
          }));
        }
      })
      .then(async () => {
        const confirmationPromise = new Promise((resolve, reject) => {
          const form = windowCTX.window.listOfWindowsDisplayed.find((e) => e.id === 20000);
          if (!form) {
            const id = 20000;
            const newWindow = {
              id: id, // ou utilisez un ID unique
              component: ConfirmationModale, // Remplacez par le composant que vous souhaitez afficher
              data: { args, event, rooms }, // Les données que vous souhaitez transmettre au composant
              translatedTitle: <>Confirmez vous la modification?</>,
              actions: {
                hideSubForm: () => windowCTX.hideWindow(id),
                onConfirm: (updatedValues: ConfirmedBooking) => {
                  windowCTX.hideWindow(id);
                  resolve(true);

                  // Met à jour l'état `event` avec les nouvelles données
                  setEvent((prevEvent) => {
                    // Fusionner les données existantes et nouvelles
                    return prevEvent
                      .filter((e) => e.Id !== 'temp')
                      .map((e) => {
                        const updated = updatedValues.Items.find((u) => u.IdBooking === e.id);
                        if (updated && updated.IdRoom) {
                          return {
                            ...e,
                            start: moment(updated.DateStart).toDate(), // Mettre à jour le champ start
                            end: moment(updated.DateEnd).toDate(), // Mettre à jour le champ end
                            Start: updated.DateStart,
                            End: updated.DateEnd,
                            IdRoom: updated.IdRoom,
                            resource: updated.IdRoom, // Mettre à jour le champ resource
                          };
                        } else if ((updated && updated?.IdRoom === undefined) || (updated && updated.IdRoom === null)) {
                          return null;
                        } else return e;
                      })
                      .filter((e): e is PlanningLine => e !== null);
                  });

                  if (updatedValues.Group) {
                    const id2 = 21000;
                    const groupWindow = {
                      id: id2, // ou utilisez un ID unique
                      component: GroupModale, // Remplacez par le composant que vous souhaitez afficher
                      data: { updatedValues, args, event, rooms }, // Les données que vous souhaitez transmettre au composant
                      translatedTitle: <>Groupe</>,
                      actions: {
                        hideSubForm: () => windowCTX.hideWindow(id2),
                        onConfirm: (updatedValues: BookingItem[]) => {
                          windowCTX.hideWindow(id2);
                          resolve(true);
                          // Met à jour l'état `event` avec les nouvelles données
                          setEvent((prevEvent) => {
                            // Fusionner les données existantes et nouvelles
                            return prevEvent
                              .filter((e) => e.Id !== 'temp')
                              .map((e) => {
                                const updated = updatedValues?.find((u) => u.IdBooking === e.id);
                                if (updated && updated.IdRoom) {
                                  return {
                                    ...e,
                                    start: moment(updated.DateStart).toDate(), // Mettre à jour le champ start
                                    end: moment(updated.DateEnd).toDate(), // Mettre à jour le champ end
                                    Start: updated.DateStart,
                                    End: updated.DateEnd,
                                    IdRoom: updated.IdRoom,
                                    resource: updated.IdRoom, // Mettre à jour le champ resource
                                  };
                                } else return e;
                              });
                          });
                        },
                        onCancel: () => {
                          windowCTX.hideWindow(id2); // Fermer la modale
                          reject(true); // Rejeter la promesse si l'utilisateur annule
                        },
                      },
                      draggable: false, // Définissez-le en fonction de vos besoins
                      border: '0px solid #666',
                      fullWidth: true, // Définissez-le en fonction de vos besoins
                      fullHeight: false, // Définissez-le en fonction de vos besoins
                      tabMode: true,
                      x: 'calc(50vw - 400px)',
                      y: '25vh',
                      width: '700px',
                      showBlackdiv: true,
                      showCloseBtn: false,
                      zIndex: 9999,
                      maxHeight: '450px',
                    };
                    windowCTX.setWindow((prevWindow) => ({
                      ...prevWindow,
                      listOfWindowsDisplayed: [...prevWindow.listOfWindowsDisplayed, groupWindow],
                    }));
                  }
                },

                onCancel: () => {
                  windowCTX.hideWindow(id); // Fermer la modale
                  reject(true); // Rejeter la promesse si l'utilisateur annule
                },
              },
              draggable: false, // Définissez-le en fonction de vos besoins
              border: '0px solid #666',
              fullWidth: true, // Définissez-le en fonction de vos besoins
              fullHeight: false, // Définissez-le en fonction de vos besoins
              tabMode: true,
              x: 'calc(50vw - 400px)',
              y: '25vh',
              width: '600px',
              showBlackdiv: true,
              showCloseBtn: false,
              zIndex: 9999,
              maxHeight: '475px',
            };

            // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
            windowCTX.setWindow((prevWindow) => ({
              ...prevWindow,
              listOfWindowsDisplayed: [...prevWindow.listOfWindowsDisplayed, newWindow],
            }));
          }
        });
        // Attend la réponse de la promesse (confirmation ou annulation)
        try {
          // Attendre la réponse de la promesse (confirmation ou annulation)
          const result = await confirmationPromise;

          if (lineLeftRef.current) {
            document.body.removeChild(lineLeftRef.current);
            lineLeftRef.current = null;
          }
          if (lineRightRef.current) {
            document.body.removeChild(lineRightRef.current);
            lineRightRef.current = null;
          }

          document.removeEventListener('mousemove', handleMouseMove);
          initialPositionRef.current = null;
          hideTooltip();
          isDraggingRef.current = false;
          eventBeingModifiedRef.current = null;
          hoveredResourceRef.current = null;
        } catch (error) {
          if (initialEventState.current) {
            if (lineLeftRef.current) {
              document.body.removeChild(lineLeftRef.current);
              lineLeftRef.current = null;
            }
            if (lineRightRef.current) {
              document.body.removeChild(lineRightRef.current);
              lineRightRef.current = null;
            }

            document.removeEventListener('mousemove', handleMouseMove);
            initialPositionRef.current = null;
            hideTooltip();
            isDraggingRef.current = false;
            eventBeingModifiedRef.current = null;
            hoveredResourceRef.current = null;
            //remettre les données initiales
            const updatedEvents = event.map((e) => {
              if (e.id === args.event.id) {
                return {
                  ...e,
                  start: initialEventState.current?.start,
                  Start: moment(initialEventState.current?.start).format('YYYY-MM-DD'),
                  end: initialEventState.current?.end,
                  End: moment(initialEventState.current?.end).format('YYYY-MM-DD'),

                  resource: initialEventState.current?.resource, // Restaurer la ressource
                };
              }
              return e;
            });

            setEvent(updatedEvents.filter((e) => e.Id !== 'temp'));
          }
          // Si l'utilisateur a annulé, ne rien faire ou annuler l'action
        }
        // Nettoie la ligne et retire l'écouteur de la souris
      });
  };

  const drawLine = (lineElement: any, startPos: Coord, endPos: Coord) => {
    const line = lineElement;
    if (line) {
      const deltaX = endPos.x - startPos.x;
      const deltaY = endPos.y - startPos.y;
      const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY); // Calcul de la longueur
      const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI); // Calcul de l'angle

      // Applique les styles pour dessiner la ligne
      line.style.width = `${length}px`;
      line.style.height = '2px'; // Ligne fine
      line.style.position = 'absolute';
      line.style.backgroundColor = '#333';

      // Centrer la rotation sur le point de départ
      line.style.transformOrigin = '0 0'; // Origine au début de la ligne
      line.style.transform = `rotate(${angle}deg)`; // Applique la rotation
      line.style.left = `${startPos.x}px`; // Position de départ de la ligne
      line.style.top = `${startPos.y}px`; // Position de départ de la ligne
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-12">
          {(isMobile || isTablet || isConditionMet) && (
            <div
              className={`small-box`}
              style={{ width: isPortrait ? '100%' : '50%', padding: '10px', background: '#f8f9fa' }}
            >
              <div className="inner">{<PlanningFilters></PlanningFilters>}</div>
            </div>
          )}
        </div>
      </div>
      {myResources && filtersCTX.filters.planningFilters && event.length >= 0 && zoneEvents !== null && rooms && (
        <>
          {filtersCTX.filters.planningFilters.CurrentSort != 4 && (
            <CustomTimelineRow>
              {' '}
              <Eventcalendar
                dragToCreate={config.CANMOVEPLANNING === 'true'}
                onEventDragStart={onEventDragStart}
                onEventDragEnd={(e) => onEventDragEnd(e)}
                theme="material"
                themeVariant="light"
                locale={
                  userCTX.authenticationInfos.language === 'fr'
                    ? localeFr
                    : userCTX.authenticationInfos.language === 'en'
                      ? localeEn
                      : userCTX.authenticationInfos.language === 'es'
                        ? localeEs
                        : userCTX.authenticationInfos.language === 'de'
                          ? localeDe
                          : userCTX.authenticationInfos.language === 'ma'
                            ? localeAr
                            : userCTX.authenticationInfos.language === 'cn'
                              ? localeZh
                              : ''
                }
                showEventTooltip={false}
                height={'93vh'}
                onDayHoverIn={() => null}
                className={darkMode ? 'darkmode' : 'lightmode'}
                eventOverlap={true}
                view={{
                  timeline: {
                    type: 'day',
                    virtualScroll: false,
                    resolutionHorizontal: 'day',
                    currentTimeIndicator: false,
                    size: filtersCTX.filters.planningFilters.ForceOneDay
                      ? 1
                      : getDeltaInDays(
                          filtersCTX.filters.planningFilters.DateStart as number,
                          filtersCTX.filters.planningFilters.DateEnd as number,
                        ) + 1,
                    eventList: true,
                  },
                }}
                refDate={moment(filtersCTX.filters.planningFilters.DateStart).format('YYYY-MM-DD')}
                colors={myPlanningColor}
                data={event}
                resources={myResources}
                renderResourceFooter={MemoizedResourceFooter}
                renderDayFooter={renderCustomDayFooter}
                renderResource={
                  filtersCTX.filters.planningFilters.CurrentSort === 1 ||
                  filtersCTX.filters.planningFilters.CurrentSort === 3
                    ? renderCustomResourceBreakingType
                    : renderCustomResource
                }
                renderResourceHeader={renderResourceHeader}
                dragBetweenResources={
                  userCTX.authenticationInfos.permissions?.find((p) => p.IdFunctionList === 101)?.CanAccess
                }
                dragInTime={
                  config.CANMOVEPLANNING === 'true' &&
                  userCTX.authenticationInfos.permissions?.find((p) => p.IdFunctionList === 101)?.CanAccess
                }
                dragToMove={
                  config.CANMOVEPLANNING === 'true' &&
                  userCTX.authenticationInfos.permissions?.find((p) => p.IdFunctionList === 101)?.CanAccess
                }
                dragTimeStep={1}
                dragToResize={
                  config.CANMOVEPLANNING === 'true' &&
                  userCTX.authenticationInfos.permissions?.find((p) => p.IdFunctionList === 101)?.CanAccess
                }
                renderScheduleEvent={myScheduleEvent}
                selectedDate={moment(filtersCTX.filters.planningFilters.DateStart).format('YYYY-MM-DD')}
                renderDay={renderDay}
                onEventClick={onEventClick}
                //  onEventDragEnd={onEventDragEnd}
                onSelectedDateChange={(event) => {
                  planningCTX.getPlanningDetail(filtersCTX.filters.planningFilters);
                  planningCTX.getZoneEvents(filtersCTX.filters.planningFilters);

                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    planningFilters: {
                      ...prevState.planningFilters,
                      DateStart: new Date(event.date as Date).getTime(),
                      DateEnd: moment(new Date(event.date as Date))
                        .add(21, 'day')
                        .toDate()
                        .getTime(),
                    },
                  }));
                  planningCTX.getPlanning(filtersCTX.filters.planningFilters);
                }}
              ></Eventcalendar>
            </CustomTimelineRow>
          )}
          {filtersCTX.filters.planningFilters.CurrentSort === 4 && (
            <Point
              updateParentMethod={handleUpdate}
              renderDate={moment(userCTX.authenticationInfos.selectedHotel?.CurrentPlanningDate).format('YYYY-MM-DD')}
            ></Point>
          )}
          {tooltip.visible && (
            <div
              style={{
                position: 'absolute',
                top: tooltip.y, // Position un peu en dessous de la souris
                left: tooltip.x, // Position un peu à droite de la souris
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                color: '#fff',
                padding: '5px',
                borderRadius: '4px',
                fontSize: '12px',
                pointerEvents: 'none', // Éviter que le tooltip n'interfère avec d'autres éléments
                zIndex: 1000,
              }}
            >
              {tooltip.text}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Planning;
