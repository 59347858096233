import React, { useEffect, useState } from 'react';

import Impersonate from '@app/components/Common/Impersonate';
import { useSubForm } from '@app/modules/Modal/SubFormContext';
import { useUser } from '@app/modules/user/UserContext';
import { useParams } from 'react-router-dom';

const Installation = () => {
  const { type, level, CanAccess, PermissionId } = useParams();
  const userCTX = useUser();
  const subFormCTX = useSubForm();
  const [iframeUrl, setIframeUrl] = useState('');

  useEffect(() => {
    return () => {
      if (subFormCTX) {
        subFormCTX.hideSubForm(99999);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchUrl() {
      if (userCTX) {
        if (CanAccess === 'false') {
          setIframeUrl('');
          const id = 99999;
          const newSubForm = {
            id: id, // ou utilisez un ID unique
            component: Impersonate,
            data: { PermissionId: PermissionId }, // Les données que vous souhaitez transmettre au composant
            actions: {
              hideSubForm: async () => {
                subFormCTX.hideSubForm(id);
                const url = await userCTX.RedirectToInstallation(type ?? '', level ?? '');

                setIframeUrl(url as string);
              },
            },
            cantBeClose: true,
            draggable: false, // Définissez-le en fonction de vos besoins
            fullWidth: false, // Définissez-le en fonction de vos besoins
            fullHeight: false, // Définissez-le en fonction de vos besoins
            width: '50%',
            height: '50%',
            x: '25%',
            y: '5%',
            translatedTitle: 'Elévation de droits utilisateur',
          };

          // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
          subFormCTX.setSubForm((prevSubForm) => ({
            ...prevSubForm,
            listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
          }));
        } else {
          const url = await userCTX.RedirectToInstallation(type ?? '', level ?? '');
          setIframeUrl(url as string);
        }
      }
    }

    fetchUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCTX, type, level, CanAccess, PermissionId]);

  return (
    <div style={{ height: '93vh' }}>
      <div className="container-fluid" style={{ height: '100%', padding: 0, margin: 0 }}>
        <div className="card" style={{ height: '100%' }}>
          {iframeUrl !== '' && (
            <iframe src={iframeUrl} width="100%" height="100%" frameBorder="0">
              Chargement...
            </iframe>
          )}
        </div>
      </div>
    </div>
  );
};

export default Installation;
