import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';

import { config } from '@app/utils/config';
import { getLogger } from 'loglevel';

import axios from '@services/axios/Axios';

import { useUser } from '../user/UserContext';
import { SocketMessage } from '@app/types/types';
import { decodeNotificationIdentifier } from '@app/types/dataChangeNotification';
import moment from 'moment-timezone';
import BaseForm from '@app/components/Common/popup/BaseForm';
import { useMail } from '../Mail/MailContext';
import { toast } from 'react-toastify';
import EventEmitter from 'eventemitter3';

export const WSEventEmitter = new EventEmitter();

// Define the type for your params
export type MessageWindowType = {
  listOfMessageWindowDisplayed: {
    id: string;
    actions: any;
    component: React.ComponentType<any>;
    x?: number | string;
    y?: number | string;
    data: any;
    translatedTitle: string;
  }[];
};
// Define the interface for ParamsContextState
export interface WsContextState {
  isConnected: boolean;
  isInHotel: boolean;
  connectHotel: (IdHotel: number) => Promise<string | undefined>;
  Send: (message: SocketMessage) => void;

  getConnectedUser: () => void;
  connectedUser: any;
  setConnectedUser: React.Dispatch<React.SetStateAction<any>>;
  messageWindow: MessageWindowType;
  messages: { [sender: string]: SocketMessage[] } | null;
  updateMessages: (newMessage: SocketMessage) => void;
  updateMessagesSended: (newMessage: SocketMessage) => void;

  setMessageWindow: React.Dispatch<React.SetStateAction<MessageWindowType>>;
  hideMessageWindow: (id: string) => void; // Add this
}

// Create a context to hold your params with default values
export const WsContext = createContext<WsContextState | undefined>(undefined);

// Define the props type for the ParamsProvider component
type WsProviderProps = {
  children: ReactNode;
};

// Create a provider component
export const WsProvider: React.FC<WsProviderProps> = (props) => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isInHotel, setIsInHotel] = useState<boolean>(false);
  const [connectedUser, setConnectedUser] = useState(null);
  const pingIntervalRef = useRef<NodeJS.Timeout | null>(null); // Ref pour gérer l'intervalle de ping
  const getConnectedIntervalRef = useRef<NodeJS.Timeout | null>(null); // Ref pour gérer l'intervalle getConnectedUser
  const connectRef = useRef<() => void>(); // Ref pour gérer l'intervalle de connection

  const socketRef = useRef<WebSocket | null>(null);
  const userCTX = useUser();
  const mailCtx = useMail();
  const [messages, setMessages] = useState<{ [sender: string]: SocketMessage[] }>({});
  const [messageWindow, setMessageWindow] = useState<MessageWindowType>({ listOfMessageWindowDisplayed: [] });

  const hideMessageWindow = (id: string) => {
    setMessageWindow((prevWindow) => ({
      ...prevWindow,
      listOfMessageWindowDisplayed: prevWindow.listOfMessageWindowDisplayed.filter((form) => form.id !== id),
    }));
  };

  useEffect(() => {
    console.log(socketRef.current, userCTX.authenticationInfos.user);
    if (userCTX.authenticationInfos.user === null && socketRef.current !== null) {
      socketRef.current.close();
      socketRef.current = null;
    }
  }, [userCTX.authenticationInfos.user, socketRef.current]);

  const updateMessages = (newMessage: SocketMessage) => {
    newMessage.Date = moment(Date.now()).format('DD/MM/YY HH:MM');

    setMessages((prevMessages) => {
      const sender = newMessage.SenderUserId;
      const updatedMessages = { ...prevMessages };
      if (!updatedMessages[sender]) {
        updatedMessages[sender] = [];
      }
      updatedMessages[sender].push(newMessage.Message);
      return updatedMessages;
    });
  };

  const updateMessagesSended = (newMessage: SocketMessage) => {
    newMessage.Date = moment(Date.now()).format('DD/MM/YY HH:MM');

    setMessages((prevMessages) => {
      const sender = newMessage.RecipientUserId;
      const updatedMessages = { ...prevMessages };
      if (!updatedMessages[sender as string]) {
        updatedMessages[sender as string] = [];
      }
      updatedMessages[sender as string].push(newMessage);
      return updatedMessages;
    });
  };

  const Send = async (message: SocketMessage) => {
    try {
      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :

      const urlToPass = 'Send';
      const response = await axios.post('/Socket/' + urlToPass, message);
      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }
      const data: MedialogResponse = await response.data;
      return data;
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const connectHotel = async (IdHotel: number) => {
    try {
      const params = {
        IdHotel: IdHotel.toString(),
        IdUser: userCTX.authenticationInfos.user?.Id,
        IdConnection: userCTX.authenticationInfos.connectionID,
      };

      const response = await axios.post('/Socket/AddUserToHotel', params);
      if (response.status !== 200) {
        setIsInHotel(false);
        setIsConnected(false);
      } else {
        setIsInHotel(true);
      }

      const data: string = (await response.data) as string;

      return data;
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  useEffect(() => {
    console.log(userCTX.authenticationInfos.connectionID, userCTX.authenticationInfos.user?.Id);
    connectRef.current = () => {
      console.log(userCTX.authenticationInfos.connectionID, userCTX.authenticationInfos.user?.Id);

      console.log('je suis reelement dans kle useeffecy conencty');
      if (userCTX.authenticationInfos.user?.Id !== undefined && userCTX.authenticationInfos.connectionID) {
        const ws = new WebSocket(
          config.WS_URL +
            userCTX.authenticationInfos.user?.Id +
            '&connectionId=' +
            userCTX.authenticationInfos.connectionID +
            '&isTech=' +
            userCTX.authenticationInfos.user?.IsAdmin,
        );
        ws.onopen = () => {
          setIsConnected(true);
          pingIntervalRef.current = setInterval(() => {
            if (ws.readyState === WebSocket.OPEN) {
              const pingMessage: SocketMessage = {
                SenderUserId: userCTX.authenticationInfos.user?.Id || '',
                Message: 'ping',
                MessageType: 2,
                Date: moment(Date.now()).format('YYYY-MM-DD'),
              };
              ws.send(JSON.stringify(pingMessage));
            }
          }, 5000);
          getConnectedIntervalRef.current = setInterval(() => {
            getConnectedUser();
          }, 60000);
        };
        ws.onclose = async () => {
          setIsConnected(false);
          setIsInHotel(false);
          console.log(userCTX.authenticationInfos.connectionID, userCTX.authenticationInfos.user?.Id);
          if (userCTX.authenticationInfos.connectionID && userCTX.authenticationInfos.user?.Id) {
            setTimeout(() => connectRef.current?.(), 1000);
          }
          if (pingIntervalRef.current) {
            clearInterval(pingIntervalRef.current);
          }
          if (getConnectedIntervalRef.current) {
            clearInterval(getConnectedIntervalRef.current);
          }
        };
        ws.onmessage = (event) => {
          const socketMessage: SocketMessage = JSON.parse(event.data);
          switch (socketMessage.MessageType) {
            case 0: //PMS Message
              console.log('message PMS');
              WSEventEmitter.emit('ReceivedPMSMessage', decodeNotificationIdentifier(socketMessage.Message));

              break;
            case 1: // User Message
              break;
            case 2:
              updateMessages(JSON.parse(event.data));

              break;
            default:
              break;
          }
        };
        socketRef.current = ws;
      }
    };

    if (userCTX.authenticationInfos.connectionID && userCTX.authenticationInfos.user?.Id) {
      console.log(
        'je susi dans le connect',
        userCTX.authenticationInfos.connectionID,
        userCTX.authenticationInfos.user?.Id,
      );
      connectRef.current?.(); // Lance la connexion initiale
    }
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
      if (pingIntervalRef.current) {
        clearInterval(pingIntervalRef.current);
      }
      if (getConnectedIntervalRef.current) {
        clearInterval(getConnectedIntervalRef.current);
      }
    };
  }, [userCTX.authenticationInfos.connectionID, userCTX.authenticationInfos.user?.Id]);

  useEffect(() => {
    if (isConnected && userCTX.authenticationInfos.selectedHotel) {
      connectHotel(userCTX.authenticationInfos.selectedHotel.IdHotel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, userCTX.authenticationInfos.selectedHotel]);

  const getConnectedUser = async () => {
    const response = await axios.get('/Socket/GetConnectedUser', {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status !== 200) {
      throw new Error('Erreur : ');
    }

    const data = await response.data;
    setConnectedUser(data);
    return data;
  };

  return (
    <WsContext.Provider
      value={{
        isConnected,
        connectHotel,
        isInHotel,
        Send,
        connectedUser,
        getConnectedUser,
        setConnectedUser,

        messages,
        updateMessages,
        messageWindow,
        hideMessageWindow,
        setMessageWindow,
        updateMessagesSended,
      }}
    >
      {' '}
      {messageWindow.listOfMessageWindowDisplayed.map((form, index) => {
        return (
          <BaseForm
            id={form.id}
            data={form.data}
            translatedTitle={form.translatedTitle ?? ''}
            key={index}
            component={form.component}
            actions={form.actions}
            draggable={false}
            fullWidth={false}
            fullHeight={false}
            x={form.x}
            y={form.y}
            zIndex={999}
          />
        );
      })}
      {props.children}
    </WsContext.Provider>
  );
};

// Create a custom hook to access the params
export function useWs(): WsContextState {
  const wsContext = useContext(WsContext);
  if (wsContext === undefined) {
    throw new Error('useWs must be used within a WsProvider');
  }
  return wsContext;
}
