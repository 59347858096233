import React, { createContext, ReactNode, useContext, useState } from 'react';

import { getLogger } from 'loglevel';

import axios from '@services/axios/Axios';

// Define the type for your params

// Define the interface for ParamsContextState
export interface BookingContextState {
  BookingSummary: BookingSummary | null;
  getBookingSummary: (params: bookingSummaryParams) => Promise<BookingSummary | undefined>;
  setBookingSummary: (BookingSummary: BookingSummary) => void;
  bookingsProposal: Bookings | null;
  checkMove: (Booking: BookingMove) => Promise<Bookings | undefined>;
  setBookingsProposal: (Proposal: Bookings | null) => void;

  confirmedBookings: ConfirmedBooking | null;
  confirmMove: (confirmMove: ConfirmMove) => Promise<ConfirmedBooking | undefined>;
  setConfirmedBookings: (confirmedBookings: ConfirmedBooking | null) => void;

  confirmedGroupBookings: BookingItem[] | null;
  confirmGroupMove: (confirmMove: BookingMove[]) => Promise<BookingItem[] | undefined>;
  setConfirmedGroupBookings: (confirmedGroupBookings: BookingItem[] | null) => void;
}

interface bookingSummaryParams {
  params: TechAndHotelParams;
  idBooking: string | number;
}
// Create a context to hold your params with default values
export const BookingContext = createContext<BookingContextState | undefined>(undefined);

// Define the props type for the ParamsProvider component
type BookingProviderProps = {
  children: ReactNode;
};

// Create a provider component
export const BookingProvider: React.FC<BookingProviderProps> = (props) => {
  const [BookingSummary, setBookingSummary] = useState<BookingSummary | null>(null);
  const [bookingsProposal, setBookingsProposal] = useState<Bookings | null>(null);
  const [confirmedBookings, setConfirmedBookings] = useState<ConfirmedBooking | null>(null);
  const [confirmedGroupBookings, setConfirmedGroupBookings] = useState<BookingItem[] | null>(null);

  const getBookingSummary = async (params: bookingSummaryParams) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.get('Bookings/Summary/' + params.idBooking);

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setBookingSummary(data.Data as BookingSummary);
      return data.Data as BookingSummary;
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const checkMove = async (params: BookingMove) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.post('Bookings/CheckMove/', params);

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setBookingsProposal(data.Data as Bookings);
      return data.Data as Bookings;
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const confirmMove = async (params: ConfirmMove) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.post('Bookings/ConfirmMove/', params);

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      if (data.ErrorCode === -1) {
        throw new Error('Erreur : Impossible ');
      }

      setConfirmedBookings(data.Data as ConfirmedBooking);
      return data.Data as ConfirmedBooking;
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const confirmGroupMove = async (params: BookingMove[]) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.post('Bookings/ConfirmGroupMove/', params);

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      if (data.ErrorCode === -1) {
        throw new Error('Erreur : Impossible ');
      }

      setConfirmedGroupBookings(data.Data as BookingItem[]);
      return data.Data as BookingItem[];
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  return (
    <BookingContext.Provider
      value={{
        BookingSummary,
        getBookingSummary,
        setBookingSummary,
        bookingsProposal,
        checkMove,
        setBookingsProposal,
        confirmedBookings,
        confirmMove,
        setConfirmedBookings,
        confirmedGroupBookings,
        confirmGroupMove,
        setConfirmedGroupBookings,
      }}
    >
      {props.children}
    </BookingContext.Provider>
  );
};

// Create a custom hook to access the params
export function useBookings(): BookingContextState {
  const bookingContext = useContext(BookingContext);
  if (bookingContext === undefined) {
    throw new Error('useBooking must be used within a BookingProvider');
  }
  return bookingContext;
}
