import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { SingleKardexType } from '@app/types/types';
import { Button } from 'primereact/button';
import { TabPanel, TabView } from 'primereact/tabview';

import BaseForm from '@components/Common/popup/BaseForm';

import { useKardex } from '../kardex/KardexContext';

// Define the type for your params
export type TabViewType = {
  listOfTabsDisplayed: {
    id: number;
    component: React.ComponentType<any>;
    data: any;
    actions: any;
    componentType: string;
    translatedTitle?: string;
    draggable: boolean;
    fullWidth: boolean;
    fullHeight: boolean;
    x?: number | string;
    y?: number | string;
    width?: number | string;
    height?: number | string;
  }[];
};

// Define the interface for ParamsContextState
export interface TabviewContextState {
  tabView: TabViewType;
  activeTab: number;
  setActiveTab: (activeTab: number) => void;
  currentID: number;
  setCurrentID: (id: number) => void;
  setTabView: React.Dispatch<React.SetStateAction<TabViewType>>;
  hideTabView: (id: number) => void; // Add this
  minimize: boolean;
  minimizeTabView: (minimize: boolean) => void;
}

// Create a context to hold your params with default values
export const TabviewContext = createContext<TabviewContextState | undefined>(undefined);

// Define the props type for the ParamsProvider component
type TabviewProviderProps = {
  children: ReactNode;
};

// Create a provider component
export const TabViewProvider: React.FC<TabviewProviderProps> = (props) => {
  const [tabView, setTabView] = useState<TabViewType>({ listOfTabsDisplayed: [] });
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [currentID, setCurrentID] = useState(0);
  const [minimize, minimizeTabView] = useState(true);
  const kardexCTX = useKardex();

  const hideTabView = (id: number) => {
    setTabView((prevTabView) => ({
      ...prevTabView,
      listOfTabsDisplayed: prevTabView.listOfTabsDisplayed.filter((form) => form.id !== id),
    }));
  };

  useEffect(() => {
    setActiveIndex(
      tabView.listOfTabsDisplayed.findIndex((e) => e.id === activeTab) < 0
        ? 0
        : tabView.listOfTabsDisplayed.findIndex((e) => e.id === activeTab),
    );
  }, [activeTab, tabView.listOfTabsDisplayed]);

  return (
    <TabviewContext.Provider
      value={{
        tabView,
        hideTabView,
        setTabView,
        activeTab,
        setActiveTab,
        currentID,
        setCurrentID,
        minimize,
        minimizeTabView,
      }}
    >
      {tabView.listOfTabsDisplayed.length > 0 && minimize === false && (
        <>
          <TabView
            onTabChange={(e) => {
              kardexCTX.setKardexOperations(null);
              kardexCTX.setStays(null);

              setActiveIndex(e.index);
              if (tabView.listOfTabsDisplayed[e.index].componentType === 'Kardex') {
                if (e.index > 0) {
                  kardexCTX.setStayFilters({
                    ...kardexCTX.StayFilters,
                    IdKardex: tabView.listOfTabsDisplayed[e.index]?.data?.Id,
                  });

                  /* kardexCTX.getKardekById(
                  userCTX?.authenticationInfos?.selectedHotel?.IdHotel as number,
                  tabView.listOfTabsDisplayed[e.index].data.Id ?? '',
                );*/
                  kardexCTX.setCurrentKardex(tabView.listOfTabsDisplayed[e.index].data as SingleKardexType);
                }
              }
            }}
            activeIndex={activeIndex ?? 0}
            onTabClose={(e) => hideTabView(tabView.listOfTabsDisplayed[e.index].id)}
          >
            {tabView.listOfTabsDisplayed.map((form, index) => {
              return (
                <TabPanel closable={index > 0} key={form.id + 1} header={form.translatedTitle}>
                  <BaseForm
                    id={form.id}
                    data={form.data}
                    translatedTitle={form?.translatedTitle ?? ''}
                    key={form.id}
                    component={form.component}
                    actions={form.actions}
                    draggable={form.draggable}
                    fullWidth={form.fullWidth}
                    fullHeight={form.fullHeight}
                    x={form.x}
                    width={form.width}
                    height={form.height}
                    y={form.y}
                    tabMode={true}
                  />
                </TabPanel>
              );
            })}
          </TabView>
          <Button
            icon="fa-solid fa-window-minimize"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              height: '52px',
              width: '52px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => minimizeTabView(true)}
          ></Button>
        </>
      )}

      {props.children}
    </TabviewContext.Provider>
  );
};

// Create a custom hook to access the params
export function useTabView(): TabviewContextState {
  const tabviewContext = useContext(TabviewContext);
  if (tabviewContext === undefined) {
    throw new Error('useTabView must be used within a TabViewProvider');
  }
  return tabviewContext;
}
