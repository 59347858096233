import { useTabView } from '@app/modules/Modal/TabViewContext';
import { KardexType } from '@app/types/types';

import MobileKardex from './Kardex';

interface props {
  user: KardexType;
}

const KardexUserItem: React.FC<props> = (props) => {
  const tabViewCTX = useTabView();

  const onClick = async () => {
    const id = tabViewCTX.currentID;
    const newTabView = {
      id: id, // ou utilisez un ID unique
      component: MobileKardex, // Remplacez par le composant que vous souhaitez afficher
      data: props.user, // Les données que vous souhaitez transmettre au composant
      actions: { hideTabView: () => tabViewCTX.hideTabView(id) },
      draggable: false, // Définissez-le en fonction de vos besoins
      fullWidth: true, // Définissez-le en fonction de vos besoins
      fullHeight: true, // Définissez-le en fonction de vos besoins
      translatedTitle: props.user.Nom,
      componentType: 'Kardex',
    };

    // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
    tabViewCTX.setTabView((prevSubForm) => ({
      ...prevSubForm,
      listOfTabsDisplayed: [...prevSubForm.listOfTabsDisplayed, newTabView],
    }));

    tabViewCTX.setCurrentID(id + 1);
    tabViewCTX.setActiveTab(id);
  };
  return (
    <div
      onTouchStart={onClick}
      style={{
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        alignContent: 'space-between',
        height: '100%',
        borderBottom: '1px solid var(--color-primary-main)',
        margin: '10px',
      }}
      className="selectableUser"
    >
      <span>
        <i className="fa-solid fa-user"></i>
        <span style={{ fontWeight: 'bolder' }}>
          {props.user.Nom} {props.user.Prenom !== props.user.Nom ? props.user.Prenom : ''}
        </span>
      </span>
      <span>{props.user.Email}</span>
      <span>{props.user.MobilePhone?.Number}</span>
    </div>
  );
};
export default KardexUserItem;
