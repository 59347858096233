/* eslint-disable max-len */
import { useEffect, useState } from 'react';

import BarChart from '@app/components/Common/charts/BarChart';
import LineChart from '@app/components/Common/charts/LineChart';
import PerformanceFilters from '@app/components/Common/filter/PerformanceFilters';
import PrevisionFilters from '@app/components/Common/filter/PrevisionFilters';
import { AnalytiqueProvider, useAnalytics } from '@app/modules/Analytics/AnalyticsContext';
import { useFilters } from '@app/modules/filter/filterContext';
import { useSubForm } from '@app/modules/Modal/SubFormContext';
import { useUser } from '@app/modules/user/UserContext';
import colors from '@app/styles/colors';
import { analyticsKey, stackedComponent } from '@app/types/types';
import { shortDateToLocaleStringWithoutYear, shortOnlyMonthDateToLocaleString } from '@app/utils/dates';
import { useMediaQuery } from '@app/utils/useMediaQuery';
import { ChartOptions } from 'chart.js';
import moment from 'moment-timezone';
import { Button } from 'primereact/button';
import { isMobile, isTablet, useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import PerformanceDetail from './PerformanceDetail';

import 'chart.js/auto';
import ExportExcel from '@app/components/Common/export/ExportExcel';

const Performance: React.FC = () => {
  const [t] = useTranslation();
  const [stackedComponent, setStackedComponent] = useState<stackedComponent | null>(null);

  const AnalyticsData: analyticsKey[] = [
    { name: t('smallbox.label.TO'), code: 0, value: 'OccupancyRate' },

    { name: t('smallbox.label.PM'), code: 1, value: 'Adr' },

    { name: t('smallbox.label.RevPar'), code: 2, value: 'RevPar' },

    { name: t('smallbox.label.RoomRevenue'), code: 3, value: 'AccomodationTurnOver' },

    { name: t('smallbox.label.GlobalRevenue'), code: 4, value: 'TurnOver' },

    { name: t('smallbox.label.ALOS'), code: 5, value: 'Alos' },
    { name: t('prevision.label.PMH'), code: 6, value: 'RevPor' },
    { name: t('header.label.occupancy'), code: 7, value: 'RoomsSoldQty' },
    { name: t('labels.arrivals'), code: 8, value: 'ArrivalsQty' },
    { name: t('labels.departures'), code: 9, value: 'DeparturesQty' },

    { name: t('labels.outoforder'), code: 10, value: 'OutOfOrder' },
    { name: t('labels.TrevPar'), code: 11, value: 'TrevPar' },
    { name: t('labels.RoomsSetQty'), code: 12, value: 'RoomsSetQty' },
    { name: t('labels.Alt'), code: 13, value: 'Alt' },
    { name: t('labels.Arg'), code: 14, value: 'Arg' },
    { name: t('labels.TRevPe'), code: 15, value: 'TRevPe' },
    { name: t('labels.CancellationRate'), code: 16, value: 'CancellationRate' },
  ];
  const { orientation } = useMobileOrientation();
  const [hoveredId, setHoveredId] = useState<number | null>(null);

  const filtersCTX = useFilters();
  const analyticsCtx = useAnalytics();
  const subFormCTX = useSubForm();
  const userCTX = useUser();
  const performanceData = analyticsCtx.AnalyticsPerformance;
  const mergeOptions: ChartOptions<'line' | 'bar'> = {};
  const [mergeOptions2, setMergeOptions2] = useState<ChartOptions<'line' | 'bar'>>({});
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const isLandscape = useMediaQuery('(orientation: landscape)');
  const isNarrowScreen = useMediaQuery('(max-width: 900px)');

  const [isConditionMet, setIsConditionMet] = useState(false);

  useEffect(() => {
    setIsConditionMet(isPortrait && isNarrowScreen);
  }, [isPortrait, isNarrowScreen, isLandscape]);

  useEffect(() => {
    return () => {
      if (subFormCTX) {
        subFormCTX.hideSubForm(15000);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filtersCTX.filters.performanceFilters.needReload === true) {
      analyticsCtx.getAnalyticsPerformance(filtersCTX.filters.performanceFilters);
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        performanceFilters: {
          ...prevState.performanceFilters,
          needReload: false,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersCTX.filters.performanceFilters]);

  useEffect(() => {
    if (stackedComponent?.from?.code !== undefined && stackedComponent?.to?.code !== undefined) {
      const newOptions: ChartOptions<'line' | 'bar'> = {
        scales: {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            title: {
              display: true,
              text: AnalyticsData.find((e) => e.code === stackedComponent.from?.code)?.name,
            },
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            title: {
              display: true,
              text: AnalyticsData.find((e) => e.code === stackedComponent.to?.code)?.name,
            },
            grid: {
              drawOnChartArea: false, // Cela empêche l'affichage de la grille de cet axe sur l'aire du graphique
            },
          },
        },
      };

      setMergeOptions2(newOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stackedComponent]);

  useEffect(() => {
    if (performanceData) {
      setMergeOptions2({
        plugins: {
          tooltip: {
            callbacks: {
              // Modifier le label du tooltip pour inclure des informations sur les ticks
              title: (tooltipItems) => {
                const tooltipItem = tooltipItems[0];
                const label = tooltipItem.label;
                let extraInfo = '';
                performanceData?.Events.map((t) =>
                  t.Data.map(
                    (date) =>
                      (extraInfo = moment(tooltipItem.label).isBetween(
                        date.Dates.DateStart,
                        date.Dates.DateEnd,
                        undefined,
                        '[]',
                      )
                        ? `\n${t.ShortName + ' : ' + date.Description}`
                        : ''),
                  ),
                );

                return `${label} ${extraInfo}`;
              },
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: (context) => {
                // Colorer les labels après '3 Jan'
                let color = '';
                performanceData?.Events.map((t) =>
                  t.Data.map((date) => {
                    color = moment(context.tick.label).isBetween(
                      date.Dates.DateStart,
                      date.Dates.DateEnd,
                      undefined,
                      '[]',
                    )
                      ? (color = date.Background)
                      : '';
                  }),
                );
                return color;
              },
              textStrokeColor: (context) => {
                // Colorer les labels après '3 Jan'
                let color = '';
                performanceData?.Events.map((t) =>
                  t.Data.map((date) => {
                    color = moment(context.tick.label).isBetween(
                      date.Dates.DateStart,
                      date.Dates.DateEnd,
                      undefined,
                      '[]',
                    )
                      ? (color = date.Background)
                      : '';
                  }),
                );
                return color;
              },
            },
          },
        },
      });
    }
  }, [performanceData]);

  const handleDragStart = (e: React.DragEvent, data: any) => {
    // You can store any relevant data using the dataTransfer object
    e.dataTransfer?.setData('text/plain', JSON.stringify(data));
  };

  const openModale = (id: number) => {
    const idModal = 15000;
    const newSubForm = {
      id: idModal, // ou utilisez un ID unique
      component: PerformanceDetail, // Remplacez par le composant que vous souhaitez afficher

      data: {
        dataIndex: id,
        originalData: performanceData,
        mergeOptions: {
          plugins: {
            tooltip: {
              callbacks: {
                // Modifier le label du tooltip pour inclure des informations sur les ticks
                title: (tooltipItems: any) => {
                  const tooltipItem = tooltipItems[0];
                  const label = tooltipItem.label;
                  let extraInfo = '';
                  performanceData?.Events.map((t) =>
                    t.Data.map(
                      (date) =>
                        (extraInfo = moment(tooltipItem.label).isBetween(
                          date.Dates.DateStart,
                          date.Dates.DateEnd,
                          undefined,
                          '[]',
                        )
                          ? `\n${t.ShortName + ' : ' + date.Description}`
                          : ''),
                    ),
                  );

                  return `${label} ${extraInfo}`;
                },
              },
            },
          },
          scales: {
            x: {
              ticks: {
                color: (context: any) => {
                  // Colorer les labels après '3 Jan'
                  let color = '';
                  performanceData?.Events.map((t) =>
                    t.Data.map((date) => {
                      color = moment(context.tick.label).isBetween(
                        date.Dates.DateStart,
                        date.Dates.DateEnd,
                        undefined,
                        '[]',
                      )
                        ? (color = date.Background)
                        : '';
                    }),
                  );
                  return color;
                },
                textStrokeColor: (context: any) => {
                  // Colorer les labels après '3 Jan'
                  let color = '';
                  performanceData?.Events.map((t) =>
                    t.Data.map((date) => {
                      color = moment(context.tick.label).isBetween(
                        date.Dates.DateStart,
                        date.Dates.DateEnd,
                        undefined,
                        '[]',
                      )
                        ? (color = date.Background)
                        : '';
                    }),
                  );
                  return color;
                },
              },
            },
          },
        },
        stackedComponent: stackedComponent,
        data: {
          datasets: [
            {
              label: AnalyticsData.find((e) => e.code === id)?.name,
              data: performanceData?.Data.map(
                (t) => t.Current[AnalyticsData?.find((e) => e.code === id)?.value as keyof typeof t.Current],
              ),
              backgroundColor: function (context: any) {
                const index = context.dataIndex;
                const label = context?.chart?.data?.labels?.[index];
                let color = '#f8a2a0'; // Couleur par défaut
                performanceData?.Events.forEach((event) => {
                  event.Data.forEach((date) => {
                    if (moment(label as string).isBetween(date.Dates.DateStart, date.Dates.DateEnd, undefined, '[]')) {
                      color = date.Foreground;
                    }
                  });
                });
                return color;
              },
              borderColor: function (context: any) {
                const index = context.dataIndex;
                const label = context?.chart?.data?.labels?.[index];
                let color = '#f8a2a0'; // Couleur par défaut
                performanceData?.Events.forEach((event) => {
                  event.Data.forEach((date) => {
                    if (moment(label as string).isBetween(date.Dates.DateStart, date.Dates.DateEnd, undefined, '[]')) {
                      color = date.Background;
                    }
                  });
                });
                return color;
              },
            },
            {
              label:
                AnalyticsData.find((e) => e.code === id)?.name +
                ' en ' +
                filtersCTX.filters.performanceFilters.YearToCompare,
              data: performanceData?.Data.map(
                (t) => t.Previous[AnalyticsData?.find((e) => e.code === id)?.value as keyof typeof t.Previous],
              ),
              borderColor: function (context: any) {
                const index = context.dataIndex;
                const label = context?.chart?.data?.labels?.[index];
                let color = '#ffa600'; // Couleur par défaut
                performanceData?.Events.forEach((event) => {
                  event.Data.forEach((date) => {
                    if (moment(label as string).isBetween(date.Dates.DateStart, date.Dates.DateEnd, undefined, '[]')) {
                      color = date.Background;
                    }
                  });
                });
                return color;
              },
              backgroundColor: function (context: any) {
                const index = context.dataIndex;
                const label = context?.chart?.data?.labels?.[index];
                let color = '#ffa600'; // Couleur par défaut
                performanceData?.Events.forEach((event) => {
                  event.Data.forEach((date) => {
                    if (moment(label as string).isBetween(date.Dates.DateStart, date.Dates.DateEnd, undefined, '[]')) {
                      color = date.Foreground;
                    }
                  });
                });
                return color;
              },
            },
          ],
          labels: performanceData?.Data.map((t) =>
            filtersCTX.filters.performanceFilters.GroupBy === 0 || filtersCTX.filters.performanceFilters.GroupBy === 3
              ? shortDateToLocaleStringWithoutYear(
                  moment(t.Label).toDate(),
                  userCTX.authenticationInfos.language ?? 'fr',
                )
              : filtersCTX.filters.performanceFilters.GroupBy === 2
                ? shortOnlyMonthDateToLocaleString(
                    moment(t.Label).toDate(),
                    userCTX.authenticationInfos.language ?? 'fr',
                  )
                : t.Label,
          ),
        },
      }, // Les données que vous souhaitez transmettre au composant
      actions: {
        hideSubForm: () => {
          subFormCTX.hideSubForm(idModal);
        },
      },
      draggable: false, // Définissez-le en fonction de vos besoins
      fullWidth: true, // Définissez-le en fonction de vos besoins
      fullHeight: true, // Définissez-le en fonction de vos besoins
      translatedTitle: '',
    };

    // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
    subFormCTX.setSubForm((prevSubForm) => ({
      ...prevSubForm,
      listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
    }));
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    const data = e.dataTransfer?.getData('text');
    if (data) {
      const chartData = JSON.parse(data);
      if (
        performanceData &&
        chartData !== hoveredId &&
        AnalyticsData &&
        hoveredId !== null &&
        hoveredId >= 0 === true &&
        AnalyticsData?.find((e) => e.code === chartData)?.value !== undefined
      ) {
        setStackedComponent({
          ...stackedComponent,
          from: {
            datasets: [
              {
                label: AnalyticsData.find((e) => e.code === chartData)?.name ?? '',
                type: chartData === 3 ? 'bar' : chartData === 4 ? 'bar' : 'line',
                backgroundColor: 'red',
                data: performanceData?.Data.map((t) => {
                  const entry = AnalyticsData?.find((e) => e.code === chartData);
                  if (entry && entry.value !== undefined) {
                    return t.Current[entry.value as keyof typeof t.Current];
                  } else {
                    return 0;
                  }
                }),
              },
              {
                type: chartData === 3 ? 'bar' : chartData === 4 ? 'bar' : 'line',
                label:
                  AnalyticsData.find((e) => e.code === chartData)?.name +
                  ' en ' +
                  filtersCTX.filters.performanceFilters.YearToCompare,
                data: performanceData.Data.map((t) => {
                  const entry = AnalyticsData?.find((e) => e.code === chartData);
                  if (entry && entry.value !== undefined) {
                    return t.Previous[entry.value as keyof typeof t.Previous];
                  } else {
                    return 0;
                  }
                }),
              },
            ],
            labels: performanceData.Data.map((t) => t.Label),
            code: chartData,
          },
          to: {
            datasets: [
              {
                label: AnalyticsData.find((e) => e.code === hoveredId)?.name ?? '',
                type: hoveredId === 3 ? 'bar' : hoveredId === 4 ? 'bar' : 'line',

                data: performanceData.Data.map((t) => {
                  const entry = AnalyticsData?.find((e) => e.code === hoveredId);
                  if (entry && entry.value !== undefined) {
                    return t.Current[entry.value as keyof typeof t.Current];
                  } else {
                    return 0;
                  }
                }),
              },
              {
                label:
                  AnalyticsData.find((e) => e.code === hoveredId)?.name +
                  ' en ' +
                  filtersCTX.filters.performanceFilters.YearToCompare,
                type: hoveredId === 3 ? 'bar' : hoveredId === 4 ? 'bar' : 'line',

                data: performanceData.Data.map((t) => {
                  const entry = AnalyticsData?.find((e) => e.code === hoveredId);
                  if (entry && entry.value !== undefined) {
                    return t.Previous[entry.value as keyof typeof t.Previous];
                  } else {
                    return 0;
                  }
                }),
              },
            ],
            labels: performanceData.Data.map((t) =>
              filtersCTX.filters.performanceFilters.GroupBy === 0 || filtersCTX.filters.performanceFilters.GroupBy === 3
                ? shortDateToLocaleStringWithoutYear(
                    moment(t.Label).toDate(),
                    userCTX.authenticationInfos.language ?? 'fr',
                  )
                : filtersCTX.filters.performanceFilters.GroupBy === 2
                  ? shortOnlyMonthDateToLocaleString(
                      moment(t.Label).toDate(),
                      userCTX.authenticationInfos.language ?? 'fr',
                    )
                  : t.Label,
            ),
            code: hoveredId,
          },
        });
      }
    }

    setHoveredId(null); // Réinitialise après un dépôt

    // You can set this data into state to re-render this component with the new chart
  };
  const handleDragOver = (e: any) => {
    e.preventDefault(); // Necessary to allow dropping
  };

  const handleDragEnter = (id: number) => {
    setHoveredId(id);
  };

  const backgroundColor = (context: any) => {
    const index = context.dataIndex;
    const label = context?.chart?.data?.labels?.[index];
    let color = 'darkorange'; // Couleur par défaut
    performanceData?.Events.forEach((event) => {
      event.Data.forEach((date) => {
        if (moment(label as string).isBetween(date.Dates.DateStart, date.Dates.DateEnd, undefined, '[]')) {
          color = date.Foreground;
        }
      });
    });
    return color;
  };
  const backgroundColor2 = (context: any) => {
    const index = context.dataIndex;
    const label = context?.chart?.data?.labels?.[index];
    let color = 'pink'; // Couleur par défaut
    performanceData?.Events.forEach((event) => {
      event.Data.forEach((date) => {
        if (moment(label as string).isBetween(date.Dates.DateStart, date.Dates.DateEnd, undefined, '[]')) {
          color = date.Foreground;
        }
      });
    });
    return color;
  };

  const foreGroundColor2 = (context: any) => {
    const index = context.dataIndex;
    const label = context?.chart?.data?.labels?.[index];
    let color = 'pink'; // Couleur par défaut
    performanceData?.Events.forEach((event) => {
      event.Data.forEach((date) => {
        if (moment(label as string).isBetween(date.Dates.DateStart, date.Dates.DateEnd, undefined, '[]')) {
          color = date.Background;
        }
      });
    });
    return color;
  };

  const foreGroundColor = (context: any) => {
    const index = context.dataIndex;
    const label = context?.chart?.data?.labels?.[index];
    let color = 'darkorange'; // Couleur par défaut
    performanceData?.Events.forEach((event) => {
      event.Data.forEach((date) => {
        if (moment(label as string).isBetween(date.Dates.DateStart, date.Dates.DateEnd, undefined, '[]')) {
          color = date.Background;
        }
      });
    });
    return color;
  };
  return (
    <section className="content">
      <div className="container-fluid" style={{ paddingTop: '20px' }}>
        <div className="row">
          <div className="col-lg-12 col-12">
            {(isMobile || isTablet || isConditionMet) && (
              <div
                className={`small-box`}
                style={{ width: isPortrait ? '100%' : '50%', padding: '10px', background: '#f8f9fa' }}
              >
                {' '}
                <div className="inner">
                  <PerformanceFilters></PerformanceFilters>
                </div>
              </div>
            )}
          </div>
        </div>

        {filtersCTX.filters.performanceFilters.hasBeenModified === false && (
          <div className="row">
            <div className="col-lg-12 col-12">
              <AnalytiqueProvider>
                <>
                  {performanceData && (
                    <div>
                      <div className="row">
                        {stackedComponent?.from && mergeOptions !== undefined && stackedComponent.to && (
                          <div className="col-lg-6 col-12">
                            <div
                              style={{
                                border: hoveredId === 0 ? '2px dashed #333' : '1px solid #e0e0e0',
                                background: hoveredId === 0 ? '#ccc' : 'inherit',
                              }}
                              className={`small-box bg-gradient-light  drop-zone`}
                            >
                              <Button
                                style={{
                                  position: 'absolute',
                                  right: 10,
                                  top: 10,
                                  backgroundColor: colors.colorErrorMain,
                                  borderColor: colors.colorErrorDark,

                                  height: '24px',
                                  width: '24px',
                                  fontSize: '16px',
                                }}
                                icon="fa-solid fa-xmark"
                                onClick={() => setStackedComponent(null)}
                              ></Button>
                              <LineChart
                                mergeOptions={mergeOptions2}
                                stackedComponent={stackedComponent}
                                data={{
                                  datasets: [
                                    ...stackedComponent.from.datasets.map((dataset, i) => ({
                                      ...dataset, // Copie toutes les propriétés du dataset original
                                      label: dataset.label, // Ajoute une année ou une autre distinction au label
                                      borderColor: i % 2 ? '#666' : 'darkorange',

                                      backgroundColor: i % 2 ? 'darkorange' : '#666', // Idem pour l'arrière-plan
                                      yAxisID: 'y',
                                      beginAtZero: true,
                                      min: 0,
                                      data: dataset.data,
                                      type: dataset.type as 'line',
                                    })),
                                    ...stackedComponent.to.datasets.map((dataset, i) => ({
                                      ...dataset, // Copie toutes les propriétés du dataset original
                                      label: dataset.label, // Ajoute une année ou une autre distinction au label
                                      borderColor: i % 2 ? '#ddd' : 'pink',
                                      backgroundColor: i % 2 ? 'pink' : '#ddd', // Idem pour l'arrière-plan
                                      yAxisID: 'y1',
                                      beginAtZero: true,
                                      min: 0,
                                      data: dataset.data,
                                      type: dataset.type as 'line',
                                    })),
                                  ],
                                  labels: performanceData.Data.map((t) =>
                                    filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                    filtersCTX.filters.performanceFilters.GroupBy === 3
                                      ? shortDateToLocaleStringWithoutYear(
                                          moment(t.Label).toDate(),
                                          userCTX.authenticationInfos.language ?? 'fr',
                                        )
                                      : filtersCTX.filters.performanceFilters.GroupBy === 2
                                        ? shortOnlyMonthDateToLocaleString(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : t.Label,
                                  ),
                                }}
                              ></LineChart>
                            </div>
                          </div>
                        )}

                        {performanceData.Data.length > 0 && (
                          <>
                            <div className="col-lg-3 col-12">
                              <div
                                id="box0"
                                style={{
                                  border: hoveredId === 0 ? '2px dashed #333' : '1px solid #e0e0e0',
                                  background: hoveredId === 0 ? '#ccc' : 'inherit',
                                  display: 'flex',
                                }}
                                className={`small-box bg-gradient-light  drop-zone`}
                                onDragOver={handleDragOver}
                                onDragEnter={() => handleDragEnter(0)}
                                onDrop={handleDrop}
                                onDragStart={(e) => handleDragStart(e, 0)} // chartData is your data for the chart
                              >
                                <i
                                  id="drag0"
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    left: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'grab',
                                  }}
                                  className="fa-solid fa-bars"
                                ></i>
                                <i
                                  id="eye0"
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    right: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'pointer',
                                  }}
                                  className="fa-solid fa-eye"
                                  onClick={() => openModale(0)}
                                ></i>
                                <div style={{ position: 'absolute', right: '15px', top: 'calc(50% - 20px)' }}>
                                  <ExportExcel
                                    filename="OccupancyRate"
                                    datestart={moment(filtersCTX.filters.performanceFilters.DateStart).format(
                                      'YYYY-MM-DD',
                                    )}
                                    dateend={moment(filtersCTX.filters.performanceFilters.DateEnd).format('YYYY-MM-DD')}
                                    data={performanceData.Data.map((t) => {
                                      return {
                                        // Clé pour la date
                                        Date:
                                          filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                          filtersCTX.filters.performanceFilters.GroupBy === 3
                                            ? shortDateToLocaleStringWithoutYear(
                                                moment(t.Label).toDate(),
                                                userCTX.authenticationInfos.language ?? 'fr',
                                              )
                                            : filtersCTX.filters.performanceFilters.GroupBy === 2
                                              ? shortOnlyMonthDateToLocaleString(
                                                  moment(t.Label).toDate(),
                                                  userCTX.authenticationInfos.language ?? 'fr',
                                                )
                                              : t.Label,
                                        // Clé pour le taux d'occupation actuel
                                        [AnalyticsData.find((e) => e.code === 0)?.name ||
                                        'Current Year Occupancy Rate']: t.Current.OccupancyRate,
                                        // Clé pour le taux d'occupation de l'année précédente
                                        [`${AnalyticsData.find((e) => e.code === 0)?.name || 'Previous Year Occupancy Rate'} (${filtersCTX.filters.performanceFilters.YearToCompare})`]:
                                          t.Previous.OccupancyRate,
                                      };
                                    })}
                                    exportedColumns={[
                                      // Colonne pour la date
                                      { field: 'Date', header: 'Date' },
                                      // Colonne pour le taux d'occupation actuel
                                      {
                                        field:
                                          AnalyticsData.find((e) => e.code === 0)?.name ||
                                          'Current Year Occupancy Rate',
                                        header:
                                          AnalyticsData.find((e) => e.code === 0)?.name ||
                                          'Current Year Occupancy Rate',
                                      },
                                      // Colonne pour le taux d'occupation de l'année précédente
                                      {
                                        field: `${AnalyticsData.find((e) => e.code === 0)?.name || 'Previous Year Occupancy Rate'} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                        header: `${AnalyticsData.find((e) => e.code === 0)?.name || 'Previous Year Occupancy Rate'} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                      },
                                    ]}
                                  />
                                </div>
                                <LineChart
                                  code={0}
                                  mergeOptions={mergeOptions}
                                  stackedComponent={stackedComponent}
                                  data={{
                                    datasets: [
                                      {
                                        label: AnalyticsData.find((e) => e.code === 0)?.name,
                                        data: performanceData.Data.map((t) => t.Current.OccupancyRate),
                                        backgroundColor: backgroundColor,
                                        borderColor: foreGroundColor,
                                      },
                                      {
                                        label:
                                          AnalyticsData.find((e) => e.code === 0)?.name +
                                          ' en ' +
                                          filtersCTX.filters.performanceFilters.YearToCompare,
                                        data: performanceData.Data.map((t) => t.Previous.OccupancyRate),
                                        borderColor: foreGroundColor2,
                                        backgroundColor: backgroundColor2,
                                      },
                                    ],
                                    labels: performanceData.Data.map((t) =>
                                      filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                      filtersCTX.filters.performanceFilters.GroupBy === 3
                                        ? shortDateToLocaleStringWithoutYear(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : filtersCTX.filters.performanceFilters.GroupBy === 2
                                          ? shortOnlyMonthDateToLocaleString(
                                              moment(t.Label).toDate(),
                                              userCTX.authenticationInfos.language ?? 'fr',
                                            )
                                          : t.Label,
                                    ),
                                  }}
                                ></LineChart>
                              </div>
                            </div>
                            <div className="col-lg-3 col-12">
                              <div
                                id="box1"
                                draggable
                                style={{
                                  border: hoveredId === 1 ? '2px dashed #333' : '1px solid #e0e0e0',
                                  backgroundColor: hoveredId === 1 ? '#f0f0f0' : 'transparent',
                                }}
                                className={`small-box bg-gradient-light  drop-zone`}
                                onDragOver={handleDragOver}
                                onDragEnter={() => handleDragEnter(1)}
                                onDrop={handleDrop}
                                onDragStart={(e) => handleDragStart(e, 1)} // chartData is your data for the chart
                              >
                                <i
                                  id="drag1"
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    left: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'grab',
                                  }}
                                  className="fa-solid fa-bars"
                                ></i>
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    right: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'pointer',
                                  }}
                                  className="fa-solid fa-eye"
                                  onClick={() => openModale(1)}
                                ></i>
                                <div style={{ position: 'absolute', right: '15px', top: 'calc(50% - 20px)' }}>
                                  <ExportExcel
                                    filename="Adr"
                                    datestart={moment(filtersCTX.filters.performanceFilters.DateStart).format(
                                      'YYYY-MM-DD',
                                    )}
                                    dateend={moment(filtersCTX.filters.performanceFilters.DateEnd).format('YYYY-MM-DD')}
                                    data={performanceData.Data.map((t) => {
                                      return {
                                        // Clé pour la date
                                        Date:
                                          filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                          filtersCTX.filters.performanceFilters.GroupBy === 3
                                            ? shortDateToLocaleStringWithoutYear(
                                                moment(t.Label).toDate(),
                                                userCTX.authenticationInfos.language ?? 'fr',
                                              )
                                            : filtersCTX.filters.performanceFilters.GroupBy === 2
                                              ? shortOnlyMonthDateToLocaleString(
                                                  moment(t.Label).toDate(),
                                                  userCTX.authenticationInfos.language ?? 'fr',
                                                )
                                              : t.Label,
                                        // Clé pour le taux d'occupation actuel
                                        [AnalyticsData.find((e) => e.code === 1)?.name || '']: t.Current.Adr,
                                        // Clé pour le taux d'occupation de l'année précédente
                                        [`${AnalyticsData.find((e) => e.code === 1)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`]:
                                          t.Previous.Adr,
                                      };
                                    })}
                                    exportedColumns={[
                                      // Colonne pour la date
                                      { field: 'Date', header: 'Date' },
                                      // Colonne pour le taux d'occupation actuel
                                      {
                                        field: AnalyticsData.find((e) => e.code === 1)?.name || '',
                                        header: AnalyticsData.find((e) => e.code === 1)?.name || '',
                                      },
                                      // Colonne pour le taux d'occupation de l'année précédente
                                      {
                                        field: `${AnalyticsData.find((e) => e.code === 1)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                        header: `${AnalyticsData.find((e) => e.code === 1)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                      },
                                    ]}
                                  />
                                </div>
                                <LineChart
                                  code={1}
                                  mergeOptions={mergeOptions}
                                  stackedComponent={stackedComponent}
                                  data={{
                                    datasets: [
                                      {
                                        label: AnalyticsData.find((e) => e.code === 1)?.name,
                                        data: performanceData.Data.map((t) => t.Current.Adr),
                                        backgroundColor: backgroundColor,
                                        borderColor: foreGroundColor,
                                      },
                                      {
                                        label:
                                          AnalyticsData.find((e) => e.code === 1)?.name +
                                          ' en ' +
                                          filtersCTX.filters.performanceFilters.YearToCompare,
                                        data: performanceData.Data.map((t) => t.Previous.Adr),
                                        borderColor: foreGroundColor2,
                                        backgroundColor: backgroundColor2,
                                      },
                                    ],
                                    labels: performanceData.Data.map((t) =>
                                      filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                      filtersCTX.filters.performanceFilters.GroupBy === 3
                                        ? shortDateToLocaleStringWithoutYear(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : filtersCTX.filters.performanceFilters.GroupBy === 2
                                          ? shortOnlyMonthDateToLocaleString(
                                              moment(t.Label).toDate(),
                                              userCTX.authenticationInfos.language ?? 'fr',
                                            )
                                          : t.Label,
                                    ),
                                  }}
                                ></LineChart>
                              </div>
                            </div>
                            <div className="col-lg-3 col-12">
                              <div
                                style={{
                                  border: hoveredId === 2 ? '2px dashed #333' : '1px solid #e0e0e0',
                                  background: hoveredId === 2 ? '#ccc' : 'inherit',
                                }}
                                className={`small-box bg-gradient-light  drop-zone`}
                                onDragOver={handleDragOver}
                                onDragEnter={() => handleDragEnter(2)}
                                onDrop={handleDrop}
                                onDragStart={(e) => handleDragStart(e, 2)} // chartData is your data for the chart
                              >
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    left: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'grab',
                                  }}
                                  className="fa-solid fa-bars"
                                ></i>{' '}
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    right: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'pointer',
                                  }}
                                  className="fa-solid fa-eye"
                                  onClick={() => openModale(2)}
                                ></i>
                                <div style={{ position: 'absolute', right: '15px', top: 'calc(50% - 20px)' }}>
                                  <ExportExcel
                                    filename="RevPar"
                                    datestart={moment(filtersCTX.filters.performanceFilters.DateStart).format(
                                      'YYYY-MM-DD',
                                    )}
                                    dateend={moment(filtersCTX.filters.performanceFilters.DateEnd).format('YYYY-MM-DD')}
                                    data={performanceData.Data.map((t) => {
                                      return {
                                        // Clé pour la date
                                        Date:
                                          filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                          filtersCTX.filters.performanceFilters.GroupBy === 3
                                            ? shortDateToLocaleStringWithoutYear(
                                                moment(t.Label).toDate(),
                                                userCTX.authenticationInfos.language ?? 'fr',
                                              )
                                            : filtersCTX.filters.performanceFilters.GroupBy === 2
                                              ? shortOnlyMonthDateToLocaleString(
                                                  moment(t.Label).toDate(),
                                                  userCTX.authenticationInfos.language ?? 'fr',
                                                )
                                              : t.Label,
                                        // Clé pour le taux d'occupation actuel
                                        [AnalyticsData.find((e) => e.code === 2)?.name || '']: t.Current.RevPar,
                                        // Clé pour le taux d'occupation de l'année précédente
                                        [`${AnalyticsData.find((e) => e.code === 2)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`]:
                                          t.Previous.RevPar,
                                      };
                                    })}
                                    exportedColumns={[
                                      // Colonne pour la date
                                      { field: 'Date', header: 'Date' },
                                      // Colonne pour le taux d'occupation actuel
                                      {
                                        field: AnalyticsData.find((e) => e.code === 2)?.name || '',
                                        header: AnalyticsData.find((e) => e.code === 2)?.name || '',
                                      },
                                      // Colonne pour le taux d'occupation de l'année précédente
                                      {
                                        field: `${AnalyticsData.find((e) => e.code === 2)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                        header: `${AnalyticsData.find((e) => e.code === 2)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                      },
                                    ]}
                                  />
                                </div>
                                <LineChart
                                  data={{
                                    datasets: [
                                      {
                                        label: AnalyticsData.find((e) => e.code === 2)?.name,
                                        data: performanceData.Data.map((t) => t.Current.RevPar),
                                        backgroundColor: backgroundColor,
                                        borderColor: foreGroundColor,
                                      },
                                      {
                                        label:
                                          AnalyticsData.find((e) => e.code === 2)?.name +
                                          ' en ' +
                                          filtersCTX.filters.performanceFilters.YearToCompare,
                                        data: performanceData.Data.map((t) => t.Previous.RevPar),
                                        backgroundColor: backgroundColor2,
                                        borderColor: foreGroundColor2,
                                      },
                                    ],
                                    labels: performanceData.Data.map((t) =>
                                      filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                      filtersCTX.filters.performanceFilters.GroupBy === 3
                                        ? shortDateToLocaleStringWithoutYear(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : filtersCTX.filters.performanceFilters.GroupBy === 2
                                          ? shortOnlyMonthDateToLocaleString(
                                              moment(t.Label).toDate(),
                                              userCTX.authenticationInfos.language ?? 'fr',
                                            )
                                          : t.Label,
                                    ),
                                  }}
                                  mergeOptions={mergeOptions}
                                ></LineChart>
                              </div>
                            </div>
                            <div className="col-lg-3 col-12">
                              <div
                                style={{
                                  border: hoveredId === 3 ? '2px dashed #333' : '1px solid #e0e0e0',
                                  background: hoveredId === 3 ? '#ccc' : 'inherit',
                                }}
                                className={`small-box bg-gradient-light  drop-zone`}
                                onDragOver={handleDragOver}
                                onDragEnter={() => handleDragEnter(3)}
                                onDrop={handleDrop}
                                onDragStart={(e) => handleDragStart(e, 3)} // chartData is your data for the chart
                              >
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    left: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'grab',
                                  }}
                                  className="fa-solid fa-bars"
                                ></i>{' '}
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    right: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'pointer',
                                  }}
                                  className="fa-solid fa-eye"
                                  onClick={() => openModale(3)}
                                ></i>
                                <div style={{ position: 'absolute', right: '15px', top: 'calc(50% - 20px)' }}>
                                  <ExportExcel
                                    data={performanceData.Data.map((t) => {
                                      return {
                                        Date:
                                          filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                          filtersCTX.filters.performanceFilters.GroupBy === 3
                                            ? shortDateToLocaleStringWithoutYear(
                                                moment(t.Label).toDate(),
                                                userCTX.authenticationInfos.language ?? 'fr',
                                              )
                                            : filtersCTX.filters.performanceFilters.GroupBy === 2
                                              ? shortOnlyMonthDateToLocaleString(
                                                  moment(t.Label).toDate(),
                                                  userCTX.authenticationInfos.language ?? 'fr',
                                                )
                                              : t.Label,
                                        [AnalyticsData.find((e) => e.code === 4)?.name || '']:
                                          t.Current.AccomodationTurnOver,
                                        [`${AnalyticsData.find((e) => e.code === 4)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`]:
                                          t.Previous.AccomodationTurnOver,
                                      };
                                    })}
                                    filename="AccomodationTurnOver"
                                    datestart={moment(filtersCTX.filters.performanceFilters.DateStart).format(
                                      'YYYY-MM-DD',
                                    )}
                                    dateend={moment(filtersCTX.filters.performanceFilters.DateEnd).format('YYYY-MM-DD')}
                                    exportedColumns={[
                                      { field: 'Date', header: 'Date' },

                                      {
                                        field: AnalyticsData.find((e) => e.code === 4)?.name || '',
                                        header: AnalyticsData.find((e) => e.code === 4)?.name || '',
                                      },
                                      {
                                        field: `${AnalyticsData.find((e) => e.code === 4)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                        header: `${AnalyticsData.find((e) => e.code === 4)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                      },
                                    ]}
                                  />
                                </div>
                                <BarChart
                                  blueColor={false}
                                  options={{
                                    ...mergeOptions,
                                    bar: { datasets: { barPercentage: 1, categoryPercentage: 1 } },

                                    layout: { autoPadding: false },
                                    datasets: { bar: { grouped: true, barPercentage: 1, categoryPercentage: 1 } },
                                  }}
                                  data={{
                                    datasets: [
                                      {
                                        label: AnalyticsData.find((e) => e.code === 3)?.name,
                                        data: performanceData.Data.map((t) => t.Current.AccomodationTurnOver),
                                        backgroundColor: colors.colorPrimaryMain,
                                        borderColor: '#333',
                                        borderWidth: 1,
                                      },
                                      {
                                        label:
                                          AnalyticsData.find((e) => e.code === 3)?.name +
                                          ' en ' +
                                          filtersCTX.filters.performanceFilters.YearToCompare,
                                        data: performanceData.Data.map((t) => t.Previous.AccomodationTurnOver),
                                        backgroundColor: colors.colorSecondaryMain,
                                        borderColor: '#333',
                                        borderWidth: 1,
                                      },
                                    ],
                                    labels: performanceData.Data.map((t) =>
                                      filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                      filtersCTX.filters.performanceFilters.GroupBy === 3
                                        ? shortDateToLocaleStringWithoutYear(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : filtersCTX.filters.performanceFilters.GroupBy === 2
                                          ? shortOnlyMonthDateToLocaleString(
                                              moment(t.Label).toDate(),
                                              userCTX.authenticationInfos.language ?? 'fr',
                                            )
                                          : t.Label,
                                    ),
                                  }}
                                ></BarChart>
                              </div>
                            </div>
                            <div className="col-lg-3 col-12">
                              <div
                                style={{
                                  border: hoveredId === 4 ? '2px dashed #333' : '1px solid #e0e0e0',
                                  background: hoveredId === 4 ? '#ccc' : 'inherit',
                                }}
                                className={`small-box bg-gradient-light  drop-zone`}
                                onDragOver={handleDragOver}
                                onDragEnter={() => handleDragEnter(4)}
                                onDrop={handleDrop}
                                onDragStart={(e) => handleDragStart(e, 4)} // chartData is your data for the chart
                              >
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    left: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'grab',
                                  }}
                                  className="fa-solid fa-bars"
                                ></i>
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    right: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'pointer',
                                  }}
                                  className="fa-solid fa-eye"
                                  onClick={() => openModale(4)}
                                ></i>
                                <div style={{ position: 'absolute', right: '15px', top: 'calc(50% - 20px)' }}>
                                  <ExportExcel
                                    data={performanceData.Data.map((t) => {
                                      return {
                                        Date:
                                          filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                          filtersCTX.filters.performanceFilters.GroupBy === 3
                                            ? shortDateToLocaleStringWithoutYear(
                                                moment(t.Label).toDate(),
                                                userCTX.authenticationInfos.language ?? 'fr',
                                              )
                                            : filtersCTX.filters.performanceFilters.GroupBy === 2
                                              ? shortOnlyMonthDateToLocaleString(
                                                  moment(t.Label).toDate(),
                                                  userCTX.authenticationInfos.language ?? 'fr',
                                                )
                                              : t.Label,
                                        [AnalyticsData.find((e) => e.code === 4)?.name || '']: t.Current.TurnOver,
                                        [`${AnalyticsData.find((e) => e.code === 4)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`]:
                                          t.Previous.TurnOver,
                                      };
                                    })}
                                    filename="TurnOver"
                                    datestart={moment(filtersCTX.filters.performanceFilters.DateStart).format(
                                      'YYYY-MM-DD',
                                    )}
                                    dateend={moment(filtersCTX.filters.performanceFilters.DateEnd).format('YYYY-MM-DD')}
                                    exportedColumns={[
                                      { field: 'Date', header: 'Date' },

                                      {
                                        field: AnalyticsData.find((e) => e.code === 5)?.name || '',
                                        header: AnalyticsData.find((e) => e.code === 5)?.name || '',
                                      },
                                      {
                                        field: `${AnalyticsData.find((e) => e.code === 5)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                        header: `${AnalyticsData.find((e) => e.code === 5)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                      },
                                    ]}
                                  />
                                </div>
                                <BarChart
                                  options={{
                                    bar: { datasets: { barPercentage: 1, categoryPercentage: 1 } },

                                    layout: { autoPadding: false },
                                    datasets: { bar: { grouped: true, barPercentage: 1, categoryPercentage: 1 } },
                                  }}
                                  minHeight="250px"
                                  blueColor={false}
                                  data={{
                                    datasets: [
                                      {
                                        label: AnalyticsData.find((e) => e.code === 4)?.name,
                                        data: performanceData.Data.map((t) => t.Current.TurnOver),
                                        backgroundColor: colors.colorPrimaryMain,
                                        borderColor: '#333',
                                        borderWidth: 1,
                                      },
                                      {
                                        label:
                                          AnalyticsData.find((e) => e.code === 4)?.name +
                                          ' en ' +
                                          filtersCTX.filters.performanceFilters.YearToCompare,
                                        data: performanceData.Data.map((t) => t.Previous.TurnOver),
                                        backgroundColor: colors.colorSecondaryMain,
                                        borderColor: '#333',
                                        borderWidth: 1,
                                      },
                                    ],
                                    labels: performanceData.Data.map((t) =>
                                      filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                      filtersCTX.filters.performanceFilters.GroupBy === 3
                                        ? shortDateToLocaleStringWithoutYear(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : filtersCTX.filters.performanceFilters.GroupBy === 2
                                          ? shortOnlyMonthDateToLocaleString(
                                              moment(t.Label).toDate(),
                                              userCTX.authenticationInfos.language ?? 'fr',
                                            )
                                          : t.Label,
                                    ),
                                  }}
                                ></BarChart>
                              </div>
                            </div>
                            <div className="col-lg-3 col-12">
                              <div
                                style={{
                                  border: hoveredId === 5 ? '2px dashed #333' : '1px solid #e0e0e0',
                                  background: hoveredId === 5 ? '#ccc' : 'inherit',
                                }}
                                className={`small-box bg-gradient-light  drop-zone`}
                                onDragOver={handleDragOver}
                                onDragEnter={() => handleDragEnter(5)}
                                onDrop={handleDrop}
                                onDragStart={(e) => handleDragStart(e, 5)} // chartData is your data for the chart
                              >
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    left: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'grab',
                                  }}
                                  className="fa-solid fa-bars"
                                ></i>
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    right: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'pointer',
                                  }}
                                  className="fa-solid fa-eye"
                                  onClick={() => openModale(5)}
                                ></i>
                                <div style={{ position: 'absolute', right: '15px', top: 'calc(50% - 20px)' }}>
                                  <ExportExcel
                                    filename="Alos"
                                    datestart={moment(filtersCTX.filters.performanceFilters.DateStart).format(
                                      'YYYY-MM-DD',
                                    )}
                                    dateend={moment(filtersCTX.filters.performanceFilters.DateEnd).format('YYYY-MM-DD')}
                                    data={performanceData.Data.map((t) => {
                                      return {
                                        Date:
                                          filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                          filtersCTX.filters.performanceFilters.GroupBy === 3
                                            ? shortDateToLocaleStringWithoutYear(
                                                moment(t.Label).toDate(),
                                                userCTX.authenticationInfos.language ?? 'fr',
                                              )
                                            : filtersCTX.filters.performanceFilters.GroupBy === 2
                                              ? shortOnlyMonthDateToLocaleString(
                                                  moment(t.Label).toDate(),
                                                  userCTX.authenticationInfos.language ?? 'fr',
                                                )
                                              : t.Label,
                                        [AnalyticsData.find((e) => e.code === 5)?.name || '']: t.Current.Alos,
                                        [`${AnalyticsData.find((e) => e.code === 5)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`]:
                                          t.Previous.Alos,
                                      };
                                    })}
                                    exportedColumns={[
                                      { field: 'Date', header: 'Date' },

                                      {
                                        field: AnalyticsData.find((e) => e.code === 5)?.name || '',
                                        header: AnalyticsData.find((e) => e.code === 5)?.name || '',
                                      },
                                      {
                                        field: `${AnalyticsData.find((e) => e.code === 5)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                        header: `${AnalyticsData.find((e) => e.code === 5)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                      },
                                    ]}
                                  />
                                </div>
                                <LineChart
                                  mergeOptions={mergeOptions}
                                  data={{
                                    datasets: [
                                      {
                                        label: AnalyticsData.find((e) => e.code === 5)?.name,
                                        data: performanceData.Data.map((t) => t.Current.Alos),
                                        backgroundColor: backgroundColor,
                                        borderColor: foreGroundColor,
                                      },
                                      {
                                        label:
                                          AnalyticsData.find((e) => e.code === 5)?.name +
                                          ' en ' +
                                          filtersCTX.filters.performanceFilters.YearToCompare,
                                        data: performanceData.Data.map((t) => t.Previous.Alos),
                                        backgroundColor: backgroundColor2,
                                        borderColor: foreGroundColor2,
                                      },
                                    ],
                                    labels: performanceData.Data.map((t) =>
                                      filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                      filtersCTX.filters.performanceFilters.GroupBy === 3
                                        ? shortDateToLocaleStringWithoutYear(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : filtersCTX.filters.performanceFilters.GroupBy === 2
                                          ? shortOnlyMonthDateToLocaleString(
                                              moment(t.Label).toDate(),
                                              userCTX.authenticationInfos.language ?? 'fr',
                                            )
                                          : t.Label,
                                    ),
                                  }}
                                ></LineChart>
                              </div>
                            </div>
                            <div className="col-lg-3 col-12">
                              <div
                                style={{
                                  border: hoveredId === 6 ? '2px dashed #333' : '1px solid #e0e0e0',
                                  background: hoveredId === 6 ? '#ccc' : 'inherit',
                                }}
                                className={`small-box bg-gradient-light  drop-zone`}
                                onDragOver={handleDragOver}
                                onDragEnter={() => handleDragEnter(6)}
                                onDrop={handleDrop}
                                onDragStart={(e) => handleDragStart(e, 6)} // chartData is your data for the chart
                              >
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    left: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'grab',
                                  }}
                                  className="fa-solid fa-bars"
                                ></i>
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    right: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'pointer',
                                  }}
                                  className="fa-solid fa-eye"
                                  onClick={() => openModale(6)}
                                ></i>
                                <div style={{ position: 'absolute', right: '15px', top: 'calc(50% - 20px)' }}>
                                  <ExportExcel
                                    filename="RevPor"
                                    datestart={moment(filtersCTX.filters.performanceFilters.DateStart).format(
                                      'YYYY-MM-DD',
                                    )}
                                    dateend={moment(filtersCTX.filters.performanceFilters.DateEnd).format('YYYY-MM-DD')}
                                    data={performanceData.Data.map((t) => {
                                      return {
                                        Date:
                                          filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                          filtersCTX.filters.performanceFilters.GroupBy === 3
                                            ? shortDateToLocaleStringWithoutYear(
                                                moment(t.Label).toDate(),
                                                userCTX.authenticationInfos.language ?? 'fr',
                                              )
                                            : filtersCTX.filters.performanceFilters.GroupBy === 2
                                              ? shortOnlyMonthDateToLocaleString(
                                                  moment(t.Label).toDate(),
                                                  userCTX.authenticationInfos.language ?? 'fr',
                                                )
                                              : t.Label,
                                        [AnalyticsData.find((e) => e.code === 6)?.name || '']: t.Current.RevPor,
                                        [`${AnalyticsData.find((e) => e.code === 6)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`]:
                                          t.Previous.RevPor,
                                      };
                                    })}
                                    exportedColumns={[
                                      { field: 'Date', header: 'Date' },

                                      {
                                        field: AnalyticsData.find((e) => e.code === 6)?.name || '',
                                        header: AnalyticsData.find((e) => e.code === 6)?.name || '',
                                      },
                                      {
                                        field: `${AnalyticsData.find((e) => e.code === 6)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                        header: `${AnalyticsData.find((e) => e.code === 6)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                      },
                                    ]}
                                  />
                                </div>
                                <LineChart
                                  mergeOptions={mergeOptions}
                                  data={{
                                    datasets: [
                                      {
                                        label: AnalyticsData.find((e) => e.code === 6)?.name,
                                        data: performanceData.Data.map((t) => t.Current.RevPor),
                                        backgroundColor: backgroundColor,
                                        borderColor: foreGroundColor,
                                      },
                                      {
                                        label:
                                          AnalyticsData.find((e) => e.code === 6)?.name +
                                          ' en ' +
                                          filtersCTX.filters.performanceFilters.YearToCompare,
                                        data: performanceData.Data.map((t) => t.Previous.RevPor),
                                        backgroundColor: backgroundColor2,
                                        borderColor: foreGroundColor2,
                                      },
                                    ],
                                    labels: performanceData.Data.map((t) =>
                                      filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                      filtersCTX.filters.performanceFilters.GroupBy === 3
                                        ? shortDateToLocaleStringWithoutYear(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : filtersCTX.filters.performanceFilters.GroupBy === 2
                                          ? shortOnlyMonthDateToLocaleString(
                                              moment(t.Label).toDate(),
                                              userCTX.authenticationInfos.language ?? 'fr',
                                            )
                                          : t.Label,
                                    ),
                                  }}
                                ></LineChart>
                              </div>
                            </div>
                            <div className="col-lg-3 col-12">
                              <div
                                style={{
                                  border: hoveredId === 7 ? '2px dashed #333' : '1px solid #e0e0e0',
                                  background: hoveredId === 7 ? '#ccc' : 'inherit',
                                }}
                                className={`small-box bg-gradient-light  drop-zone`}
                                onDragOver={handleDragOver}
                                onDragEnter={() => handleDragEnter(7)}
                                onDrop={handleDrop}
                                onDragStart={(e) => handleDragStart(e, 7)} // chartData is your data for the chart
                              >
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    left: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'grab',
                                  }}
                                  className="fa-solid fa-bars"
                                ></i>
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    right: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'pointer',
                                  }}
                                  className="fa-solid fa-eye"
                                  onClick={() => openModale(7)}
                                ></i>
                                <div style={{ position: 'absolute', right: '15px', top: 'calc(50% - 20px)' }}>
                                  <ExportExcel
                                    filename="RoomsSoldQty"
                                    datestart={moment(filtersCTX.filters.performanceFilters.DateStart).format(
                                      'YYYY-MM-DD',
                                    )}
                                    dateend={moment(filtersCTX.filters.performanceFilters.DateEnd).format('YYYY-MM-DD')}
                                    data={performanceData.Data.map((t) => {
                                      return {
                                        Date:
                                          filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                          filtersCTX.filters.performanceFilters.GroupBy === 3
                                            ? shortDateToLocaleStringWithoutYear(
                                                moment(t.Label).toDate(),
                                                userCTX.authenticationInfos.language ?? 'fr',
                                              )
                                            : filtersCTX.filters.performanceFilters.GroupBy === 2
                                              ? shortOnlyMonthDateToLocaleString(
                                                  moment(t.Label).toDate(),
                                                  userCTX.authenticationInfos.language ?? 'fr',
                                                )
                                              : t.Label,
                                        [AnalyticsData.find((e) => e.code === 7)?.name || '']: t.Current.RoomsSoldQty,
                                        [`${AnalyticsData.find((e) => e.code === 7)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`]:
                                          t.Previous.RoomsSoldQty,
                                      };
                                    })}
                                    exportedColumns={[
                                      { field: 'Date', header: 'Date' },

                                      {
                                        field: AnalyticsData.find((e) => e.code === 7)?.name || '',
                                        header: AnalyticsData.find((e) => e.code === 7)?.name || '',
                                      },
                                      {
                                        field: `${AnalyticsData.find((e) => e.code === 7)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                        header: `${AnalyticsData.find((e) => e.code === 7)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                      },
                                    ]}
                                  />
                                </div>
                                <LineChart
                                  mergeOptions={mergeOptions}
                                  data={{
                                    datasets: [
                                      {
                                        label: AnalyticsData.find((e) => e.code === 7)?.name,
                                        data: performanceData.Data.map((t) => t.Current.RoomsSoldQty),
                                        backgroundColor: backgroundColor,
                                        borderColor: foreGroundColor,
                                      },
                                      {
                                        label:
                                          AnalyticsData.find((e) => e.code === 7)?.name +
                                          ' en ' +
                                          filtersCTX.filters.performanceFilters.YearToCompare,
                                        data: performanceData.Data.map((t) => t.Previous.RoomsSoldQty),
                                        backgroundColor: backgroundColor2,
                                        borderColor: foreGroundColor2,
                                      },
                                    ],
                                    labels: performanceData.Data.map((t) =>
                                      filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                      filtersCTX.filters.performanceFilters.GroupBy === 3
                                        ? shortDateToLocaleStringWithoutYear(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : filtersCTX.filters.performanceFilters.GroupBy === 2
                                          ? shortOnlyMonthDateToLocaleString(
                                              moment(t.Label).toDate(),
                                              userCTX.authenticationInfos.language ?? 'fr',
                                            )
                                          : t.Label,
                                    ),
                                  }}
                                ></LineChart>
                              </div>
                            </div>
                            <div className="col-lg-3 col-12">
                              <div
                                style={{
                                  border: hoveredId === 8 ? '2px dashed #333' : '1px solid #e0e0e0',
                                  background: hoveredId === 8 ? '#ccc' : 'inherit',
                                }}
                                className={`small-box bg-gradient-light  drop-zone`}
                                onDragOver={handleDragOver}
                                onDragEnter={() => handleDragEnter(8)}
                                onDrop={handleDrop}
                                onDragStart={(e) => handleDragStart(e, 8)} // chartData is your data for the chart
                              >
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    left: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'grab',
                                  }}
                                  className="fa-solid fa-bars"
                                ></i>
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    right: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'pointer',
                                  }}
                                  className="fa-solid fa-eye"
                                  onClick={() => openModale(8)}
                                ></i>
                                <div style={{ position: 'absolute', right: '15px', top: 'calc(50% - 20px)' }}>
                                  <ExportExcel
                                    filename="ArrivalsQty"
                                    datestart={moment(filtersCTX.filters.performanceFilters.DateStart).format(
                                      'YYYY-MM-DD',
                                    )}
                                    dateend={moment(filtersCTX.filters.performanceFilters.DateEnd).format('YYYY-MM-DD')}
                                    data={performanceData.Data.map((t) => {
                                      return {
                                        Date:
                                          filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                          filtersCTX.filters.performanceFilters.GroupBy === 3
                                            ? shortDateToLocaleStringWithoutYear(
                                                moment(t.Label).toDate(),
                                                userCTX.authenticationInfos.language ?? 'fr',
                                              )
                                            : filtersCTX.filters.performanceFilters.GroupBy === 2
                                              ? shortOnlyMonthDateToLocaleString(
                                                  moment(t.Label).toDate(),
                                                  userCTX.authenticationInfos.language ?? 'fr',
                                                )
                                              : t.Label,
                                        [AnalyticsData.find((e) => e.code === 8)?.name || '']: t.Current.ArrivalsQty,
                                        [`${AnalyticsData.find((e) => e.code === 8)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`]:
                                          t.Previous.ArrivalsQty,
                                      };
                                    })}
                                    exportedColumns={[
                                      { field: 'Date', header: 'Date' },

                                      {
                                        field: AnalyticsData.find((e) => e.code === 8)?.name || '',
                                        header: AnalyticsData.find((e) => e.code === 8)?.name || '',
                                      },
                                      {
                                        field: `${AnalyticsData.find((e) => e.code === 8)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                        header: `${AnalyticsData.find((e) => e.code === 8)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                      },
                                    ]}
                                  />
                                </div>
                                <LineChart
                                  mergeOptions={mergeOptions}
                                  data={{
                                    datasets: [
                                      {
                                        label: AnalyticsData.find((e) => e.code === 8)?.name,
                                        data: performanceData.Data.map((t) => t.Current.ArrivalsQty),
                                        backgroundColor: backgroundColor,
                                        borderColor: foreGroundColor,
                                      },
                                      {
                                        label:
                                          AnalyticsData.find((e) => e.code === 8)?.name +
                                          ' en ' +
                                          filtersCTX.filters.performanceFilters.YearToCompare,
                                        data: performanceData.Data.map((t) => t.Previous.ArrivalsQty),
                                        backgroundColor: backgroundColor2,
                                        borderColor: foreGroundColor2,
                                      },
                                    ],
                                    labels: performanceData.Data.map((t) =>
                                      filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                      filtersCTX.filters.performanceFilters.GroupBy === 3
                                        ? shortDateToLocaleStringWithoutYear(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : filtersCTX.filters.performanceFilters.GroupBy === 2
                                          ? shortOnlyMonthDateToLocaleString(
                                              moment(t.Label).toDate(),
                                              userCTX.authenticationInfos.language ?? 'fr',
                                            )
                                          : t.Label,
                                    ),
                                  }}
                                ></LineChart>
                              </div>
                            </div>
                            <div className="col-lg-3 col-12">
                              <div
                                style={{
                                  border: hoveredId === 9 ? '2px dashed #333' : '1px solid #e0e0e0',
                                  background: hoveredId === 9 ? '#ccc' : 'inherit',
                                }}
                                className={`small-box bg-gradient-light  drop-zone`}
                                onDragOver={handleDragOver}
                                onDragEnter={() => handleDragEnter(9)}
                                onDrop={handleDrop}
                                onDragStart={(e) => handleDragStart(e, 9)} // chartData is your data for the chart
                              >
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    left: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'grab',
                                  }}
                                  className="fa-solid fa-bars"
                                ></i>
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    right: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'pointer',
                                  }}
                                  className="fa-solid fa-eye"
                                  onClick={() => openModale(9)}
                                ></i>
                                <div style={{ position: 'absolute', right: '15px', top: 'calc(50% - 20px)' }}>
                                  <ExportExcel
                                    filename="DeparturesQty"
                                    datestart={moment(filtersCTX.filters.performanceFilters.DateStart).format(
                                      'YYYY-MM-DD',
                                    )}
                                    dateend={moment(filtersCTX.filters.performanceFilters.DateEnd).format('YYYY-MM-DD')}
                                    data={performanceData.Data.map((t) => {
                                      return {
                                        Date:
                                          filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                          filtersCTX.filters.performanceFilters.GroupBy === 3
                                            ? shortDateToLocaleStringWithoutYear(
                                                moment(t.Label).toDate(),
                                                userCTX.authenticationInfos.language ?? 'fr',
                                              )
                                            : filtersCTX.filters.performanceFilters.GroupBy === 2
                                              ? shortOnlyMonthDateToLocaleString(
                                                  moment(t.Label).toDate(),
                                                  userCTX.authenticationInfos.language ?? 'fr',
                                                )
                                              : t.Label,
                                        [AnalyticsData.find((e) => e.code === 9)?.name || '']: t.Current.DeparturesQty,
                                        [`${AnalyticsData.find((e) => e.code === 9)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`]:
                                          t.Previous.DeparturesQty,
                                      };
                                    })}
                                    exportedColumns={[
                                      { field: 'Date', header: 'Date' },

                                      {
                                        field: AnalyticsData.find((e) => e.code === 9)?.name || '',
                                        header: AnalyticsData.find((e) => e.code === 9)?.name || '',
                                      },
                                      {
                                        field: `${AnalyticsData.find((e) => e.code === 9)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                        header: `${AnalyticsData.find((e) => e.code === 9)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                      },
                                    ]}
                                  />
                                </div>
                                <LineChart
                                  mergeOptions={mergeOptions}
                                  data={{
                                    datasets: [
                                      {
                                        label: AnalyticsData.find((e) => e.code === 9)?.name,
                                        data: performanceData.Data.map((t) => t.Current.DeparturesQty),
                                        backgroundColor: backgroundColor,
                                        borderColor: foreGroundColor,
                                      },
                                      {
                                        label:
                                          AnalyticsData.find((e) => e.code === 9)?.name +
                                          ' en ' +
                                          filtersCTX.filters.performanceFilters.YearToCompare,
                                        data: performanceData.Data.map((t) => t.Previous.DeparturesQty),
                                        backgroundColor: backgroundColor2,
                                        borderColor: foreGroundColor2,
                                      },
                                    ],
                                    labels: performanceData.Data.map((t) =>
                                      filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                      filtersCTX.filters.performanceFilters.GroupBy === 3
                                        ? shortDateToLocaleStringWithoutYear(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : filtersCTX.filters.performanceFilters.GroupBy === 2
                                          ? shortOnlyMonthDateToLocaleString(
                                              moment(t.Label).toDate(),
                                              userCTX.authenticationInfos.language ?? 'fr',
                                            )
                                          : t.Label,
                                    ),
                                  }}
                                ></LineChart>
                              </div>
                            </div>
                            <div className="col-lg-3 col-12">
                              <div
                                style={{
                                  border: hoveredId === 10 ? '2px dashed #333' : '1px solid #e0e0e0',
                                  background: hoveredId === 10 ? '#ccc' : 'inherit',
                                }}
                                className={`small-box bg-gradient-light  drop-zone`}
                                onDragOver={handleDragOver}
                                onDragEnter={() => handleDragEnter(10)}
                                onDrop={handleDrop}
                                onDragStart={(e) => handleDragStart(e, 10)} // chartData is your data for the chart
                              >
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    left: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'grab',
                                  }}
                                  className="fa-solid fa-bars"
                                ></i>
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    right: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'pointer',
                                  }}
                                  className="fa-solid fa-eye"
                                  onClick={() => openModale(10)}
                                ></i>
                                <div style={{ position: 'absolute', right: '15px', top: 'calc(50% - 20px)' }}>
                                  <ExportExcel
                                    filename="OutOfOrder"
                                    datestart={moment(filtersCTX.filters.performanceFilters.DateStart).format(
                                      'YYYY-MM-DD',
                                    )}
                                    dateend={moment(filtersCTX.filters.performanceFilters.DateEnd).format('YYYY-MM-DD')}
                                    data={performanceData.Data.map((t) => {
                                      return {
                                        Date:
                                          filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                          filtersCTX.filters.performanceFilters.GroupBy === 3
                                            ? shortDateToLocaleStringWithoutYear(
                                                moment(t.Label).toDate(),
                                                userCTX.authenticationInfos.language ?? 'fr',
                                              )
                                            : filtersCTX.filters.performanceFilters.GroupBy === 2
                                              ? shortOnlyMonthDateToLocaleString(
                                                  moment(t.Label).toDate(),
                                                  userCTX.authenticationInfos.language ?? 'fr',
                                                )
                                              : t.Label,
                                        [AnalyticsData.find((e) => e.code === 10)?.name || '']: t.Current.OutOfOrder,
                                        [`${AnalyticsData.find((e) => e.code === 10)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`]:
                                          t.Previous.OutOfOrder,
                                      };
                                    })}
                                    exportedColumns={[
                                      { field: 'Date', header: 'Date' },

                                      {
                                        field: AnalyticsData.find((e) => e.code === 10)?.name || '',
                                        header: AnalyticsData.find((e) => e.code === 10)?.name || '',
                                      },
                                      {
                                        field: `${AnalyticsData.find((e) => e.code === 10)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                        header: `${AnalyticsData.find((e) => e.code === 10)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                      },
                                    ]}
                                  />
                                </div>
                                <LineChart
                                  mergeOptions={mergeOptions}
                                  data={{
                                    datasets: [
                                      {
                                        label: AnalyticsData.find((e) => e.code === 10)?.name,
                                        data: performanceData.Data.map((t) => t.Current.OutOfOrder),
                                        backgroundColor: backgroundColor,
                                        borderColor: foreGroundColor,
                                      },
                                      {
                                        label:
                                          AnalyticsData.find((e) => e.code === 10)?.name +
                                          ' en ' +
                                          filtersCTX.filters.performanceFilters.YearToCompare,
                                        data: performanceData.Data.map((t) => t.Previous.OutOfOrder),
                                        backgroundColor: backgroundColor2,
                                        borderColor: foreGroundColor2,
                                      },
                                    ],
                                    labels: performanceData.Data.map((t) =>
                                      filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                      filtersCTX.filters.performanceFilters.GroupBy === 3
                                        ? shortDateToLocaleStringWithoutYear(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : filtersCTX.filters.performanceFilters.GroupBy === 2
                                          ? shortOnlyMonthDateToLocaleString(
                                              moment(t.Label).toDate(),
                                              userCTX.authenticationInfos.language ?? 'fr',
                                            )
                                          : t.Label,
                                    ),
                                  }}
                                ></LineChart>
                              </div>
                            </div>
                            <div className="col-lg-3 col-12">
                              <div
                                style={{
                                  border: hoveredId === 11 ? '2px dashed #333' : '1px solid #e0e0e0',
                                  background: hoveredId === 11 ? '#ccc' : 'inherit',
                                }}
                                className={`small-box bg-gradient-light  drop-zone`}
                                onDragOver={handleDragOver}
                                onDragEnter={() => handleDragEnter(11)}
                                onDrop={handleDrop}
                                onDragStart={(e) => handleDragStart(e, 11)} // chartData is your data for the chart
                              >
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    left: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'grab',
                                  }}
                                  className="fa-solid fa-bars"
                                ></i>
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    right: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'pointer',
                                  }}
                                  className="fa-solid fa-eye"
                                  onClick={() => openModale(11)}
                                ></i>
                                <div style={{ position: 'absolute', right: '15px', top: 'calc(50% - 20px)' }}>
                                  <ExportExcel
                                    filename="TrevPar"
                                    datestart={moment(filtersCTX.filters.performanceFilters.DateStart).format(
                                      'YYYY-MM-DD',
                                    )}
                                    dateend={moment(filtersCTX.filters.performanceFilters.DateEnd).format('YYYY-MM-DD')}
                                    data={performanceData.Data.map((t) => {
                                      return {
                                        Date:
                                          filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                          filtersCTX.filters.performanceFilters.GroupBy === 3
                                            ? shortDateToLocaleStringWithoutYear(
                                                moment(t.Label).toDate(),
                                                userCTX.authenticationInfos.language ?? 'fr',
                                              )
                                            : filtersCTX.filters.performanceFilters.GroupBy === 2
                                              ? shortOnlyMonthDateToLocaleString(
                                                  moment(t.Label).toDate(),
                                                  userCTX.authenticationInfos.language ?? 'fr',
                                                )
                                              : t.Label,
                                        [AnalyticsData.find((e) => e.code === 11)?.name || '']: t.Current.TrevPar,
                                        [`${AnalyticsData.find((e) => e.code === 11)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`]:
                                          t.Previous.TrevPar,
                                      };
                                    })}
                                    exportedColumns={[
                                      { field: 'Date', header: 'Date' },

                                      {
                                        field: AnalyticsData.find((e) => e.code === 11)?.name || '',
                                        header: AnalyticsData.find((e) => e.code === 11)?.name || '',
                                      },
                                      {
                                        field: `${AnalyticsData.find((e) => e.code === 11)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                        header: `${AnalyticsData.find((e) => e.code === 11)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                      },
                                    ]}
                                  />
                                </div>
                                <LineChart
                                  mergeOptions={mergeOptions}
                                  data={{
                                    datasets: [
                                      {
                                        label: AnalyticsData.find((e) => e.code === 11)?.name,
                                        data: performanceData.Data.map((t) => t.Current.TrevPar),
                                        backgroundColor: backgroundColor,
                                        borderColor: foreGroundColor,
                                      },
                                      {
                                        label:
                                          AnalyticsData.find((e) => e.code === 11)?.name +
                                          ' en ' +
                                          filtersCTX.filters.performanceFilters.YearToCompare,
                                        data: performanceData.Data.map((t) => t.Previous.TrevPar),
                                        backgroundColor: backgroundColor2,
                                        borderColor: foreGroundColor2,
                                      },
                                    ],
                                    labels: performanceData.Data.map((t) =>
                                      filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                      filtersCTX.filters.performanceFilters.GroupBy === 3
                                        ? shortDateToLocaleStringWithoutYear(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : filtersCTX.filters.performanceFilters.GroupBy === 2
                                          ? shortOnlyMonthDateToLocaleString(
                                              moment(t.Label).toDate(),
                                              userCTX.authenticationInfos.language ?? 'fr',
                                            )
                                          : t.Label,
                                    ),
                                  }}
                                ></LineChart>
                              </div>
                            </div>
                            <div className="col-lg-3 col-12">
                              <div
                                style={{
                                  border: hoveredId === 12 ? '2px dashed #333' : '1px solid #e0e0e0',
                                  background: hoveredId === 12 ? '#ccc' : 'inherit',
                                }}
                                className={`small-box bg-gradient-light  drop-zone`}
                                onDragOver={handleDragOver}
                                onDragEnter={() => handleDragEnter(12)}
                                onDrop={handleDrop}
                                onDragStart={(e) => handleDragStart(e, 12)} // chartData is your data for the chart
                              >
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    left: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'grab',
                                  }}
                                  className="fa-solid fa-bars"
                                ></i>
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    right: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'pointer',
                                  }}
                                  className="fa-solid fa-eye"
                                  onClick={() => openModale(12)}
                                ></i>
                                <div style={{ position: 'absolute', right: '15px', top: 'calc(50% - 20px)' }}>
                                  <ExportExcel
                                    filename="RoomsSetQty"
                                    datestart={moment(filtersCTX.filters.performanceFilters.DateStart).format(
                                      'YYYY-MM-DD',
                                    )}
                                    dateend={moment(filtersCTX.filters.performanceFilters.DateEnd).format('YYYY-MM-DD')}
                                    data={performanceData.Data.map((t) => {
                                      return {
                                        Date:
                                          filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                          filtersCTX.filters.performanceFilters.GroupBy === 3
                                            ? shortDateToLocaleStringWithoutYear(
                                                moment(t.Label).toDate(),
                                                userCTX.authenticationInfos.language ?? 'fr',
                                              )
                                            : filtersCTX.filters.performanceFilters.GroupBy === 2
                                              ? shortOnlyMonthDateToLocaleString(
                                                  moment(t.Label).toDate(),
                                                  userCTX.authenticationInfos.language ?? 'fr',
                                                )
                                              : t.Label,
                                        [AnalyticsData.find((e) => e.code === 12)?.name || '']: t.Current.RoomsSetQty,
                                        [`${AnalyticsData.find((e) => e.code === 12)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`]:
                                          t.Previous.RoomsSetQty,
                                      };
                                    })}
                                    exportedColumns={[
                                      { field: 'Date', header: 'Date' },

                                      {
                                        field: AnalyticsData.find((e) => e.code === 12)?.name || '',
                                        header: AnalyticsData.find((e) => e.code === 12)?.name || '',
                                      },
                                      {
                                        field: `${AnalyticsData.find((e) => e.code === 12)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                        header: `${AnalyticsData.find((e) => e.code === 12)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                      },
                                    ]}
                                  />
                                </div>
                                <LineChart
                                  mergeOptions={mergeOptions}
                                  data={{
                                    datasets: [
                                      {
                                        label: AnalyticsData.find((e) => e.code === 12)?.name,
                                        data: performanceData.Data.map((t) => t.Current.RoomsSetQty),
                                        backgroundColor: backgroundColor,
                                        borderColor: foreGroundColor,
                                      },
                                      {
                                        label:
                                          AnalyticsData.find((e) => e.code === 12)?.name +
                                          ' en ' +
                                          filtersCTX.filters.performanceFilters.YearToCompare,
                                        data: performanceData.Data.map((t) => t.Previous.RoomsSetQty),
                                        backgroundColor: backgroundColor2,
                                        borderColor: foreGroundColor2,
                                      },
                                    ],
                                    labels: performanceData.Data.map((t) =>
                                      filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                      filtersCTX.filters.performanceFilters.GroupBy === 3
                                        ? shortDateToLocaleStringWithoutYear(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : filtersCTX.filters.performanceFilters.GroupBy === 2
                                          ? shortOnlyMonthDateToLocaleString(
                                              moment(t.Label).toDate(),
                                              userCTX.authenticationInfos.language ?? 'fr',
                                            )
                                          : t.Label,
                                    ),
                                  }}
                                ></LineChart>
                              </div>
                            </div>
                            <div className="col-lg-3 col-12">
                              <div
                                style={{
                                  border: hoveredId === 13 ? '2px dashed #333' : '1px solid #e0e0e0',
                                  background: hoveredId === 13 ? '#ccc' : 'inherit',
                                }}
                                className={`small-box bg-gradient-light  drop-zone`}
                                onDragOver={handleDragOver}
                                onDragEnter={() => handleDragEnter(13)}
                                onDrop={handleDrop}
                                onDragStart={(e) => handleDragStart(e, 13)} // chartData is your data for the chart
                              >
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    left: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'grab',
                                  }}
                                  className="fa-solid fa-bars"
                                ></i>
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    right: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'pointer',
                                  }}
                                  className="fa-solid fa-eye"
                                  onClick={() => openModale(13)}
                                ></i>
                                <div style={{ position: 'absolute', right: '15px', top: 'calc(50% - 20px)' }}>
                                  <ExportExcel
                                    filename="YearToCompare"
                                    datestart={moment(filtersCTX.filters.performanceFilters.DateStart).format(
                                      'YYYY-MM-DD',
                                    )}
                                    dateend={moment(filtersCTX.filters.performanceFilters.DateEnd).format('YYYY-MM-DD')}
                                    data={performanceData.Data.map((t) => {
                                      return {
                                        Date:
                                          filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                          filtersCTX.filters.performanceFilters.GroupBy === 3
                                            ? shortDateToLocaleStringWithoutYear(
                                                moment(t.Label).toDate(),
                                                userCTX.authenticationInfos.language ?? 'fr',
                                              )
                                            : filtersCTX.filters.performanceFilters.GroupBy === 2
                                              ? shortOnlyMonthDateToLocaleString(
                                                  moment(t.Label).toDate(),
                                                  userCTX.authenticationInfos.language ?? 'fr',
                                                )
                                              : t.Label,
                                        [AnalyticsData.find((e) => e.code === 13)?.name || '']: t.Current.Alt,
                                        [`${AnalyticsData.find((e) => e.code === 13)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`]:
                                          t.Previous.Alt,
                                      };
                                    })}
                                    exportedColumns={[
                                      { field: 'Date', header: 'Date' },

                                      {
                                        field: AnalyticsData.find((e) => e.code === 13)?.name || '',
                                        header: AnalyticsData.find((e) => e.code === 13)?.name || '',
                                      },
                                      {
                                        field: `${AnalyticsData.find((e) => e.code === 13)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                        header: `${AnalyticsData.find((e) => e.code === 13)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                      },
                                    ]}
                                  />
                                </div>
                                <LineChart
                                  mergeOptions={mergeOptions}
                                  data={{
                                    datasets: [
                                      {
                                        label: AnalyticsData.find((e) => e.code === 13)?.name,
                                        data: performanceData.Data.map((t) => t.Current.Alt),
                                        backgroundColor: backgroundColor,
                                        borderColor: foreGroundColor,
                                      },
                                      {
                                        label:
                                          AnalyticsData.find((e) => e.code === 13)?.name +
                                          ' en ' +
                                          filtersCTX.filters.performanceFilters.YearToCompare,
                                        data: performanceData.Data.map((t) => t.Previous.Alt),
                                        backgroundColor: backgroundColor2,
                                        borderColor: foreGroundColor2,
                                      },
                                    ],
                                    labels: performanceData.Data.map((t) =>
                                      filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                      filtersCTX.filters.performanceFilters.GroupBy === 3
                                        ? shortDateToLocaleStringWithoutYear(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : filtersCTX.filters.performanceFilters.GroupBy === 2
                                          ? shortOnlyMonthDateToLocaleString(
                                              moment(t.Label).toDate(),
                                              userCTX.authenticationInfos.language ?? 'fr',
                                            )
                                          : t.Label,
                                    ),
                                  }}
                                ></LineChart>
                              </div>
                            </div>
                            <div className="col-lg-3 col-12">
                              <div
                                style={{
                                  border: hoveredId === 14 ? '2px dashed #333' : '1px solid #e0e0e0',
                                  background: hoveredId === 14 ? '#ccc' : 'inherit',
                                }}
                                className={`small-box bg-gradient-light  drop-zone`}
                                onDragOver={handleDragOver}
                                onDragEnter={() => handleDragEnter(14)}
                                onDrop={handleDrop}
                                onDragStart={(e) => handleDragStart(e, 14)} // chartData is your data for the chart
                              >
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    left: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'grab',
                                  }}
                                  className="fa-solid fa-bars"
                                ></i>
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    right: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'pointer',
                                  }}
                                  className="fa-solid fa-eye"
                                  onClick={() => openModale(14)}
                                ></i>
                                <div style={{ position: 'absolute', right: '15px', top: 'calc(50% - 20px)' }}>
                                  <ExportExcel
                                    filename="Arg"
                                    datestart={moment(filtersCTX.filters.performanceFilters.DateStart).format(
                                      'YYYY-MM-DD',
                                    )}
                                    dateend={moment(filtersCTX.filters.performanceFilters.DateEnd).format('YYYY-MM-DD')}
                                    data={performanceData.Data.map((t) => {
                                      return {
                                        Date:
                                          filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                          filtersCTX.filters.performanceFilters.GroupBy === 3
                                            ? shortDateToLocaleStringWithoutYear(
                                                moment(t.Label).toDate(),
                                                userCTX.authenticationInfos.language ?? 'fr',
                                              )
                                            : filtersCTX.filters.performanceFilters.GroupBy === 2
                                              ? shortOnlyMonthDateToLocaleString(
                                                  moment(t.Label).toDate(),
                                                  userCTX.authenticationInfos.language ?? 'fr',
                                                )
                                              : t.Label,
                                        [AnalyticsData.find((e) => e.code === 14)?.name || '']: t.Current.Arg,
                                        [`${AnalyticsData.find((e) => e.code === 14)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`]:
                                          t.Previous.Arg,
                                      };
                                    })}
                                    exportedColumns={[
                                      { field: 'Date', header: 'Date' },

                                      {
                                        field: AnalyticsData.find((e) => e.code === 14)?.name || '',
                                        header: AnalyticsData.find((e) => e.code === 14)?.name || '',
                                      },
                                      {
                                        field: `${AnalyticsData.find((e) => e.code === 14)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                        header: `${AnalyticsData.find((e) => e.code === 14)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                      },
                                    ]}
                                  />
                                </div>
                                <LineChart
                                  mergeOptions={mergeOptions}
                                  data={{
                                    datasets: [
                                      {
                                        label: AnalyticsData.find((e) => e.code === 14)?.name,
                                        data: performanceData.Data.map((t) => t.Current.Arg),
                                        backgroundColor: backgroundColor,
                                        borderColor: foreGroundColor,
                                      },
                                      {
                                        label:
                                          AnalyticsData.find((e) => e.code === 14)?.name +
                                          ' en ' +
                                          filtersCTX.filters.performanceFilters.YearToCompare,
                                        data: performanceData.Data.map((t) => t.Previous.Arg),
                                        backgroundColor: backgroundColor2,
                                        borderColor: foreGroundColor2,
                                      },
                                    ],
                                    labels: performanceData.Data.map((t) =>
                                      filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                      filtersCTX.filters.performanceFilters.GroupBy === 3
                                        ? shortDateToLocaleStringWithoutYear(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : filtersCTX.filters.performanceFilters.GroupBy === 2
                                          ? shortOnlyMonthDateToLocaleString(
                                              moment(t.Label).toDate(),
                                              userCTX.authenticationInfos.language ?? 'fr',
                                            )
                                          : t.Label,
                                    ),
                                  }}
                                ></LineChart>
                              </div>
                            </div>
                            <div className="col-lg-3 col-12">
                              <div
                                style={{
                                  border: hoveredId === 15 ? '2px dashed #333' : '1px solid #e0e0e0',
                                  background: hoveredId === 15 ? '#ccc' : 'inherit',
                                }}
                                className={`small-box bg-gradient-light  drop-zone`}
                                onDragOver={handleDragOver}
                                onDragEnter={() => handleDragEnter(15)}
                                onDrop={handleDrop}
                                onDragStart={(e) => handleDragStart(e, 15)} // chartData is your data for the chart
                              >
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    left: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'grab',
                                  }}
                                  className="fa-solid fa-bars"
                                ></i>
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    right: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'pointer',
                                  }}
                                  className="fa-solid fa-eye"
                                  onClick={() => openModale(15)}
                                ></i>
                                <div style={{ position: 'absolute', right: '15px', top: 'calc(50% - 20px)' }}>
                                  <ExportExcel
                                    filename="TRevPec"
                                    datestart={moment(filtersCTX.filters.performanceFilters.DateStart).format(
                                      'YYYY-MM-DD',
                                    )}
                                    dateend={moment(filtersCTX.filters.performanceFilters.DateEnd).format('YYYY-MM-DD')}
                                    data={performanceData.Data.map((t) => {
                                      return {
                                        Date:
                                          filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                          filtersCTX.filters.performanceFilters.GroupBy === 3
                                            ? shortDateToLocaleStringWithoutYear(
                                                moment(t.Label).toDate(),
                                                userCTX.authenticationInfos.language ?? 'fr',
                                              )
                                            : filtersCTX.filters.performanceFilters.GroupBy === 2
                                              ? shortOnlyMonthDateToLocaleString(
                                                  moment(t.Label).toDate(),
                                                  userCTX.authenticationInfos.language ?? 'fr',
                                                )
                                              : t.Label,
                                        [AnalyticsData.find((e) => e.code === 15)?.name || '']: t.Current.TRevPec,
                                        [`${AnalyticsData.find((e) => e.code === 15)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`]:
                                          t.Previous.TRevPec,
                                      };
                                    })}
                                    exportedColumns={[
                                      { field: 'Date', header: 'Date' },

                                      {
                                        field: AnalyticsData.find((e) => e.code === 15)?.name || '',
                                        header: AnalyticsData.find((e) => e.code === 15)?.name || '',
                                      },
                                      {
                                        field: `${AnalyticsData.find((e) => e.code === 15)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                        header: `${AnalyticsData.find((e) => e.code === 15)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                      },
                                    ]}
                                  />
                                </div>
                                <LineChart
                                  mergeOptions={mergeOptions}
                                  data={{
                                    datasets: [
                                      {
                                        label: AnalyticsData.find((e) => e.code === 15)?.name,
                                        data: performanceData.Data.map((t) => t.Current.TRevPec),
                                        backgroundColor: backgroundColor,
                                        borderColor: foreGroundColor,
                                      },
                                      {
                                        label:
                                          AnalyticsData.find((e) => e.code === 15)?.name +
                                          ' en ' +
                                          filtersCTX.filters.performanceFilters.YearToCompare,
                                        data: performanceData.Data.map((t) => t.Previous.TRevPec),
                                        backgroundColor: backgroundColor2,
                                        borderColor: foreGroundColor2,
                                      },
                                    ],
                                    labels: performanceData.Data.map((t) =>
                                      filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                      filtersCTX.filters.performanceFilters.GroupBy === 3
                                        ? shortDateToLocaleStringWithoutYear(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : filtersCTX.filters.performanceFilters.GroupBy === 2
                                          ? shortOnlyMonthDateToLocaleString(
                                              moment(t.Label).toDate(),
                                              userCTX.authenticationInfos.language ?? 'fr',
                                            )
                                          : t.Label,
                                    ),
                                  }}
                                ></LineChart>
                              </div>
                            </div>
                            <div className="col-lg-3 col-12">
                              <div
                                style={{
                                  border: hoveredId === 16 ? '2px dashed #333' : '1px solid #e0e0e0',
                                  background: hoveredId === 16 ? '#ccc' : 'inherit',
                                }}
                                className={`small-box bg-gradient-light  drop-zone`}
                                onDragOver={handleDragOver}
                                onDragEnter={() => handleDragEnter(16)}
                                onDrop={handleDrop}
                                onDragStart={(e) => handleDragStart(e, 16)} // chartData is your data for the chart
                              >
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    left: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'grab',
                                  }}
                                  className="fa-solid fa-bars"
                                ></i>
                                <i
                                  draggable
                                  style={{
                                    position: 'absolute',
                                    top: 15,
                                    right: 15,
                                    color: colors.colorGreyAdminLTE,
                                    cursor: 'pointer',
                                  }}
                                  className="fa-solid fa-eye"
                                  onClick={() => openModale(16)}
                                ></i>
                                <div style={{ position: 'absolute', right: '15px', top: 'calc(50% - 20px)' }}>
                                  <ExportExcel
                                    filename="CancellationRate"
                                    datestart={moment(filtersCTX.filters.performanceFilters.DateStart).format(
                                      'YYYY-MM-DD',
                                    )}
                                    dateend={moment(filtersCTX.filters.performanceFilters.DateEnd).format('YYYY-MM-DD')}
                                    data={performanceData.Data.map((t) => {
                                      return {
                                        Date:
                                          filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                          filtersCTX.filters.performanceFilters.GroupBy === 3
                                            ? shortDateToLocaleStringWithoutYear(
                                                moment(t.Label).toDate(),
                                                userCTX.authenticationInfos.language ?? 'fr',
                                              )
                                            : filtersCTX.filters.performanceFilters.GroupBy === 2
                                              ? shortOnlyMonthDateToLocaleString(
                                                  moment(t.Label).toDate(),
                                                  userCTX.authenticationInfos.language ?? 'fr',
                                                )
                                              : t.Label,
                                        [AnalyticsData.find((e) => e.code === 16)?.name || '']:
                                          t.Current.CancellationRate,
                                        [`${AnalyticsData.find((e) => e.code === 16)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`]:
                                          t.Previous.CancellationRate,
                                      };
                                    })}
                                    exportedColumns={[
                                      { field: 'Date', header: 'Date' },

                                      {
                                        field: AnalyticsData.find((e) => e.code === 16)?.name || '',
                                        header: AnalyticsData.find((e) => e.code === 16)?.name || '',
                                      },
                                      {
                                        field: `${AnalyticsData.find((e) => e.code === 16)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                        header: `${AnalyticsData.find((e) => e.code === 16)?.name || ''} (${filtersCTX.filters.performanceFilters.YearToCompare})`,
                                      },
                                    ]}
                                  />
                                </div>
                                <LineChart
                                  mergeOptions={mergeOptions}
                                  data={{
                                    datasets: [
                                      {
                                        label: AnalyticsData.find((e) => e.code === 16)?.name,
                                        data: performanceData.Data.map((t) => t.Current.CancellationRate),
                                        backgroundColor: backgroundColor,
                                        borderColor: foreGroundColor,
                                      },
                                      {
                                        label:
                                          AnalyticsData.find((e) => e.code === 16)?.name +
                                          ' en ' +
                                          filtersCTX.filters.performanceFilters.YearToCompare,
                                        data: performanceData.Data.map((t) => t.Previous.CancellationRate),
                                        backgroundColor: backgroundColor2,
                                        borderColor: foreGroundColor2,
                                      },
                                    ],
                                    labels: performanceData.Data.map((t) =>
                                      filtersCTX.filters.performanceFilters.GroupBy === 0 ||
                                      filtersCTX.filters.performanceFilters.GroupBy === 3
                                        ? shortDateToLocaleStringWithoutYear(
                                            moment(t.Label).toDate(),
                                            userCTX.authenticationInfos.language ?? 'fr',
                                          )
                                        : filtersCTX.filters.performanceFilters.GroupBy === 2
                                          ? shortOnlyMonthDateToLocaleString(
                                              moment(t.Label).toDate(),
                                              userCTX.authenticationInfos.language ?? 'fr',
                                            )
                                          : t.Label,
                                    ),
                                  }}
                                ></LineChart>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </>
              </AnalytiqueProvider>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Performance;
