import { ReactNode, useEffect, useState } from 'react';

import { useKardex } from '@app/modules/kardex/KardexContext';
import { useSubForm } from '@app/modules/Modal/SubFormContext';
import { useTabView } from '@app/modules/Modal/TabViewContext';
import { useUser } from '@app/modules/user/UserContext';
import { RootState } from '@app/store/store';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import {
  DataTable,
  DataTableRowClickEvent,
  DataTableSelectionSingleChangeEvent,
  DataTableValueArray,
} from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect, MultiSelectPanelHeaderTemplateEvent } from 'primereact/multiselect';
import { Paginator } from 'primereact/paginator';
import { RadioButton } from 'primereact/radiobutton';
import { useSelector } from 'react-redux';

import Kardex from './Kardex';

interface props {
  data: any;
  tabMode: boolean;
  title: ReactNode;
  showTitle: boolean;
}
interface templateObject {
  name: string;
  code: number;
  header: string;
}
const KardexGrid: React.FC<props> = (props) => {
  const kardexCTX = useKardex();
  const kardexList = kardexCTX.KardexList;
  const subFormCTX = useSubForm();
  const tabViewCTX = useTabView();
  const [filterText, setFilterText] = useState('');
  const [debouncedFilterText, setDebouncedFilterText] = useState(filterText);
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);
  const [selectedLine, setSelectedLine] = useState<DataTableValueArray[] | null>(null);
  const userCTX = useUser();
  const selectedHotel = userCTX.authenticationInfos.selectedHotel;
  const handleRowSelection = (e: DataTableSelectionSingleChangeEvent<any>) => {
    const selectedData = e.value as DataTableValueArray[];

    setSelectedLine(selectedData);
  };
  const [selectedDataField, setSelectedDataField] = useState({
    name: 'Adresse',
    header: 'Adresse',

    code: 0,
  });
  const displayMode = [
    { name: 'Indeterminé', code: 0 },
    { name: 'WS (Grossiste)', code: 10 },
    { name: 'AGY (Agence)', code: 20 },
    { name: 'CPY (Société)', code: 30 },
    { name: 'IND (Individuel)', code: 40 },
    { name: 'GRP (Groupe)', code: 50 },
  ];
  const colomunTemplate = [
    {
      name: 'Adress',
      header: 'Adresse',
    },
    {
      name: 'DateNaissance',
      header: 'Date de naissance',
    },
    {
      name: 'DateCreation',
      header: 'Date de création',
    },
    {
      name: 'DateAcces',
      header: 'Date du dernier accès',
    },
    {
      name: 'IdSegment',
      header: 'Segmentation',
    },
    {
      name: 'IdOrigine0',
      header: 'Origine',
    },
    {
      name: 'AcceptMailing',
      header: 'Accepte les communications',
    },
    {
      name: 'CommunicationPreference',
      header: 'Préférences de communications',
    },
    {
      name: 'IataCode',
      header: 'Code IATAN',
    },
    {
      name: 'IdKardexCategory',
      header: 'Catégories',
    },
    {
      name: 'AccountBalance',
      header: 'Solde du compte',
    },
    {
      name: 'BusinessOrTourism',
      header: 'But du séjour par défaut',
    },
    {
      name: 'DefaultPreCheckInActivated',
      header: 'Pré Check-In',
    },
    {
      name: 'Gender',
      header: 'Genre',
    },
    {
      name: 'Gender',
      header: 'Genre',
    },
    {
      name: 'Phone',
      header: 'Tel 2',
    },
    {
      name: 'Fax',
      header: 'Fax',
    },
  ];
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilterText(filterText);
    }, 500); // Délai de 500ms

    return () => {
      clearTimeout(handler);
    };
  }, [filterText]);

  useEffect(() => {
    if (kardexCTX && kardexCTX.KardexFilters && selectedHotel) {
      kardexCTX.setKardexFilters({ page: 1, pagesize: props.tabMode ? 25 : 15 });
      kardexCTX.getAllKardex({ page: 1, pagesize: props.tabMode ? 25 : 15 }, selectedHotel?.IdHotel);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (kardexCTX.KardexFilters?.page && kardexCTX.KardexFilters?.pagesize) {
      switch (kardexCTX.KardexFilters.SearchMode) {
        case 'ByName':
          kardexCTX.setKardexFilters({
            ...kardexCTX.KardexFilters,
            LastName: debouncedFilterText,
            search: debouncedFilterText,
            Iata: undefined,
            PhoneNumber: undefined,
            Email: undefined,
          });
          break;
        case 'ByEmail':
          kardexCTX.setKardexFilters({
            ...kardexCTX.KardexFilters,
            Email: debouncedFilterText,
            LastName: undefined,
            search: debouncedFilterText,
            PhoneNumber: undefined,
            Iata: undefined,
          });
          break;
        case 'ByTelephone':
          kardexCTX.setKardexFilters({
            ...kardexCTX.KardexFilters,
            PhoneNumber: debouncedFilterText,
            LastName: undefined,
            Iata: undefined,
            search: debouncedFilterText,
            Email: undefined,
          });
          break;
        case 'ByIataCode':
          kardexCTX.setKardexFilters({
            ...kardexCTX.KardexFilters,
            Iata: debouncedFilterText,
            LastName: undefined,
            PhoneNumber: undefined,
            search: debouncedFilterText,
            Email: undefined,
          });
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilterText]);
  const onDoubleClick = async (e: DataTableRowClickEvent) => {
    kardexCTX.getKardekById(selectedHotel?.IdHotel as number, e.data.Id).then((t) => {
      if (props.tabMode) {
        const id = tabViewCTX.currentID;
        const newTabView = {
          id: id, // ou utilisez un ID unique
          component: Kardex, // Remplacez par le composant que vous souhaitez afficher
          componentType: 'Kardex',

          data: t, // Les données que vous souhaitez transmettre au composant
          actions: {
            hideTabView: () => {
              tabViewCTX.hideTabView(id);
              kardexCTX.setCurrentKardex(null);
              kardexCTX.setStayFilters(null);
            },
          },
          draggable: false, // Définissez-le en fonction de vos besoins
          fullWidth: true, // Définissez-le en fonction de vos besoins
          fullHeight: true, // Définissez-le en fonction de vos besoins
          translatedTitle: e.data.Nom,
        };

        // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
        tabViewCTX.setTabView((prevSubForm) => ({
          ...prevSubForm,
          listOfTabsDisplayed: [...prevSubForm.listOfTabsDisplayed, newTabView],
        }));

        tabViewCTX.setCurrentID(id + 1);
        tabViewCTX.setActiveTab(id);
      } else {
        const id = subFormCTX.subForm.listOfSubformsDisplayed.length + 1;
        const newSubForm = {
          id: id, // ou utilisez un ID unique
          component: Kardex, // Remplacez par le composant que vous souhaitez afficher
          data: t, // Les données que vous souhaitez transmettre au composant
          actions: {
            hideTabView: () => {
              subFormCTX.hideSubForm(id);
              kardexCTX.setCurrentKardex(null);
              kardexCTX.setStayFilters(null);
            },
          },
          draggable: false, // Définissez-le en fonction de vos besoins
          fullWidth: true, // Définissez-le en fonction de vos besoins
          fullHeight: true, // Définissez-le en fonction de vos besoins
          translatedTitle: 'Fiche kardex',
        };

        // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
        subFormCTX.setSubForm((prevSubForm) => ({
          ...prevSubForm,
          listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
        }));
      }
    });
  };
  const handleSearchText = (e: any) => {
    setFilterText(e.target.value);
  };

  const onPageChange = (event: any) => {
    kardexCTX.setKardexFilters({
      ...kardexCTX.KardexFilters,
      page: event.first === 0 ? 1 : (event.first ?? 1),
      pagesize: event.rows,
    });
  };

  useEffect(() => {
    if (kardexCTX.KardexFilters && selectedHotel?.IdHotel) {
      kardexCTX.getAllKardex(kardexCTX.KardexFilters, selectedHotel?.IdHotel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kardexCTX?.KardexFilters, selectedHotel?.IdHotel]);

  const customHeader = (options: MultiSelectPanelHeaderTemplateEvent) => {
    const onHeaderCheckboxChange = (e: any) => {
      options.onChange(e.checked);
    };
    return (
      <div className="p-multiselect-header" style={{ alignItems: 'center', marginTop: '10px' }}>
        <div className="p-checkbox p-component">
          <input
            style={{
              border: '2px solid #ced4da',
              background: '#ffffff',
              width: '20px',
              height: '20px',
              color: '#495057',
              borderRadius: '3px',
              transition: 'background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s',
            }}
            type="checkbox"
            role="checkbox"
            checked={options.checked}
            onChange={(e) => onHeaderCheckboxChange({ originalEvent: e, checked: e.target.checked })}
          />
        </div>
        <span style={{ marginLeft: '10px' }}>Tous</span>
      </div>
    );
  };
  const selectedField = () => {
    return (
      <Dropdown
        style={{ width: '100%' }}
        panelStyle={{ display: 'none' }}
        value={selectedDataField}
        onChange={(e) => setSelectedDataField(e.value)}
        options={colomunTemplate}
        optionLabel="header"
      />
    );
  };

  return (
    <div style={{ padding: '5px' }}>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          paddingBottom: '10px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <InputText style={{ width: '300px' }} onChange={handleSearchText} placeholder="Rechercher"></InputText>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <div style={{ paddingLeft: '5px', paddingRight: '5px' }} title="Nom">
            <RadioButton
              inputId="ingredient1"
              value="user"
              onChange={() => {
                kardexCTX.setKardexFilters({
                  ...kardexCTX.KardexFilters,
                  SearchMode: 'ByName',
                  LastName: debouncedFilterText,
                  Email: undefined,
                  PhoneNumber: undefined,
                  Iata: undefined,
                });
              }}
              checked={kardexCTX.KardexFilters?.SearchMode === 'ByName'}
            />
            <i style={{ paddingLeft: '10px', paddingRight: '10px' }} className="fa-solid fa-user"></i>
          </div>
          <div style={{ paddingLeft: '10px', paddingRight: '10px' }} title="Email">
            <RadioButton
              inputId="ingredient2"
              value="mail"
              onChange={() =>
                kardexCTX.setKardexFilters({
                  ...kardexCTX.KardexFilters,
                  SearchMode: 'ByEmail',
                  Email: debouncedFilterText,
                  LastName: undefined,
                  PhoneNumber: undefined,
                  Iata: undefined,
                })
              }
              checked={kardexCTX.KardexFilters?.SearchMode === 'ByEmail'}
            />
            <i style={{ paddingLeft: '10px', paddingRight: '10px' }} className="fa-regular fa-envelope"></i>
          </div>
          <div style={{ paddingLeft: '10px', paddingRight: '10px' }} title="Téléphone">
            <RadioButton
              inputId="ingredient3"
              value="phone"
              onChange={() =>
                kardexCTX.setKardexFilters({
                  ...kardexCTX.KardexFilters,
                  SearchMode: 'ByTelephone',
                  PhoneNumber: debouncedFilterText,
                  LastName: undefined,
                  Iata: undefined,
                  Email: undefined,
                })
              }
              checked={kardexCTX.KardexFilters?.SearchMode === 'ByTelephone'}
            />
            <i style={{ paddingLeft: '10px', paddingRight: '10px' }} className="fa-solid fa-phone"></i>
          </div>
          <div style={{ paddingLeft: '10px', paddingRight: '10px' }} title="Code IATAN">
            <RadioButton
              inputId="ingredient4"
              value="IATAN"
              onChange={() =>
                kardexCTX.setKardexFilters({
                  ...kardexCTX.KardexFilters,
                  SearchMode: 'ByIataCode',
                  Iata: debouncedFilterText,
                  LastName: undefined,
                  PhoneNumber: undefined,
                  Email: undefined,
                })
              }
              checked={kardexCTX.KardexFilters?.SearchMode === 'ByIataCode'}
            />
            <i style={{ paddingLeft: '10px', paddingRight: '10px' }} className="fa-solid fa-plane"></i>
          </div>
        </div>
        <Button
          icon="fa-solid fa-folder-plus"
          style={{ height: '24px', width: '24px', borderRadius: '7.5px' }}
          title="Créer un Kardex"
        ></Button>
        <MultiSelect
          title="Catégorie"
          placeholder="Toutes catégories"
          value={kardexCTX.KardexFilters?.CategorySelected}
          panelHeaderTemplate={customHeader}
          onChange={(e) => {
            kardexCTX.setKardexFilters({
              ...kardexCTX.KardexFilters,
              CategorySelected: e.value,
              IdCategory: e.value.map((t: templateObject) => t.code),
            });
          }}
          options={displayMode}
          maxSelectedLabels={(displayMode?.length ?? 0) - 1}
          selectedItemsLabel={'Tous'}
          optionLabel="name"
          style={{ width: '300px' }}
        />{' '}
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <div title="Afficher les kardex masqués">
            <Checkbox
              checked={kardexCTX.KardexFilters?.Hidden ?? false}
              onChange={(e) =>
                kardexCTX.setKardexFilters({
                  ...kardexCTX.KardexFilters,
                  Hidden: e.checked ?? false,
                })
              }
            ></Checkbox>
            <i style={{ paddingLeft: '10px', paddingRight: '20px' }} className="fa-regular fa-file"></i>
          </div>
          <div title="N'afficher que les kardex ayant des tarifs préférentiels">
            <Checkbox
              checked={kardexCTX.KardexFilters?.OnlyWithPreferedRate ?? false}
              onChange={(e) =>
                kardexCTX.setKardexFilters({
                  ...kardexCTX.KardexFilters,
                  OnlyWithPreferedRate: e.checked ?? false,
                })
              }
            ></Checkbox>
            <i style={{ paddingLeft: '10px', paddingRight: '20px' }} className="fa-solid fa-percent"></i>
          </div>{' '}
          <div title="Afficher les kardex sociétés">
            <Checkbox
              checked={
                kardexCTX.KardexFilters?.SearchType === 'Both' || kardexCTX.KardexFilters?.SearchType === 'Company'
                  ? true
                  : false
              }
              onChange={() =>
                kardexCTX.KardexFilters?.SearchType === 'Individual'
                  ? kardexCTX.setKardexFilters({
                      ...kardexCTX.KardexFilters,
                      SearchType: 'Both',
                    })
                  : kardexCTX.setKardexFilters({
                      ...kardexCTX.KardexFilters,
                      SearchType: 'Company',
                    })
              }
            ></Checkbox>
            <i style={{ paddingLeft: '10px', paddingRight: '20px' }} className="fa-solid fa-industry"></i>
          </div>{' '}
          <div title="Afficher les kardex individuels">
            <Checkbox
              checked={
                kardexCTX.KardexFilters?.SearchType === 'Both' || kardexCTX.KardexFilters?.SearchType === 'Individual'
                  ? true
                  : false
              }
              onChange={() =>
                kardexCTX.KardexFilters?.SearchType === 'Company'
                  ? kardexCTX.setKardexFilters({
                      ...kardexCTX.KardexFilters,
                      SearchType: 'Both',
                    })
                  : kardexCTX.setKardexFilters({
                      ...kardexCTX.KardexFilters,
                      SearchType: 'Individual',
                    })
              }
            ></Checkbox>
            <i style={{ paddingLeft: '10px', paddingRight: '20px' }} className="fa-solid fa-user"></i>
          </div>{' '}
          <div title="Afficher uniquement les comptes d'avoirs">
            <Checkbox
              checked={kardexCTX.KardexFilters?.OnlyWithAccountBalance ?? false}
              onChange={(e) =>
                kardexCTX.setKardexFilters({
                  ...kardexCTX.KardexFilters,
                  OnlyWithAccountBalance: e.checked ?? false,
                })
              }
            ></Checkbox>
            <i style={{ paddingLeft: '10px', paddingRight: '20px' }} className="fa-solid fa-building-columns"></i>
          </div>
          <div title="Afficher uniquement les comptes non utilisés">
            <Checkbox
              checked={kardexCTX.KardexFilters?.OnlyUnused ?? false}
              onChange={(e) =>
                kardexCTX.setKardexFilters({
                  ...kardexCTX.KardexFilters,
                  OnlyUnused: e.checked ?? false,
                })
              }
            ></Checkbox>
            <i style={{ paddingLeft: '10px', paddingRight: '20px' }} className="fa-solid fa-ghost"></i>
          </div>{' '}
        </div>
      </div>
      {kardexList && (
        <>
          <DataTable
            value={kardexList}
            stripedRows
            selectionMode="single"
            selection={selectedLine}
            onSelectionChange={(e: DataTableSelectionSingleChangeEvent<any>) => handleRowSelection(e)}
            rows={props.tabMode ? 25 : 15}
            scrollable
            rowHover
            onRowDoubleClick={onDoubleClick}
            className={(darkMode ? 'dark' : '') + ' normalDT'}
          >
            <Column field="Nom" header="Nom"></Column>
            <Column field="Prenom" header="Prénom"></Column>
            <Column field="Email" header="Email"></Column>
            <Column field="CodePostal" header="Code postal"></Column>
            <Column field="Ville" header="Ville"></Column>
            <Column field="IdPays" header="Pays"></Column>

            <Column field="TelBureau" header="Tel 1"></Column>
            <Column field={selectedDataField.name} header={selectedField}></Column>
          </DataTable>
          <Paginator
            first={kardexCTX?.KardexFilters?.page}
            rows={kardexCTX?.KardexFilters?.pagesize}
            totalRecords={120000}
            rowsPerPageOptions={[15, 25, 50]}
            onPageChange={onPageChange}
          />
        </>
      )}
    </div>
  );
};

export default KardexGrid;
