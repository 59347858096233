import { toast } from 'react-toastify';
import { MailContextState, useMail } from '@app/modules/Mail/MailContext';
import { contextList } from '@app/types/types';
import axios from '@services/axios/Axios';

export const handleInternalMessage = async (dependencies: contextList) => {
  axios.defaults.headers.common['silent'] = true;

  dependencies.mailCtx.countInboxMessagesUnread();

  if (location.pathname === '/mail' && dependencies.mailCtx.currentBox === '1') {
    dependencies.mailCtx.getInboxMessage(dependencies.mailCtx.mailFilters);
  }

  toast.success('Vous avez reçu un nouveau message !');
  axios.defaults.headers.common['silent'] = false;
};
