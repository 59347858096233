import React, { createContext, ReactNode, useContext, useState } from 'react';

import { getLogger } from 'loglevel';
import moment from 'moment-timezone';

import axios from '@services/axios/Axios';
import axiosblob from '@services/axios/AxiosBlob';

// Define the type for your params

interface MainCouranteServiceRequestParams {
  mainCouranteFilters: MainCouranteFilters;
  params: TechAndHotelParams;
  monthToDisplay?: Date; // La date à passer
}
// Define the interface for ParamsContextState
export interface MainCouranteContextState {
  MainCouranteMonthData: MainCouranteData | null;
  MainCouranteMonthDataByDay: MainCouranteData | null;
  setMainCouranteMonthData: (MainCouranteMonthData: MainCouranteData | null) => void;
  setMainCouranteMonthDataByDay: (MainCouranteMonthDataByDay: MainCouranteData | null) => void;
  getMainCouranteMonthData: (params: MainCouranteServiceRequestParams) => Promise<void | undefined>;
  getMainCouranteDataPerDay: (params: MainCouranteServiceRequestParams) => Promise<void | undefined>;

  getFileFromUrl: (params: string) => Promise<Blob | undefined>;
}

export interface MainCouranteResult {
  Data: MainCouranteData;
}

// Create a context to hold your params with default values
export const MainCouranteContext = createContext<MainCouranteContextState | undefined>(undefined);

// Define the props type for the ParamsProvider component
type MainCouranteProviderProps = {
  children: ReactNode;
};

// Create a provider component
export const MainCouranteProvider: React.FC<MainCouranteProviderProps> = (props) => {
  const [MainCouranteMonthData, setMainCouranteMonthData] = useState<MainCouranteData | null>(null);
  const [MainCouranteMonthDataByDay, setMainCouranteMonthDataByDay] = useState<MainCouranteData | null>(null);
  const getFileFromUrl = async (fileUrl: string): Promise<Blob | undefined> => {
    try {
      const response = await axiosblob.post(fileUrl);
      if (response.status === 415) {
        // Handle the 415 Unsupported Media Type error here
        console.error('Received a 415 Unsupported Media Type error', response);
        // You might want to throw an error or take other specific actions
      }
      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: Blob = await response.data;
      window.open(URL.createObjectURL(data), '_blank');
      return data;
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getMainCouranteMonthData = async (params: MainCouranteServiceRequestParams) => {
    try {
      const { ...filters } = params.mainCouranteFilters;
      if (params.params.isTech && params.params.selectedHotel) {
        filters.IdHotel = [params.params.selectedHotel.IdHotel];
      } else if (params.params.isTech === false) {
        filters.IdHotel = params.mainCouranteFilters.ListHotels.map((e) => e.IdHotel);
      }
      if (filters.IdHotel.length === 0) {
        throw "Pas d'hotel séléctionné";
      }
      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :

      filters.DateEnd = moment(filters.DateEnd).format('YYYY-MM-DD') as string;
      filters.DateStart = moment(filters.DateStart).format('YYYY-MM-DD') as string;

      const response = await axios.post('Analytics/SalesCategory/Month', filters, {});

      const data: MedialogResponse = await response.data;
      setMainCouranteMonthData(data.Data as MainCouranteData);

      if (
        moment(filters.DateStart).month() === moment(filters.DateEnd).month() &&
        moment(filters.DateStart).year() === moment(filters.DateEnd).year()
      ) {
        params.monthToDisplay = moment(filters.DateStart).toDate();
        getMainCouranteDataPerDay(params);
      }
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getMainCouranteDataPerDay = async (params: MainCouranteServiceRequestParams) => {
    try {
      const { ...filters } = params.mainCouranteFilters;
      if (params.params.isTech && params.params.selectedHotel) {
        filters.IdHotel = [params.params.selectedHotel.IdHotel];
      } else if (params.params.isTech === false) {
        filters.IdHotel = params.mainCouranteFilters.ListHotels.map((e) => e.IdHotel);
      }
      if (filters.IdHotel.length === 0) {
        throw "Pas d'hotel séléctionné";
      }
      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :

      const initialMoment = moment(params.monthToDisplay);
      filters.DateStart = initialMoment.format('YYYY-MM-DD');
      filters.DateEnd = initialMoment.endOf('month').format('YYYY-MM-DD');

      if (moment(params.mainCouranteFilters.DateStart).month() === initialMoment.month()) {
        filters.DateStart = moment(params.mainCouranteFilters.DateStart).format('YYYY-MM-DD');
      }

      if (moment(params.mainCouranteFilters.DateEnd).month() === initialMoment.month()) {
        filters.DateEnd = moment(params.mainCouranteFilters.DateEnd).format('YYYY-MM-DD');
      }

      if (
        moment(filters.DateEnd).month() === moment(new Date()).month() &&
        moment(filters.DateEnd).year() === moment(new Date()).year()
      ) {
        filters.DateEnd = moment(new Date()).subtract(1, 'day').format('YYYY-MM-DD');
      }

      const response = await axios.post('Analytics/SalesCategory/Day', filters, {});

      const data: MedialogResponse = await response.data;
      setMainCouranteMonthDataByDay(data.Data as MainCouranteData);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  return (
    <MainCouranteContext.Provider
      value={{
        MainCouranteMonthData,
        MainCouranteMonthDataByDay,
        setMainCouranteMonthData,
        setMainCouranteMonthDataByDay,
        getMainCouranteDataPerDay,
        getMainCouranteMonthData,
        getFileFromUrl,
      }}
    >
      {props.children}
    </MainCouranteContext.Provider>
  );
};

// Create a custom hook to access the params
export function useMainCourante(): MainCouranteContextState {
  const mainCouranteContext = useContext(MainCouranteContext);
  if (mainCouranteContext === undefined) {
    throw new Error('useMainCouranter must be used within a MainCouranteProvider');
  }
  return mainCouranteContext;
}
