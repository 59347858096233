import React, { useEffect, useState } from 'react';

import Kardex from '@app/components/Desktop/Kardex/Kardex';
import { useDataFactory } from '@app/modules/DataFactory/DataFactoryContext';
import { useKardex } from '@app/modules/kardex/KardexContext';
import { useSubForm } from '@app/modules/Modal/SubFormContext';
import { useUser } from '@app/modules/user/UserContext';
import { RootState } from '@app/store/store';
import colors from '@app/styles/colors';
import moment from 'moment-timezone';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { SplitButton } from 'primereact/splitbutton';
import { TabPanel, TabView } from 'primereact/tabview';
import { useSelector } from 'react-redux';

import PickupSelector from '../popup/pickupSelector';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useBookings } from '@app/modules/Booking/BookingsContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Availability } from '@app/types/types';
import { usePlanning } from '@app/modules/planning/PlanningContext';
import { useTranslation } from 'react-i18next';
import { useWindow } from '@app/modules/Modal/WindowContext';
import InformationModal from '../popup/InformationModal';

interface props {
  data: any;
  actions: {
    hideSubForm: () => void;
  };
}

const ChooseNewRoom: React.FC<props> = ({ data, actions }) => {
  const userCTX = useUser();
  const bookingCTX = useBookings();
  const planningCTX = usePlanning();
  const dataFactoryCTX = useDataFactory();
  const windowCTX = useWindow();
  const [selectedLine, setSelectedLine] = useState<any>();
  const [t] = useTranslation();
  const displayLine = (e: any) => {
    return (
      <div
        style={{ padding: '5px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        onClick={() => {
          setSelectedLine(e);
        }}
      >
        <div style={{ width: 'calc(100% - 50px)' }}>
          {e.SubLabel ? (
            <div>
              <span style={{ fontWeight: 'bold', fontSize: '18px' }}>{e.Label}</span> {e.SubLabel}
            </div>
          ) : (
            <span style={{ fontWeight: 'bold', fontSize: '18px' }}>{e.Label}</span>
          )}
          {
            <p style={{ fontSize: '12px' }}>
              <i>{e.Description}</i>
            </p>
          }
        </div>
        <div style={{ width: '50px', justifyContent: 'flex-end', display: 'flex', alignItems: 'center' }}>
          {e.IsDeparture && <i className="fa-solid fa-plane-departure fa-xl" style={{ color: 'darkred' }}></i>}
        </div>
      </div>
    );
  };
  const displayType = (e: any) => {
    return (
      <div
        style={{ fontWeight: e.Bold ? 'bold' : 'normal', color: e.Color ? e.Color : 'black', padding: '5px' }}
        title={e?.RoomTypeDescription}
      >
        {e.TypeName.indexOf('[') >= 0 ? t('labels.' + e.TypeName) : e.TypeName}
      </div>
    );
  };
  useEffect(() => {
    planningCTX.getFreeAvailabilities(
      new Date(bookingCTX.bookingsProposal?.ConcernedItem.NewValue.DateStart as string),
      new Date(bookingCTX.bookingsProposal?.ConcernedItem.NewValue.DateEnd as string),
    );
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: '100%', padding: '5px' }}>
      <DataTable
        rowHover
        selection={selectedLine}
        style={{ padding: 0, width: '50%', height: '530px', overflow: 'auto' }}
        stripedRows
        selectionMode={'single'}
        onSelectionChange={async (e) => {
          let updatedSelection = e.value; // L'élément sélectionné
          if (!e.value?.IdRoom) {
            //roomtype choisi
            updatedSelection = bookingCTX.bookingsProposal?.Items.find(
              (i) => (i.IdBooking = data.IdBooking),
            )?.Proposals?.find((p) => p.IdRoomType === e.value.IdRoomType && p.IdRoom !== undefined);

            if (updatedSelection === undefined) {
              //pas de chambre du type séléctionné disponible

              const infoPromise = new Promise((resolve, reject) => {
                const form = windowCTX.window.listOfWindowsDisplayed.find((e) => e.id === 22000);
                if (!form) {
                  const id = 22000;
                  const newWindow = {
                    id: id,
                    component: InformationModal,
                    data: {
                      iconComponent: (
                        <i style={{ padding: '10px', color: colors.colorErrorDark }} className="far fa-bell fa-xl" />
                      ),
                      description: 'Attention, la réservation va être mise en sur-réservation',
                    },
                    translatedTitle: <>Attention</>,
                    actions: {
                      hideSubForm: () => windowCTX.hideWindow(id),
                      onConfirm: () => {
                        windowCTX.hideWindow(id);
                        resolve(true);
                      },
                    },
                    draggable: false,
                    fullWidth: false,
                    fullHeight: false,
                    x: 'calc(50vw - 300px)',
                    y: '25vh',
                    width: '600px',
                    showBlackdiv: true,
                    showCloseBtn: false,
                    border: '5px solid red',
                    zIndex: 11111,
                    maxHeight: '300px',
                  };

                  windowCTX.setWindow((prevWindow) => ({
                    ...prevWindow,
                    listOfWindowsDisplayed: [...prevWindow.listOfWindowsDisplayed, newWindow],
                  }));
                }
              });

              try {
                // Attendre la réponse de la promesse (confirmation ou annulation)
                const result = await infoPromise;
                if (result) {
                  //on cherche la premiere chambre virtuelle proposé et non selectionné

                  if (
                    bookingCTX.bookingsProposal?.Items.find((i) => i.IdBooking === data.IdBooking)?.Proposals?.filter(
                      (e) => e.IsVirtual === true,
                    )?.length
                  ) {
                    // Si des propositions virtuelles existent
                    updatedSelection = bookingCTX.bookingsProposal?.Items.find(
                      (i) => i.IdBooking === data.IdBooking,
                    )?.Proposals?.filter((e) => e.IsVirtual === true)[0];
                  } else {
                    // Sinon, utilisez `e.value`
                    updatedSelection = e.value;
                  }
                }
              } catch (e) {}
            }
          }
          setSelectedLine(updatedSelection); // Met à jour la sélection locale

          // Mettre à jour le contexte avec la nouvelle sélection
          if (bookingCTX.bookingsProposal?.Items) {
            const updatedItems = bookingCTX.bookingsProposal?.Items.map((item) => {
              if (item.IdBooking === data.IdBooking) {
                return {
                  ...item,
                  IdRoom: updatedSelection?.IdRoom,
                  IdRoomType: updatedSelection?.IdRoomType,
                  RoomType: updatedSelection.Label,
                  RoomNumber: dataFactoryCTX.DataDictionnary.Rooms?.find((r) => r.Id === updatedSelection.IdRoom)
                    ?.RoomNumber as string,
                  Proposals: null,
                };
              }
              return item; // Les autres éléments restent inchangés
            });

            // Mettre à jour le contexte
            bookingCTX.setBookingsProposal({
              ...bookingCTX.bookingsProposal,
              Items: updatedItems,
            });
            actions.hideSubForm();
          }
        }}
        className="normalDT previsionDT"
        value={bookingCTX.bookingsProposal?.Items.find((e) => e.IdBooking === data.IdBooking)?.Proposals}
      >
        <Column
          style={{ marginLeft: '20px', textAlign: 'left', justifyContent: 'flex-start' }}
          body={(e) => displayLine(e)}
        ></Column>
      </DataTable>
      <DataTable
        rowHover
        style={{ padding: 0, width: isMobileOnly ? '90vw' : '20vw', minWidth: isMobileOnly ? '90vw' : '400px' }}
        stripedRows
        selectionMode={'single'}
        className="normalDT previsionDT"
        value={planningCTX.FreeAvailabilities as Availability[]}
      >
        <Column
          style={{ marginLeft: '20px', textAlign: 'left', justifyContent: 'flex-start' }}
          body={(e) => displayType(e)}
        ></Column>
        <Column body={(e) => <div style={{ padding: '5px' }}>{e.Quantity}</div>}></Column>
      </DataTable>
    </div>
  );
};

export default ChooseNewRoom;
