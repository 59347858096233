import React, { createContext, ReactNode, useContext, useState } from 'react';

import { CumulData, PrevisionData } from '@app/types/types';
import { getLogger } from 'loglevel';
import moment from 'moment-timezone';

import axios from '@services/axios/Axios';

// Define the type for your params

interface PrevisionServiceRequestParams {
  previsionFilters: PrevisionFilters;
  params: TechAndHotelParams;
}
// Define the interface for ParamsContextState
export interface PrevisionContextState {
  GridGlobal: PrevisionData[] | null;
  GridCumul: CumulData[] | null;
  setGridCumul: (GridCumul: CumulData[] | null) => void;
  setGridGlobal: (GridGlobal: PrevisionData[] | null) => void;
  getPrevisionData: (params: PrevisionServiceRequestParams) => Promise<PrevisionResult | undefined>;
}

export interface PrevisionResult {
  Data: {
    Data: CumulData[];
    Hotels: PrevisionData[];
  };
}

// Create a context to hold your params with default values
export const PrevisionContext = createContext<PrevisionContextState | undefined>(undefined);

// Define the props type for the ParamsProvider component
type PrevisionProviderProps = {
  children: ReactNode;
};

// Create a provider component
export const PrevisionProvider: React.FC<PrevisionProviderProps> = (props) => {
  const [GridGlobal, setGridGlobal] = useState<PrevisionData[] | null>(null);
  const [GridCumul, setGridCumul] = useState<CumulData[] | null>(null);
  const getPrevisionData = async (params: PrevisionServiceRequestParams): Promise<PrevisionResult | undefined> => {
    try {
      const { ListHotels, ...filters } = params.previsionFilters;
      ListHotels;
      if (params.params.isTech && params.params.selectedHotel) {
        filters.IdHotel = [params.params.selectedHotel.IdHotel];
      } else if (params.params.isTech === false) {
        filters.IdHotel = params.previsionFilters.ListHotels.map((e) => e.IdHotel);
      }
      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :

      filters.DateEnd = moment(filters.DateEnd).format('YYYY-MM-DD') as string;
      filters.DateStart = moment(filters.DateStart).format('YYYY-MM-DD') as string;

      const response = await axios.post('/Analytics/Forecast/', filters, {});

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const responseData: PrevisionResult = await response.data;
      setGridCumul(responseData.Data.Data);
      setGridGlobal(responseData.Data.Hotels);

      return responseData;
      //    setGridGlobal(responseData.Data.Hotels);
    } catch (error) {
      getLogger('web').error(error);
    }
  };
  return (
    <PrevisionContext.Provider value={{ GridCumul, GridGlobal, setGridCumul, setGridGlobal, getPrevisionData }}>
      {props.children}
    </PrevisionContext.Provider>
  );
};

// Create a custom hook to access the params
export function usePrevision(): PrevisionContextState {
  const previsionContext = useContext(PrevisionContext);
  if (previsionContext === undefined) {
    throw new Error('usePrevision must be used within a PrevisionProvider');
  }
  return previsionContext;
}
