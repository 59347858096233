import { config } from '@app/utils/config';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { getLogger } from 'loglevel';

import { storage } from '@store/localstorage/localStorage';

const ApiConfig = {
  baseURL: config.API_URL,
  headers: {
    'Content-type': 'application/json; charset=utf-8',
  },
};

const instance = axios.create(ApiConfig);
instance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = storage.getParam('access_token');
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['TimeZoneOffset'] = new Date().getTimezoneOffset();
    getLogger('api').debug(`[query:silence][${config.url?.split('/').pop()}]`, {
      method: config.method,
      url: `${config.url}`,
      params: config.params,
      data: config.data,
    });

    return config;
  },
  (error: AxiosError) => {
    // Handle request error here
    getLogger('api').error(error);
    return Promise.reject(error);
  },
);

function onFulfilled<T>(response: AxiosResponse<MedialogResponse>) {
  try {
    const realResp: MedialogResponse = response.data;
    const error = new Error(response.data.Message);

    if (realResp.Success === false) {
      (error as any).response = response;
      throw error;
    }
    getLogger('api').info(`[response:silence][${response.config.url?.split('/').pop()}]`, 'OK', response);
  } catch {
    // safe fallback for tests
    getLogger('api').warn(
      `[response:silence][${response.request.responseURL?.split('/')[response.request.responseURL?.split('/').length - 1]}]`,
      'ERROR',
      response,
    );
  }

  //test des erreurs fonctionnelles

  return response; // Return the response object
}

const onRejected = (e: AxiosError) => {
  getLogger('api').error(e);
};

instance.interceptors.response.use(onFulfilled, onRejected);

export default instance;
