import { contextList } from '@app/types/types';
import { handleBookings } from './handler/handleBookings';
import { handleInternalMessage } from './handler/handleInternalMessage';

export const actionHandlers: { [key: string]: (dependencies: contextList) => void } = {
  InternalMessage: (dependencies) => handleInternalMessage(dependencies),
  Bookings: handleBookings,
};

export const handleDefault = (action: string) => {
  console.warn(`Action non reconnue : ${action}`);
};

// Liste des actions disponibles
const ACTIONS = [
  'None',
  'InternalMessage',
  'Parametres',
  'AllotmentContracts',
  'AllotmentDetails',
  'Rooms',
  'HostBookingsSubscriptions',
  'CodesClient',
  'Colors',
  'Currencies',
  'ExpenseCategory',
  'DisplayColors',
  'RoomStates',
  'Origines',
  'BillingInstructions',
  'Bookings',
  'Segments',
  'Rates',
  'RoomTypes',
  'Titres',
  'Users',
  'Products',
  'Zones',
  'PaymentModes',
  'eStock',
  'Countries',
  'Familles',
  'TaxRates',
  'HostsInstalled',
  'RoomTypeCategories',
  'DailyMessages',
  'BookingDeposits',
  'Invoice',
  'SubFamilles',
  'InvoicingHosts',
  'Payment',
  'WorkStation',
  'NomTarifs',
  'WebDispoHotels',
  'Opentele',
  'ServerState',
  'HostName',
  'Letters',
  'ClosureRunning',
  'UsersRole',
  'SaleCondition',
  'ClosureEnd',
  'InvoiceProduits',
  'ClosedPeriod',
  'JobScheduling',
  'HotelProperties',
  'HotelPictures',
  'KardexAccountType',
  'Product_Prices',
  'RateCancelCondition',
  'PreAuthorizationsCaptured',
  'EpaymentTpe',
];
