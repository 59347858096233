import { config } from '@app/utils/config';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { getLogger } from 'loglevel';
import { toast } from 'react-toastify';

import { decrementCallInProgress, incrementCallInProgress } from '@services/apiState';

import { storage } from '@store/localstorage/localStorage';

const ApiConfig = {
  baseURL: config.API_URL,
  headers: {
    'Content-type': 'application/json; charset=utf-8',
  },
};

const instance = axios.create(ApiConfig);
instance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    getLogger('api').debug('conffig', config);
    //We save the last time call
    if (
      config.url !== '/WebPubSub/SendToGroup' &&
      config.url !== '/Socket/SendToUser' &&
      config.url !== '/Socket/SendToGroup' &&
      config.url !== '/User/RefreshToken' &&
      config.url !== '/Socket/GetConnectedUser' &&
      config.url !== '/WebPubSub/SendToUser' &&
      config.url !== '/WebPubSub/GetConnectedUser' &&
      config.headers['silent'] !== 'true'
    ) {
      incrementCallInProgress();
    }
    const lastCall = storage.getParam('lastCall');
    if (config.url !== '/auth/login') {
      if (lastCall !== undefined && lastCall !== null) {
        if (Date.now() - parseInt(lastCall) > 27000000) {
          storage.setParam('needToRelog', 'true');
          throw 'too long time';
        }
      }
    }
    storage.setParam('lastCall', Date.now().toString());
    const token = storage.getParam('access_token');
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['TimeZoneOffset'] = new Date().getTimezoneOffset();
    getLogger('api').debug(`[query][${config.url?.split('/')[config.url?.split('/').length - 1]}]`, {
      method: config.method,
      url: `${config.url}`,
      params: config.params,
      data: config.data,
    });

    return config;
  },
  (error: AxiosError) => {
    // Handle request error here
    getLogger('api').error(error);
    return Promise.reject(error);
  },
);

function onFulfilled<T>(response: AxiosResponse<MedialogResponse>) {
  if (
    response.config.url !== '/WebPubSub/SendToGroup' &&
    response.config.url !== '/Socket/SendToUser' &&
    response.config.url !== '/WebPubSub/SendToUser' &&
    response.config.url !== '/Socket/SendToGroup' &&
    response.config.url !== '/Socket/GetConnectedUser' &&
    response.config.url !== '/User/RefreshToken' &&
    response.config.url !== '/WebPubSub/GetConnectedUser' &&
    response.config.headers['silent'] !== 'true'
  ) {
    decrementCallInProgress();
  }
  try {
    const realResp: MedialogResponse = response.data;
    const error = new Error(response.data.Message);

    if (realResp.Success === false) {
      (error as any).response = response;

      toast.error(response.data.ErrorCode + ' : ' + error.message);
      throw error;
    }
    getLogger('api').info(
      `[response][${response.request.responseURL?.split('/')[response.request.responseURL?.split('/').length - 1]}]`,
      'OK',
      response,
    );
  } catch {
    // safe fallback for tests
    getLogger('api').warn(
      `[response][${response.request.responseURL?.split('/')[response.request.responseURL?.split('/').length - 1]}]`,
      'ERROR',
      response,
    );
  }

  //test des erreurs fonctionnelles

  return response; // Return the response object
}

const onRejected = (e: AxiosError) => {
  decrementCallInProgress();
  getLogger('api').error(e);
  if (e.response?.status === 401) {
    storage.setParam('needToRelog', 'true');
  } else if (e.code === 'ERR_NETWORK') {
    storage.setParam('needToRelog', 'true');
  } else {
    toast.error(e.message);
  }
};

instance.interceptors.response.use(onFulfilled, onRejected);

export default instance;
