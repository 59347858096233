import React, { useState } from 'react';

import ContentHeader from '@app/components/Common/content-header/ContentHeader';
import { Tree } from 'primereact/tree';
import { useMail } from '@app/modules/Mail/MailContext';
import { useUser } from '@app/modules/user/UserContext';

const LeftColumnMail = () => {
  const mailCTX = useMail();
  const userCTX = useUser();
  return (
    <div style={{ height: '100%', width: '250px', textAlign: 'left', padding: 0, margin: 0 }}>
      <Tree
        selectionMode="single"
        selectionKeys={mailCTX.currentBox}
        onSelectionChange={(e) => {
          if (e.value !== null) {
            mailCTX.setCurrentBox(e.value as string), mailCTX.setSelectedMail(null);
            mailCTX.setCreationMode(false);
            mailCTX.setCreationDailyMode(false);
          }
        }}
        style={{ fontSize: '0.8rem', height: '100%', width: '100%', margin: 0, padding: '10px' }}
        value={[
          ...(userCTX.authenticationInfos.selectedHotel
            ? [{ key: '0', label: 'Message du jour', icon: 'pi pi-fw pi-sun' }]
            : []),
          {
            key: '1',
            label: `Boite de réception (${mailCTX.inboxMessagesUnreadCount})`,
            icon: 'pi pi-fw pi-inbox',
            children: [{ key: '1-1', label: 'Eléments supprimés', icon: 'pi pi-fw pi-trash' }],
          },

          {
            key: '2',
            label: 'Eléments envoyés',
            icon: 'pi pi-fw pi-send',
          },
        ]}
        className="w-full md:w-30rem"
      />
    </div>
  );
};

export default LeftColumnMail;
