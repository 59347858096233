import React, { createContext, ReactNode, useContext, useState } from 'react';

import { DataFactory } from '@app/types/dataFactory';
import { getLogger } from 'loglevel';

import axios from '@services/axios/Axios';

// Define the type for your params

// Define the interface for ParamsContextState
export interface DataFactoryContextState {
  DataDictionnary: DataFactory;
  getDataDictionary: (params: DataFactoryParams) => Promise<any | undefined>;
  PMSSettings: PMSSettings | null;
  getPMSSettings: (idHotel: number) => Promise<PMSSettings | undefined>;
  setDataDictionnary: (data: any | null) => void;
  setPMSSettings: (data: PMSSettings | null) => void;
}

interface DataFactoryParams {
  IdHotel: string;
  Culture: string;
}
// Create a context to hold your params with default values
export const DataFactoryContext = createContext<DataFactoryContextState | undefined>(undefined);

// Define the props type for the ParamsProvider component
type DataFactoryProviderProps = {
  children: ReactNode;
};

// Create a provider component
export const DataFactoryProvider: React.FC<DataFactoryProviderProps> = (props) => {
  const [DataDictionnary, setDataDictionnary] = useState<any | null>(null);
  const [PMSSettings, setPMSSettings] = useState<PMSSettings | null>(null);
  const getDataDictionary = async (params: DataFactoryParams) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      if (!params.IdHotel) {
        return;
      }
      const response = await axios.get('PmsData/All/true');

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setDataDictionnary(data.Data as DataFactory);
      return data.Data as DataFactory;
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getPMSSettings = async (idHotel: number) => {
    try {
      const response = await axios.get('Settings/Hotel/' + idHotel);

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setPMSSettings(data.Data as PMSSettings);
      return data.Data as PMSSettings;
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  return (
    <DataFactoryContext.Provider
      value={{
        DataDictionnary,

        getDataDictionary,
        PMSSettings,
        getPMSSettings,
        setDataDictionnary,
        setPMSSettings,
      }}
    >
      {props.children}
    </DataFactoryContext.Provider>
  );
};

// Create a custom hook to access the params
export function useDataFactory(): DataFactoryContextState {
  const dataFactoryContext = useContext(DataFactoryContext);
  if (dataFactoryContext === undefined) {
    throw new Error('useDataFactory must be used within a DataFactoryProvider');
  }
  return dataFactoryContext;
}
