import React, { useCallback, useEffect, useState } from 'react';

import ControlSidebar from '@app/modules/main/control-sidebar/ControlSidebar';
import Header from '@app/modules/main/header/Header';
import MenuSidebar from '@app/modules/main/menu-sidebar/MenuSidebar';
import { toggleSidebarMenu } from '@app/store/reducers/ui';
import { RootState } from '@app/store/store';
import { config } from '@app/utils/config';
import { addWindowClass, removeWindowClass } from '@app/utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { useFilters } from '../filter/filterContext';
import { TabViewProvider } from '../Modal/TabViewContext';
import { useUser } from '../user/UserContext';
import { WindowProvider } from '../Modal/WindowContext';
import { useMail } from '../Mail/MailContext';
import { toast } from 'react-toastify';

const Main = () => {
  const dispatch = useDispatch();
  const userCTX = useUser();
  const mailCTX = useMail();
  const rooms = userCTX.authenticationInfos.roomOfSelectedHotel;
  const filtersCTX = useFilters();
  const isTech = userCTX.authenticationInfos.user?.IsAdmin;
  const menuSidebarCollapsed = useSelector((state: RootState) => state.ui.menuSidebarCollapsed);
  const controlSidebarCollapsed = useSelector((state: RootState) => state.ui.controlSidebarCollapsed);
  const screenSize = useSelector((state: RootState) => state.ui.screenSize);
  const authentication = userCTX.authenticationInfos.user;
  const [isAppLoaded, setIsAppLoaded] = useState(false);
  const handleToggleMenuSidebar = () => {
    dispatch(toggleSidebarMenu());
  };

  useEffect(() => {
    setIsAppLoaded(Boolean(authentication));
  }, [authentication]);

  useEffect(() => {
    removeWindowClass('login-page');
    removeWindowClass('hold-transition');

    addWindowClass('sidebar-mini');

    // fetchProfile();
    return () => {
      removeWindowClass('sidebar-mini');
    };
  }, []);

  useEffect(() => {
    removeWindowClass('sidebar-closed');
    removeWindowClass('sidebar-collapse');
    removeWindowClass('sidebar-open');

    if (menuSidebarCollapsed && screenSize === 'lg') {
      addWindowClass('sidebar-collapse');
    } else if (menuSidebarCollapsed && screenSize === 'xs') {
      addWindowClass('sidebar-collapse');
    } else if (!menuSidebarCollapsed && screenSize !== 'lg') {
      addWindowClass('sidebar-closed');
      addWindowClass('sidebar-open');
    } else if (menuSidebarCollapsed && screenSize === 'sm') {
      addWindowClass('sidebar-collapse');
    } else if (menuSidebarCollapsed && screenSize === 'md') {
      addWindowClass('sidebar-collapse');
    }
  }, [screenSize, menuSidebarCollapsed]);

  useEffect(() => {
    if (controlSidebarCollapsed) {
      removeWindowClass('control-sidebar-slide-open');
    } else {
      addWindowClass('control-sidebar-slide-open');
    }
  }, [screenSize, controlSidebarCollapsed]);

  useEffect(() => {
    if (rooms && filtersCTX && isTech === true && filtersCTX.filters.planningFilters.IdHotel) {
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        planningFilters: {
          ...prevState.planningFilters,
          TypeToDisplay:
            rooms?.map((e) => e.RoomType).filter((value, index, self) => self.indexOf(value) === index) ?? [],
          needReload: true,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rooms]);

  const getAppTemplate = useCallback(() => {
    if (!isAppLoaded) {
      return (
        <div className="preloader flex-column justify-content-center align-items-center">
          <img className="animation__shake" src="/img/logo.png" alt="Webdispo" height={60} width={60} />
        </div>
      );
    }

    return (
      <>
        <Header />

        <MenuSidebar />

        <div className="content-wrapper">
          <div className="pt-1" />
          <section className="content">
            <Outlet />{' '}
          </section>
        </div>
        {/*<Footer />*/}
        <span style={{ position: 'absolute', bottom: 0, right: 0 }}>{config.VERSION}</span>

        <ControlSidebar />
        <div id="sidebar-overlay" role="presentation" onClick={handleToggleMenuSidebar} />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppLoaded]);

  return <div className="wrapper">{getAppTemplate()}</div>;
};

export default Main;
